import { FC, useMemo } from "react";
import { Box, Divider, Grid, useTheme } from "@mui/material";
import { ConversationDetails } from "../domain/conversation";
import { Heading2, Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { UserAvatar } from "src/components/user-avatar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import { ChatPreviewReservations } from "./chat-preview-reservations";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ChatLastConversations } from "./chat-last-conversations/chat-last-conversations";
import { ChatPreviewTasks } from "./chat-preview-tasks";
import { useReservationDetailsId } from "../../features/reservations/reservation-details/reservation-details/use-reservation-details-id";
import { Task } from "src/features/tasks/domain/task";

interface ChatPreviewProps {
  conversation: ConversationDetails;
  tasks: Task[];
}

export const ChatPreview: FC<ChatPreviewProps> = ({ conversation, tasks }) => {
  const userInitials = useMemo(() => {
    if (conversation.guestIdentity?.firstName && conversation.guestIdentity?.lastName) {
      return `${conversation.guestIdentity?.firstName?.charAt(
        0
      )}${conversation.guestIdentity?.lastName?.charAt(0)}`;
    }
    return "";
  }, [conversation.guestIdentity]);

  const { palette } = useTheme();
  const { t } = useTranslationWrapper();

  const { setSelectId } = useReservationDetailsId();

  const onRowSelect = (reservationId: string) => {
    setSelectId(reservationId);
  };

  return (
    <Box
      sx={{
        backgroundColor: palette.common.white,
        height: "100%",
        overflowY: "scroll"
      }}
      p={1.5}
    >
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <UserAvatar
            lettersToDisplay={userInitials}
            sx={{
              bgcolor: palette.common.white,
              border: 1,
              borderColor: palette.common.black,
              color: palette.common.black,
              height: 40,
              width: 40,
              p: 2
            }}
          />
        </Grid>
        <Grid item paddingTop={2}>
          <Heading2>{conversation.guest.displayName}</Heading2>
        </Grid>
        <Grid item paddingTop={2} paddingBottom={1.5}>
          <Grid container direction="row">
            {conversation.guestIdentity?.gender && (
              <ParagraphBold color={palette.text.secondary} paddingRight={1}>
                ({t("labels__pronouns__" + conversation.guestIdentity?.gender)})
              </ParagraphBold>
            )}
            <ParagraphBold color={palette.primary.main}>
              {conversation.guestIdentity?.reservations &&
              conversation.guestIdentity?.reservations.length > 1
                ? t("labels__reservation__details__overview__guests__recurring")
                : t("labels__reservation__details__overview__guests__new")}
            </ParagraphBold>
          </Grid>
        </Grid>
        <Divider sx={{ width: "100%", color: palette.grey[300] }} />
      </Grid>
      <Grid container direction="column" paddingTop={3} paddingLeft={1}>
        {conversation.guestIdentity?.phone && (
          <Grid container direction="row">
            <Grid item paddingRight={1.5}>
              <WhatsAppIcon />
            </Grid>
            <Grid item>
              <Paragraph>{conversation.guestIdentity.phone}</Paragraph>
            </Grid>
          </Grid>
        )}
        {conversation.guestIdentity?.email && (
          <Grid container direction="row">
            <Grid item paddingRight={1.5}>
              <MarkunreadOutlinedIcon />
            </Grid>
            <Grid item>
              <Paragraph>{conversation.guestIdentity.email}</Paragraph>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ width: "100%", color: palette.grey[300] }} />
      <div>
        {conversation?.guestIdentity?.lastConversations?.length ? (
          <>
            <ChatLastConversations
              lastConversations={conversation?.guestIdentity?.lastConversations}
            />
            <Divider sx={{ width: "100%", color: palette.grey[300] }} />
          </>
        ) : null}
        {conversation.guestIdentity?.reservations?.length ? (
          <>
            <ChatPreviewReservations
              reservations={conversation.guestIdentity?.reservations ?? []}
              onSelect={onRowSelect}
            />
            <Divider sx={{ width: "100%", color: palette.grey[300] }} />
          </>
        ) : null}
      </div>
      {
        <ChatPreviewTasks
          primaryReservationId={
            conversation?.guestIdentity?.reservations
              ? conversation?.guestIdentity?.reservations[0]?.pmsId ?? ""
              : ""
          }
          tasks={tasks}
        />
      }
    </Box>
  );
};
