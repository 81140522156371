import { useCallback, useEffect, useMemo } from "react";
import { fetchAvailableDoors, selectAvailableDoors } from "../slices/available-doors";
import { useDispatch, useSelector } from "../store";
import {
  ADMIN_ROLE,
  HOST_ROLE,
  useApiVersion,
  useAuth,
  useDoorProviderConfig
} from "@likemagic-tech/sv-magic-library";
import { areAdminKeysAvailable } from "../utils/key-utils";
import { useGetSupportSyncStatesQuery } from "../graphql/queries/GetSupportSyncStates.generated";
import { transformAvailableDoors } from "../graphql/transform/transform-available-doors";
import { LockAccessibility } from "../domain/available-door";

export const useFetchAvailableDoors = (propertyId: string) => {
  const { isRESTVersion } = useApiVersion();
  const dispatch = useDispatch();
  const doorConfig = useDoorProviderConfig(propertyId) ?? {};
  const { hasRole } = useAuth();
  useEffect(() => {
    if (propertyId && areAdminKeysAvailable(doorConfig?.doorProvider) && isRESTVersion) {
      dispatch(fetchAvailableDoors({ propertyId: propertyId }));
    }
  }, [dispatch, propertyId, doorConfig?.doorProvider, isRESTVersion]);

  const availableDoorsV1 = useSelector(selectAvailableDoors);

  const isNotHostOrAdmin = useMemo(() => !hasRole(HOST_ROLE) && !hasRole(ADMIN_ROLE), [hasRole]);

  const { data } = useGetSupportSyncStatesQuery(
    {
      filter: { pmsId: propertyId }
    },
    {
      skip:
        isRESTVersion ||
        !propertyId ||
        isNotHostOrAdmin ||
        !areAdminKeysAvailable(doorConfig?.doorProvider)
    }
  );

  const availableDoorsV2 = useMemo(
    () => data?.GetProperty?.availableDoors?.map(transformAvailableDoors) ?? [],
    [data?.GetProperty?.availableDoors]
  );

  const allAvailableDoors = useMemo(
    () => (isRESTVersion ? availableDoorsV1 : availableDoorsV2),
    [availableDoorsV1, availableDoorsV2, isRESTVersion]
  );

  const filterKeysByIds = useCallback(
    (doorsId: string[]) => allAvailableDoors.filter((door) => doorsId?.includes(door.id)),
    [allAvailableDoors]
  );

  const filterPrivateKeysByIds = useCallback(
    (doorsId: string[]) =>
      allAvailableDoors.filter(
        (door) => doorsId?.includes(door.id) && door.lockAccessibility === LockAccessibility.PRIVATE
      ),
    [allAvailableDoors]
  );

  const filterPrivateKeysByUnitId = useCallback(
    (unitId: string) => allAvailableDoors.filter((door) => door.unitId === unitId),
    [allAvailableDoors]
  );

  const publicDoors = useMemo(
    () =>
      allAvailableDoors.filter(
        (door) =>
          [
            LockAccessibility.PUBLIC,
            LockAccessibility.PUBLIC_INHOUSE,
            LockAccessibility.BACKOFFICE_ONLY,
            LockAccessibility.SERVICE
          ].indexOf(door.lockAccessibility) > -1
      ),
    [allAvailableDoors]
  );

  return {
    allAvailableDoors,
    filterKeysByIds,
    filterPrivateKeysByIds,
    filterPrivateKeysByUnitId,
    publicDoors
  };
};
