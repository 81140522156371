import type { FC } from "react";
import { Box, Grid } from "@mui/material";
import { useProperty } from "../../hooks/use-property";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { ReservationTable } from "../../features/reservations/reservation-table/reservation-table";
import { useIsMobile } from "../../hooks/use-is-mobile";
import { IN_HOTEL_RESERVATIONS_SEARCH_FILTERS } from "src/components/table/hooks/use-search-filter-params";

const InHotel: FC = () => {
  const { t } = useTranslationWrapper();
  const { selectedPropertyPreview } = useProperty();
  const isMobile = useIsMobile();

  return (
    <>
      <Box px={isMobile ? 0 : 3} py={isMobile ? 0 : 4}>
        {!isMobile && (
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}
          >
            <Grid item pb={2.5}>
              <PageTitle subTitle={selectedPropertyPreview} title={t("title__in_hotel")} />
            </Grid>
          </Grid>
        )}
        <ReservationTable defaultFilter={IN_HOTEL_RESERVATIONS_SEARCH_FILTERS} />
      </Box>
    </>
  );
};

export default InHotel;
