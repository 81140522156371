import { FormControl, MenuItem, Palette, Select, SelectChangeEvent, useTheme } from "@mui/material";
import { TaskStatus } from "../domain/task";
import { FC, useCallback, useState } from "react";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { Button } from "@likemagic-tech/sv-magic-library";
import { DisplayTaskIcon } from "./display-task-icon";

interface StatusCellMenuItemInterface {
  status: TaskStatus;
  disableHover?: boolean;
}

export const StatusCellMenuItem: FC<StatusCellMenuItemInterface> = ({ status, disableHover }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  return (
    <Button
      data-stop-propagation
      variant="ghost"
      size="medium"
      sx={{
        "&:hover": {
          backgroundColor: disableHover ? "transparent" : "auto"
        },
        color: statusColor(status, palette)
      }}
    >
      <DisplayTaskIcon status={status} />
      {status ? t(`labels__task_status_${status}`) : t(`labels__task_status`)}
    </Button>
  );
};

interface StatusCellProps {
  status?: TaskStatus;
  onStatusChange: (newStatus: TaskStatus) => void;
  alwaysShowText?: boolean;
}
export const statusColor = (status: TaskStatus | undefined | null, palette: Palette) => {
  if (status === TaskStatus.Done) {
    return palette.success.main;
  } else if (status === TaskStatus.InProgress) {
    return palette.warning.main;
  } else if (status === TaskStatus.Todo) {
    return palette.error.main;
  }
  return palette.primary.main; // Fallback color
};

export const StatusCell: FC<StatusCellProps> = ({ status, onStatusChange, alwaysShowText }) => {
  const isMobile = useIsMobile();
  const { spacing } = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  const handleCloseMenu = (e: Event) => {
    e.stopPropagation();
    setMenuOpen(false);
  };

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      setMenuOpen(false);
      onStatusChange(e?.target?.value as TaskStatus);
    },
    [onStatusChange]
  );

  return (
    <FormControl
      sx={{
        flexDirection: "row",
        alignItems: "center"
      }}
    >
      <Select
        renderValue={(p) => <StatusCellMenuItem status={p as TaskStatus} />}
        data-stop-propagation
        value={status || ""}
        displayEmpty
        onOpen={() => setMenuOpen(true)}
        IconComponent={() => null}
        onChange={handleChange}
        sx={{
          marginRight: alwaysShowText ? spacing(3) : 0,
          ...(!isMobile ? { minWidth: spacing(20) } : {}),
          ".MuiOutlinedInput-notchedOutline": {
            border: "none"
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "none"
          },
          ".MuiSelect-select": {
            padding: 0,
            paddingRight: "0!important"
          }
        }}
        MenuProps={{
          onClose: handleCloseMenu,
          open: menuOpen,
          anchorOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          }
        }}
      >
        {Object.values(TaskStatus).map((status) => (
          <MenuItem
            data-stop-propagation
            value={`${status}`}
            key={`${status}`}
            sx={{
              px: 0
            }}
          >
            <StatusCellMenuItem status={status} disableHover />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
