import React, { FC } from "react";
import { Box } from "@mui/material";
import { BaseTableFiltersProps, FilterObjectType, serializeIdOfFilter } from "./table-filters";
import { CheckboxFilter } from "./components/checkbox-filter";
import { ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { FilterOperator } from "../../../types/filters/filters";

export interface AdditionalFiltersProps extends BaseTableFiltersProps {
  additionalFilterNames: { [key: string]: Array<string> };
}

export const AdditionalFilters: FC<AdditionalFiltersProps> = ({
  filterMap,
  onFilterMapChange,
  additionalFilterNames
}) => {
  const { t } = useTranslationWrapper();
  const formatFilterId = (key: string) =>
    serializeIdOfFilter({
      type: FilterObjectType.ADDITIONAL,
      operator: FilterOperator.In,
      name: key
    });

  // There is a need for the filter because whenever we take out all filters we get an array with the first position being a empty string.
  const getFilterValueForKey = (key: string): Array<string> =>
    filterMap[formatFilterId(key)]?.value?.split(",").filter((item) => item !== "") || [];

  const onFilterChange = (key: string) => (values: Array<string>) => {
    onFilterMapChange({
      ...filterMap,
      [formatFilterId(key)]: {
        type: FilterObjectType.ADDITIONAL,
        value: values.length ? values.join(",") : undefined,
        operator: FilterOperator.In,
        name: key
      }
    });
  };

  return (
    <Box sx={{ display: "flex", gap: 3, mt: 1, flexWrap: "wrap" }}>
      {Object.keys(additionalFilterNames).map((key) => (
        <Box key={`additional-filter-${key}`}>
          <ParagraphSmallBold mb={2}>{t(`labels__filter_title_${key}`)}</ParagraphSmallBold>
          <CheckboxFilter
            options={additionalFilterNames[key]}
            onChange={onFilterChange(key)}
            values={getFilterValueForKey(key)}
          />
        </Box>
      ))}
    </Box>
  );
};
