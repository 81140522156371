import { UnitCondition } from "../../domain/Unit";
import type { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { filterableStatuses } from "./utils";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { QuickFilterCheckbox } from "src/components/quick-filter-checkbox/quick-filter-checkbox";
import { FilterObjectType } from "src/components/table/filters/table-filters";
import { FilterOperator } from "src/types/filters/filters";

interface UnitConditionFilterProps {
  value?: UnitCondition;
  onConditionSelected: (condition: UnitCondition) => void;
  countForUnitCondition?: {
    Clean: number;
    CleanToBeInspected: number;
    Dirty: number;
  };
}

export const mapUnitConditionLabels = {
  [UnitCondition.CLEAN]: "labels__unit_condition_Clean",
  [UnitCondition.CLEAN_TO_BE_INSPECTED]: "labels__unit_condition_CleanToBeInspected",
  [UnitCondition.DIRTY]: "labels__unit_condition_Dirty"
};

const mapUnitConditionColors = (
  condition: string
): "default" | "primary" | "error" | "info" | "success" | "warning" | undefined => {
  switch (condition) {
    case UnitCondition.DIRTY:
      return "error";
    case UnitCondition.CLEAN:
      return "success";
    case UnitCondition.CLEAN_TO_BE_INSPECTED:
      return "warning";
    default:
      return undefined;
  }
};

export const UnitConditionFilter: FC<UnitConditionFilterProps> = ({
  value,
  onConditionSelected,
  countForUnitCondition = { Clean: 0, CleanToBeInspected: 0, Dirty: 0 }
}) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();

  return (
    <Box sx={{ display: "flex", gap: spacing(1.25) }}>
      {filterableStatuses?.map((condition) => (
        <div key={`condition-${condition}`}>
          <QuickFilterCheckbox
            filter={{ name: "unitCondition" }}
            label={t(mapUnitConditionLabels[condition as keyof typeof mapUnitConditionLabels])}
            count={countForUnitCondition[condition as keyof typeof countForUnitCondition]}
            filterMap={
              value === condition
                ? {
                    [`ADDITIONAL-unitCondition-in`]: {
                      type: FilterObjectType.ADDITIONAL,
                      name: "unitCondition",
                      operator: FilterOperator.In,
                      value: condition
                    }
                  }
                : {}
            }
            onFilterMapChange={() => onConditionSelected(condition)}
            mapColors={mapUnitConditionColors}
            condition={condition}
          />
        </div>
      ))}
    </Box>
  );
};
