import { HousekeepingTaskType } from "../features/tasks/domain/task";
import { UpdateTaskRequest } from "../graphql-tasks/generated/graphql";
import { HousekeepingTaskOwnershipFilters } from "../domain/housekeeping-task-ownership-filters";

export const generateUpdateTaskRequest = (values: HousekeepingTaskType): UpdateTaskRequest => {
  const {
    id,
    attachments,
    updatedAt,
    assignee,
    assigneeGroup,
    subtasks,
    createdAt,
    reporter,
    parent,
    parentId,
    type,
    ...restOfValues
  } = values;

  return {
    ...restOfValues,
    links: {
      pmsUnitIds: restOfValues.links?.pmsUnitIds,
      pmsReservationIds: restOfValues.links?.pmsReservationIds,
      pmsDepartingReservationId: restOfValues.links?.pmsDepartingReservationId,
      pmsArrivingReservationId: restOfValues.links?.pmsArrivingReservationId
    },
    assigneeId: parseInt(assignee?.actorId ?? "") ?? 0,
    assigneeGroupId: parseInt(assigneeGroup?.actorId ?? "") ?? 0,
    attachments: attachments?.map((item) => item.uuid)
  };
};

export const housekeepingFilterParamSelector = (condition: string) => {
  switch (condition) {
    case HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME:
      return "assignedTo";
    case HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM:
      return "assignedToTeam";
  }
};
