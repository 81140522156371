import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import {
  Alert,
  Box,
  Grid,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography
} from "@mui/material";
import { MagicFile } from "../../../types/magic-file";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import { LoaderOrNotUploaded } from "./loader-or-not-uploaded";
import { Document, Page, pdfjs } from "react-pdf";
import { Person } from "../../../domain/person";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "src/store";
import {
  getMagicFiles,
  selectIdentificationDocument,
  selectIsMagicFilesDownloading,
  selectSignatureDocument
} from "../../../slices/magic-files.slice";
import {
  formatDate,
  Paragraph,
  useApiVersion,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { isDisabledIdentificationForCountry } from "src/utils/countries";
import { isPdf } from "../../../utils/file-utils";
import { IdCheckStatus } from "../../../domain/reservation";
import { Notification } from "../../notification";
import { ErrorOutline } from "@mui/icons-material";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PAGE_NUMBER = 1;
const ImageAndPdfHeight = 250;

interface ConfirmIdentityProps {
  files: MagicFile[];
  primaryGuest: Person;
  documentUploadEnabled?: boolean;
  propertyId?: string;
  idCheckStatus: IdCheckStatus;
}

const useStyles = makeStyles()((theme: Theme) => ({
  icon: {
    width: 32,
    height: 32,
    padding: 4,
    borderRadius: theme.spacing(8),
    color: theme.palette.info.dark
  }
}));

export const ConfirmIdentity: FC<ConfirmIdentityProps> = ({
  files,
  primaryGuest,
  documentUploadEnabled,
  propertyId,
  idCheckStatus
}) => {
  const { t } = useTranslationWrapper();
  const [zoomOpen, setZoomOpen] = useState(false);
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();
  const { classes } = useStyles();
  const theme = useTheme();

  const { disabledCountriesForIdentification, features } = usePropertyConfig({
    propertyId: propertyId
  });

  const identificationDocument = useSelector(selectIdentificationDocument);
  const signatureDocument = useSelector(selectSignatureDocument);
  const isFileDownloading = useSelector(selectIsMagicFilesDownloading);

  const pdfPreview = useMemo(
    () => (
      <Document file={identificationDocument?.fileSrc}>
        <Page pageNumber={PAGE_NUMBER} />
      </Document>
    ),
    [identificationDocument]
  );

  const imagePreview = useMemo(
    () => (
      <img
        style={{ width: "100%" }}
        src={identificationDocument?.fileSrc}
        alt={t("labels__preview")}
      />
    ),
    [identificationDocument, t]
  );

  useEffect(() => {
    dispatch(
      getMagicFiles({
        arg: files.map((item) => ({
          fileName: item.fileName,
          magicFileType: item.metaData.magicFileType,
          fileSrc: "",
          contentType: item.contentType
        })),
        isRESTVersion
      })
    );
  }, [files, dispatch, isRESTVersion]);

  const zoomInIdentification = useCallback(() => {
    setZoomOpen(true);
  }, []);

  const handleClosed = useCallback(() => setZoomOpen(false), []);

  return (
    <>
      {idCheckStatus === IdCheckStatus.REUPLOADED && (
        <Box mb={2}>
          <Notification title={t("labels__warning_reuploaded")} type="warning" showIcon={false} />
        </Box>
      )}
      <Grid container spacing={2}>
        {documentUploadEnabled && (
          <Grid item xs={12} md={6}>
            {identificationDocument ? (
              <Grid
                container
                sx={{
                  border: "1px solid #000000",
                  borderRadius: 1,
                  position: "relative",
                  cursor: "pointer",
                  background: theme.palette.grey[300]
                }}
                onClick={() => zoomInIdentification()}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    height: "100%",
                    overflow: "hidden",
                    maxHeight: ImageAndPdfHeight,
                    textAlign: "center"
                  }}
                >
                  <Grid
                    height={ImageAndPdfHeight}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {isPdf(identificationDocument?.contentType) ? pdfPreview : imagePreview}
                  </Grid>
                </Grid>
                <ZoomInIcon
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    opacity: 0.5
                  }}
                />
              </Grid>
            ) : (
              <LoaderOrNotUploaded
                isFileLoading={isFileDownloading}
                notUploadedLabel={t("labels__id_document_not_provided")}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={documentUploadEnabled ? 6 : 12}>
          {signatureDocument && !isFileDownloading ? (
            <Grid
              container
              textAlign="center"
              justifyContent="center"
              sx={{
                border: "1px solid #000000",
                borderRadius: 1,
                height: "100%",
                background: theme.palette.grey[300]
              }}
            >
              <img
                style={{ width: "100%" }}
                src={signatureDocument.fileSrc}
                alt={t("labels__signature")}
              />
            </Grid>
          ) : (
            <LoaderOrNotUploaded
              isFileLoading={isFileDownloading}
              notUploadedLabel={t("labels__signature_not_provided")}
            />
          )}
        </Grid>
      </Grid>

      {features?.tfeFeatureEnabled && (
        <Box mt={2}>
          <Alert
            icon={<ErrorOutline className={classes.icon} />}
            sx={{ alignItems: "center", backgroundColor: theme.palette.info.light }}
          >
            <Paragraph
              dangerouslySetInnerHTML={{ __html: t("labels__identity_check_signature") }}
            />
          </Alert>
        </Box>
      )}

      <Box sx={{ pt: 3 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__gender")}
                </Typography>
              </TableCell>
              <TableCell>
                {primaryGuest.gender && t("labels__gender_" + primaryGuest.gender)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__first_name")}
                </Typography>
              </TableCell>
              <TableCell>{primaryGuest.firstName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__last_name")}
                </Typography>
              </TableCell>
              <TableCell>{primaryGuest.lastName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__date_of_birth")}
                </Typography>
              </TableCell>
              <TableCell>{formatDate(primaryGuest.birthdate)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__nationality")}
                </Typography>
              </TableCell>
              <TableCell>{primaryGuest.nationalityCountryCode}</TableCell>
            </TableRow>
            {!isDisabledIdentificationForCountry(
              disabledCountriesForIdentification,
              primaryGuest.nationalityCountryCode
            ) && (
              <TableRow>
                <TableCell>
                  <Typography color="textPrimary" variant="subtitle2">
                    {t("labels__id_number")}
                  </Typography>
                </TableCell>
                <TableCell>{primaryGuest.identificationNumber}</TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__phone_number")}
                </Typography>
              </TableCell>
              <TableCell>{primaryGuest.phone}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography color="textPrimary" variant="subtitle2">
                  {t("labels__email")}
                </Typography>
              </TableCell>
              <TableCell>{primaryGuest.email}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Modal open={zoomOpen} onClose={handleClosed}>
        <Box
          sx={{
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            mt: "5%",
            ml: "5%",
            height: "90%",
            width: "90%",
            position: "relative",
            justifyContent: "center",
            alignContent: "center"
          }}
          style={{ overflow: "scroll" }}
        >
          {isPdf(identificationDocument?.contentType) ? pdfPreview : imagePreview}
          <CloseIcon
            fontSize="large"
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleClosed}
          />
        </Box>
      </Modal>
    </>
  );
};
