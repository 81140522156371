import { Select, styled } from "@mui/material";

export const CustomSelector = styled(Select, {
  shouldForwardProp: (prop) => prop !== "selected"
})<{ selected: boolean }>(({ theme, selected }) => ({
  width: "auto",
  height: theme.spacing(4),
  backgroundColor: "unset",
  "&&:hover": {
    backgroundColor: "unset"
  },
  "&.Mui-focused": {
    backgroundColor: "unset"
  },
  "& .MuiFilledInput-input:focus": {
    borderRadius: theme.spacing(0.75),

    backgroundColor: selected ? theme.palette.secondary.main : "unset"
  },
  "& .MuiFilledInput-root": {
    borderRadius: theme.spacing(0.75),
    backgroundColor: "unset",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    boxSizing: "border-box"
  },
  "& .MuiInputBase-input": {
    height: theme.spacing(4),
    justifyContent: "start",
    alignItems: "center",
    display: "flex",
    boxSizing: "border-box",
    padding: theme.spacing(0.5, 1, 0.5, 1),
    borderRadius: theme.spacing(0.75),
    backgroundColor: selected ? theme.palette.secondary.main : theme.palette.common.white,
    color: selected ? theme.palette.common.white : theme.palette.common.black,
    fontSize: "14px"
  },
  "& .MuiSelect-icon": {
    color: selected ? theme.palette.common.white : "inherit"
  },
  "&.MuiInputLabel-root": {
    lineHeight: 1
  },
  "&.MuiInputLabel-outlined": {
    lineHeight: 1
  },
  "& .MuiInputLabel-root": {
    lineHeight: 1
  },
  "& .MuiInputLabel-outlined": {
    lineHeight: 1
  }
}));
