import i18n from "i18next";
export const getTranslatedLanguageName = ({ code, locale }: { code: string; locale: string }) => {
  const displayNames = new Intl.DisplayNames([locale], { type: "language" });

  const name = displayNames.of(code);

  return name ? name.charAt(0).toUpperCase() + name.slice(1) : "";
};

export const getI18nSelectedLanguage = () => i18n.language.toLowerCase();
