import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Box, TableCell, TableRow } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Chip, ParagraphBold, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { Person } from "src/domain/person";
import { FC, useMemo } from "react";
import { PreferencesInRowPreview } from "./preferences-in-row-preview";
import {
  getI18nSelectedLanguage,
  getTranslatedLanguageName
} from "../../../../../../utils/language";
import { twoLetterLanguageCode } from "../../../../../../components/language-selector/language-selector";
import { genderPronounsLabel } from "../../guest-insights/reservation-details-guest-insight-utils";

/**
 * Returns the a table of content with info about the guests.
 * From the json the BE sends, the primaryGuest and Booker are objects while
 * travelBuddies is an array of objects.
 */

interface RowsForTableGuestOverviewInterface {
  reservation: ReservationDetailsDTO;
}
interface GuestOverview {
  person: Person;
  guestType: GuestType;
}

export const guestTypeLabelsMap = {
  PRIMARY_GUEST: "labels__reservation__primary__guest",
  BOOKER: "labels__reservation__booker",
  TRAVEL_BUDDY: "labels__reservation__travel__buddy",
  SAME_GUEST: "labels__reservation__same__guest"
};
export enum GuestType {
  PRIMARY_GUEST = "PRIMARY_GUEST",
  BOOKER = "BOOKER",
  TRAVEL_BUDDY = "TRAVEL_BUDDY",
  SAME_GUEST = "SAME_GUEST"
}

export enum GuestPreferencesActor {
  PRIMARY_GUEST = "PRIMARY_GUEST",
  BOOKER = "BOOKER"
}
export const RowsForTableGuestOverview: FC<RowsForTableGuestOverviewInterface> = ({
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const language = getI18nSelectedLanguage();
  const theme = useTheme();
  const { booker, primaryGuest, travelBuddies, primaryGuestSameAsBooker } = reservation || {};

  const guestOverviews: GuestOverview[] = useMemo(() => {
    const travelBuddiesGuestOverviews: GuestOverview[] = travelBuddies.map((travelBuddy) => {
      return { person: travelBuddy, guestType: GuestType.TRAVEL_BUDDY };
    });
    if (primaryGuestSameAsBooker) {
      return [
        {
          person: primaryGuest,
          guestType: GuestType.SAME_GUEST
        },
        ...travelBuddiesGuestOverviews
      ];
    }
    return [
      {
        person: booker,
        guestType: GuestType.BOOKER
      },
      {
        person: primaryGuest,
        guestType: GuestType.PRIMARY_GUEST
      },
      ...travelBuddiesGuestOverviews
    ];
  }, [primaryGuestSameAsBooker, booker, primaryGuest, travelBuddies]);

  const showRecurringInfo = (guestOverview: GuestOverview) => {
    return (
      guestOverview.guestType === GuestType.SAME_GUEST ||
      guestOverview.guestType === GuestType.PRIMARY_GUEST
    );
  };

  return (
    <>
      {guestOverviews.map((guestOverview: GuestOverview) => (
        <TableRow key={`person-row-${guestOverview.person.userProfileId}`}>
          <TableCell>
            <ParagraphBold>
              {guestOverview.person?.firstName} {guestOverview.person?.lastName}
            </ParagraphBold>
            <Box display="flex">
              {guestOverview.person?.gender && (
                <ParagraphBold color={theme.palette.text.secondary} paddingRight={1}>
                  {genderPronounsLabel(guestOverview.person?.gender, t)}
                </ParagraphBold>
              )}
              {showRecurringInfo(guestOverview) && (
                <Chip
                  size="small"
                  color="info"
                  label={
                    guestOverview.person?.isRecurring
                      ? t("labels__reservation__details__overview__guests__recurring")
                      : t("labels__reservation__details__overview__guests__new")
                  }
                />
              )}
            </Box>
          </TableCell>
          <TableCell>
            <Chip
              size="small"
              color="default"
              label={t(guestTypeLabelsMap[guestOverview.guestType])}
            />
          </TableCell>
          <TableCell>
            <ParagraphSmall>{guestOverview.person?.phone}</ParagraphSmall>
            <ParagraphSmall>{guestOverview.person?.email}</ParagraphSmall>
          </TableCell>
          <TableCell>
            <ParagraphSmall>
              {guestOverview?.person?.preferredCommunicationChannel
                ? t(
                    `labels__guest__communication__channel__${guestOverview?.person?.preferredCommunicationChannel}`
                  )
                : ""}
            </ParagraphSmall>
          </TableCell>
          <TableCell>
            <ParagraphSmall>
              {guestOverview?.person?.preferredLanguage
                ? getTranslatedLanguageName({
                    code: twoLetterLanguageCode(guestOverview?.person?.preferredLanguage),
                    locale: language
                  })
                : ""}
            </ParagraphSmall>
          </TableCell>
          <TableCell>
            {GuestType.TRAVEL_BUDDY !== guestOverview.guestType && (
              <PreferencesInRowPreview
                preferences={guestOverview?.person?.guestPreferences}
                preferencesText={guestOverview?.person?.guestPreferencesText}
                propertyId={reservation.propertyId}
              />
            )}
          </TableCell>
          <TableCell align="right" />
        </TableRow>
      ))}
    </>
  );
};
