import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useProperty } from "../../../../hooks/use-property";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Caption, Input, ParagraphSmall, useAuth } from "@likemagic-tech/sv-magic-library";
import ChevronDown from "src/icons/ChevronDown";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, useTheme } from "@mui/material";
import ChevronUp from "../../../../icons/ChevronUp";
import CheckIcon from "@mui/icons-material/Check";
import { useLocation } from "react-router-dom";
import {
  BACKOFFICE_ROUTES,
  BACKOFFICE_ROUTES_MULTI_PROPERTY,
  BackofficeRoute
} from "../../../../routes";
import { useNavigateWithPropertyId } from "../../../../hooks/use-navigate-with-propertyId";
import { generateDefaulMessagingTableURLSearchParams } from "../../../../messaging/domain/conversation-constants";

const PropertySelector: FC = () => {
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLElement | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigateWithPropertyId();

  const { hasAdminOrOperatorRole } = useAuth();
  const {
    allProperties,
    selectedProperty,
    selectedPropertyIds,
    setSelectedPropertyId,
    selectedPropertyPreview,
    isAccountSelected
  } = useProperty();

  const isAdmin = useMemo(() => {
    return hasAdminOrOperatorRole();
  }, [hasAdminOrOperatorRole]);

  const handleChangeProperty = (propertyId: string): void => {
    setSelectedPropertyId(propertyId);
  };

  useEffect(() => {
    // If the user switches from a single property to a multi-property view, and the page he is on is not a multi-property page, redirect him to the first multi-property page.
    if (
      isAccountSelected &&
      !BACKOFFICE_ROUTES_MULTI_PROPERTY.includes(pathname.split("/")[1] as BackofficeRoute)
    ) {
      // This is defining the page that we are going to redirect the user to.
      navigate(BACKOFFICE_ROUTES.MESSAGING, generateDefaulMessagingTableURLSearchParams());
    }
  }, [allProperties, isAccountSelected, navigate, pathname, selectedPropertyIds]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box ref={anchorRef} sx={{ width: "100%" }}>
        <Input
          value={selectedPropertyPreview}
          label={t("labels__property")}
          variant={"outlined"}
          fullWidth
          readOnly
          endAdornment={
            isMenuOpen ? (
              <ChevronUp sx={{ cursor: "pointer", ml: 1 }} />
            ) : (
              <ChevronDown sx={{ cursor: "pointer", ml: 1 }} />
            )
          }
          sx={{
            ".MuiOutlinedInput-root": {
              cursor: "pointer",
              fontSize: "14px"
            },
            "& .MuiInputBase-input": {
              overflow: "hidden",
              textOverflow: "ellipsis"
            }
          }}
          inputProps={{ style: { cursor: "pointer" } }}
          onClick={() => allProperties.length > 1 && setIsMenuOpen(true)}
          disabled={allProperties.length === 1}
        />
      </Box>
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={anchorRef?.current}
        sx={{ mt: 1, boxShadow: theme.shadows[2] }}
      >
        <MenuList sx={{ width: theme.spacing(34), outline: "none", padding: 0 }}>
          {isAdmin && (
            <MenuItem
              onClick={() => {
                handleChangeProperty("account");
                setIsMenuOpen(false);
              }}
              sx={{ py: 1.25 }}
            >
              <ListItemText>
                <ParagraphSmall>{t("labels__account")}</ParagraphSmall>
              </ListItemText>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {isAccountSelected && (
                  <CheckIcon fontSize="inherit" sx={{ color: theme.palette.common.black }} />
                )}
              </ListItemIcon>
            </MenuItem>
          )}
          {isAdmin && (
            <Box
              sx={{ display: "flex", justifyContent: "space-between", px: 2, py: isAdmin ? 1 : 0 }}
            >
              <Caption sx={{ color: theme.palette.grey[500] }}>{t("labels__properties")}</Caption>
            </Box>
          )}
          {allProperties.map((property) => (
            <MenuItem
              onClick={() => {
                handleChangeProperty(property.propertyId);
                setIsMenuOpen(false);
              }}
              sx={{ py: 1.25 }}
              key={property.propertyId}
            >
              <ListItemText>
                <ParagraphSmall>{property?.details?.name}</ParagraphSmall>
              </ListItemText>
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                {selectedPropertyIds.length === 1 &&
                  selectedProperty.propertyId === property.propertyId && (
                    <CheckIcon fontSize="inherit" sx={{ color: theme.palette.common.black }} />
                  )}
              </ListItemIcon>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default PropertySelector;
