import { FC } from "react";
import { TransactionsForProfile } from "../../types/profile";
import { TableCell, TableRow } from "@mui/material";
import { formatDateTime, Paragraph } from "@likemagic-tech/sv-magic-library";
import { PricePreview } from "src/components/price-preview/price-preview";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ProfileDrawerTransactionTableRowProps {
  item: TransactionsForProfile;
}

export const ProfileDrawerTransactionTableRow: FC<ProfileDrawerTransactionTableRowProps> = ({
  item
}) => {
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  return (
    <TableRow hover key={item.pmsId}>
      <TableCell />
      <TableCell>
        <Paragraph>{item.displayableReservationId}</Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>{item.name}</Paragraph>
      </TableCell>
      <TableCell>
        <Paragraph>
          {formatDateTime(item.start, language, selectedProperty.details.timeZone)}
        </Paragraph>
      </TableCell>
      <TableCell>
        <PricePreview
          price={{ amount: item.price.grossPriceInCents ?? 0, currency: item.price.currency ?? "" }}
        />
      </TableCell>
      <TableCell />
      <TableCell />
    </TableRow>
  );
};
