import { ReservationCheckInOutAuthor } from "../../../../graphql/generated/graphql";

export interface Step {
  id: string;
  tooltipText: string;
  hasEmptySpace?: boolean;
  author?: ReservationCheckInOutAuthor;
  dateTime?: string | null;
  preSteps?: Step[];
  isPreStepDone?: boolean;
}

export enum PrecheckoutSteps {
  DEPARTURE_TIME = "PRECHECKOUT_DEPARTURE_TIME",
  MINIBAR = "PRECHECKOUT_MINIBAR",
  BILL = "PRECHECKOUT_BILL",
  PAYMENT = "PRECHECKOUT_PAYMENT",
  CONFIRMATION = "PRECHECKOUT_CONFIRMATION"
}

export enum MonitoringSteps {
  BOOKING = "BOOKING",
  CHECK_IN = "CHECK_IN",
  IN_HOUSE = "IN_HOUSE",
  CHECK_OUT = "CHECK_OUT"
}

export enum AuthorPreview {
  EXTERNAL = "EXTERNAL",
  GUEST = "GUEST",
  PROPERTY = "PROPERTY"
}

export const mapReservationCheckInOutAuthorToAuthorPreview = (
  author?: ReservationCheckInOutAuthor
): AuthorPreview => {
  switch (author) {
    case ReservationCheckInOutAuthor.Backoffice:
    case ReservationCheckInOutAuthor.CheckinWizard:
    case ReservationCheckInOutAuthor.LmAutomation:
      return AuthorPreview.PROPERTY;
    case ReservationCheckInOutAuthor.Pms:
      return AuthorPreview.EXTERNAL;
    default:
      return AuthorPreview.GUEST;
  }
};

interface IsPreStepDoneProps {
  steps: Array<string>;
  stepId: string;
  lastConfirmedPage: string;
}

export const isPreStepDone = ({
  steps,
  stepId,
  lastConfirmedPage
}: IsPreStepDoneProps): boolean => {
  if (steps.indexOf(stepId) === -1 || steps.indexOf(lastConfirmedPage) === -1) {
    return false;
  }

  return steps.indexOf(stepId) <= steps.indexOf(lastConfirmedPage);
};

interface IsPassedProps {
  flatSteps: Step[];
  currentStepId: string;
  stepId: string;
}

export const isPassed = ({ flatSteps, currentStepId, stepId }: IsPassedProps) => {
  const currentStepIndex = flatSteps.findIndex((flatStep) => flatStep.id === currentStepId);
  const stepIndex = flatSteps.findIndex((flatStep) => flatStep.id === stepId);

  return currentStepIndex > stepIndex;
};
