export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigDecimal: { input: any; output: any };
  Instant: { input: any; output: any };
  Json: { input: any; output: any };
  LocalDate: { input: any; output: any };
  ZonedDateTime: { input: any; output: any };
};

export type AccessibleDoor = {
  __typename?: "AccessibleDoor";
  id?: Maybe<Scalars["String"]["output"]>;
  isGeneral?: Maybe<Scalars["Boolean"]["output"]>;
  pin?: Maybe<Scalars["String"]["output"]>;
  privacyMode?: Maybe<Scalars["Boolean"]["output"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export enum AccountingState {
  Canceled = "CANCELED",
  Closed = "CLOSED",
  Inactive = "INACTIVE",
  Open = "OPEN"
}

export enum Actor {
  Booker = "BOOKER",
  PrimaryGuest = "PRIMARY_GUEST",
  TravelBuddy = "TRAVEL_BUDDY"
}

export type AddArbitraryOrderItemInput = {
  folioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  note?: InputMaybe<Scalars["String"]["input"]>;
  pmsFolioId: Scalars["String"]["input"];
  price: GrossPriceInput;
  quantity: Scalars["Int"]["input"];
  transactionTypeCode: Scalars["String"]["input"];
  transactionTypePmsId: Scalars["String"]["input"];
};

export type AdditionalDetails = {
  origin?: InputMaybe<Scalars["String"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type AdditionalServiceAvailabilityDto = {
  __typename?: "AdditionalServiceAvailabilityDTO";
  available: Scalars["Boolean"]["output"];
  basePrice: Price;
  bookableDates: Array<DateAndCount>;
  bookedDates: Array<DateAndCount>;
  canBookMultiple?: Maybe<Scalars["Boolean"]["output"]>;
  defaultDatePickerSelection?: Maybe<DefaultDatePickerSelection>;
  defaultTargetAudience?: Maybe<DefaultTargetAudience>;
  granularity: Granularity;
  magicServiceCodeEnum?: Maybe<Scalars["String"]["output"]>;
  /**  only for early-checkin / late checkout */
  maximumHours?: Maybe<Scalars["Int"]["output"]>;
  /**  TODO remove when backoffice gets service definitions */
  name?: Maybe<Scalars["String"]["output"]>;
  payAtCheckout?: Maybe<Scalars["Boolean"]["output"]>;
  pmsServiceId: Scalars["String"]["output"];
  shopTypes?: Maybe<Array<Maybe<ShopType>>>;
  visibilities?: Maybe<Array<Visibility>>;
};

export type Address = {
  __typename?: "Address";
  addressLine1?: Maybe<Scalars["String"]["output"]>;
  addressLine2?: Maybe<Scalars["String"]["output"]>;
  city?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  postalCode?: Maybe<Scalars["String"]["output"]>;
  region?: Maybe<Scalars["String"]["output"]>;
};

export type AdjustOrderItemInput = {
  grossPrice?: InputMaybe<GrossPriceInput>;
  percentage?: InputMaybe<Scalars["BigDecimal"]["input"]>;
  pmsOrderItemIds: Array<Scalars["String"]["input"]>;
  pmsReasonCodeId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
  priceAdjustmentType: PriceAdjustmentType;
  reasonText: Scalars["String"]["input"];
};

export type AdyenStoredPaymentMethod = {
  __typename?: "AdyenStoredPaymentMethod";
  brand?: Maybe<Scalars["String"]["output"]>;
  expiryMonth?: Maybe<Scalars["String"]["output"]>;
  expiryYear?: Maybe<Scalars["String"]["output"]>;
  holderName?: Maybe<Scalars["String"]["output"]>;
  iban?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastFour?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  ownerName?: Maybe<Scalars["String"]["output"]>;
  shopperEmail?: Maybe<Scalars["String"]["output"]>;
  shopperReference?: Maybe<Scalars["String"]["output"]>;
  supportedShopperInteractions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type Amount = {
  amount?: InputMaybe<Scalars["Int"]["input"]>;
  currency?: InputMaybe<Scalars["String"]["input"]>;
};

export type AvailableDoorDto = {
  __typename?: "AvailableDoorDto";
  batteryLevel?: Maybe<BatteryLevel>;
  id?: Maybe<Scalars["String"]["output"]>;
  lockAccessibility?: Maybe<DoorLockAccessibility>;
  pin?: Maybe<Scalars["String"]["output"]>;
  privacyMode?: Maybe<Scalars["Boolean"]["output"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]["output"]>;
  sortPriority?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  unitId?: Maybe<Scalars["String"]["output"]>;
};

export type AvailableTerminal = {
  __typename?: "AvailableTerminal";
  id?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type BackofficeAccessibleDoor = {
  __typename?: "BackofficeAccessibleDoor";
  batteryLevel?: Maybe<BatteryLevel>;
  id: Scalars["String"]["output"];
  isGeneral?: Maybe<Scalars["Boolean"]["output"]>;
  lockAccessibility?: Maybe<DoorLockAccessibility>;
  /** @deprecated No longer supported */
  pin?: Maybe<Scalars["String"]["output"]>;
  privacyMode?: Maybe<Scalars["Boolean"]["output"]>;
  /** # todo not this pr - remove this */
  sortPriority?: Maybe<Scalars["Int"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type BackofficeConditionCountsFilter = {
  filters?: InputMaybe<Scalars["Json"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type BackofficeKeyCard = {
  __typename?: "BackofficeKeyCard";
  createdAt?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["String"]["output"];
  isCopy?: Maybe<Scalars["Boolean"]["output"]>;
  requestor?: Maybe<DoorAccessRequestor>;
};

export type BackofficeMagicFileDescriptor = {
  __typename?: "BackofficeMagicFileDescriptor";
  contentType: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  metaData?: Maybe<Scalars["Json"]["output"]>;
};

export enum BackofficeManualCheckInCheckpoint {
  Confirmation = "CONFIRMATION",
  Payment = "PAYMENT",
  RegistrationPersonalData = "REGISTRATION_PERSONAL_DATA",
  RegistrationVerifyAndSign = "REGISTRATION_VERIFY_AND_SIGN",
  Services = "SERVICES"
}

export type BackofficeNote = {
  __typename?: "BackofficeNote";
  beddingComment?: Maybe<Array<Scalars["String"]["output"]>>;
  bookerComment?: Maybe<Array<Scalars["String"]["output"]>>;
  bookingComment?: Maybe<Array<Scalars["String"]["output"]>>;
  comment?: Maybe<Array<Scalars["String"]["output"]>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  guestComment?: Maybe<Array<Scalars["String"]["output"]>>;
  previousVisits?: Maybe<Scalars["Int"]["output"]>;
  rateBreakdownComment?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type BackofficeOverviewDetailEvent = {
  pmsReservationId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: BackofficeOverviewDetailEventType;
};

export enum BackofficeOverviewDetailEventType {
  SecondScreenBackofficeEvent = "SECOND_SCREEN_BACKOFFICE_EVENT"
}

export type BackofficePing = BackofficeOverviewDetailEvent & {
  __typename?: "BackofficePing";
  pmsReservationId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: BackofficeOverviewDetailEventType;
};

export type BackofficeRateBreakdown = {
  __typename?: "BackofficeRateBreakdown";
  rateSpliceTypeTuples?: Maybe<Array<RateSpliceTypeTuple>>;
};

export type BackofficeRateSplice = {
  __typename?: "BackofficeRateSplice";
  date?: Maybe<Scalars["LocalDate"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<GrossPrice>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export type BackofficeReservationOverviewDetail = BackofficeReservationOverviewTable & {
  __typename?: "BackofficeReservationOverviewDetail";
  accessibleDoors: Array<BackofficeAccessibleDoor>;
  adultsCount: Scalars["Int"]["output"];
  allowedActions: Array<ReservationAction>;
  arrival: Scalars["Instant"]["output"];
  balance?: Maybe<GrossPrice>;
  bookedPmsUnitGroupId?: Maybe<Scalars["String"]["output"]>;
  bookedServicesOverview: Array<BookedService>;
  booker?: Maybe<Person>;
  bookingChannel?: Maybe<BookingChannelFilterValue>;
  bookingMagicLink: Scalars["String"]["output"];
  canCheckout: Scalars["Boolean"]["output"];
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]["output"]>;
  checkoutOnAr?: Maybe<Scalars["Boolean"]["output"]>;
  checkoutTime?: Maybe<Scalars["Instant"]["output"]>;
  childrenAges?: Maybe<Array<Scalars["Int"]["output"]>>;
  childrenCount: Scalars["Int"]["output"];
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  contractualCheckInTime?: Maybe<Scalars["Instant"]["output"]>;
  createdAt: Scalars["Instant"]["output"];
  departure: Scalars["Instant"]["output"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]["output"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]["output"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]["output"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]["output"]>;
  files: Array<BackofficeMagicFileDescriptor>;
  folios: Array<Folio>;
  foliosToBePaid: Array<Folio>;
  groupedRateBreakdown?: Maybe<GroupedRateBreakdown>;
  idCheckStatus: IdCheckStatus;
  keyCards?: Maybe<Array<BackofficeKeyCard>>;
  lastConfirmedCheckoutPage?: Maybe<GuestJourneyCheckoutFlowCheckpoint>;
  lastConfirmedPage?: Maybe<GuestFlowCheckPoint>;
  magicId: Scalars["String"]["output"];
  magicLink: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  maxCompanions: Scalars["Int"]["output"];
  notes?: Maybe<BackofficeNote>;
  pmsOriginalPropertyId: Scalars["String"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  pmsReservationId: Scalars["String"]["output"];
  /**
   *  null if the property/unit group/... doesn't support it
   *  otherwise always populated (either implicit or explicit choice)
   */
  preferredCleaningDays?: Maybe<Array<DayOfWeek>>;
  primaryGuest?: Maybe<Person>;
  primaryGuestSameAsBooker?: Maybe<Scalars["Boolean"]["output"]>;
  /** @deprecated Use the 'groupedRateBreakdown' field instead */
  rateBreakdown?: Maybe<BackofficeRateBreakdown>;
  reservationCondition?: Maybe<ReservationCondition>;
  reservationConditionReasons?: Maybe<Scalars["Json"]["output"]>;
  reservationIsPaid?: Maybe<Scalars["Boolean"]["output"]>;
  reservationStatus?: Maybe<ReservationStatus>;
  reservationUpsellInformation?: Maybe<ReservationUpsellInformation>;
  restaurantReservations?: Maybe<Array<Maybe<RestaurantReservation>>>;
  sentNotifications: Array<BackofficeSentNotification>;
  /** @deprecated Use the 'bookedServicesOverview' field instead */
  services: Array<BackofficeService>;
  travelBuddies: Array<Person>;
  unit?: Maybe<BackofficeUnit>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]["output"]>;
};

export type BackofficeReservationOverviewFilter = {
  filters?: InputMaybe<Scalars["Json"]["input"]>;
  pageable: Pageable;
  pmsPropertyId: Scalars["String"]["input"];
};

export type BackofficeReservationOverviewTable = {
  adultsCount: Scalars["Int"]["output"];
  allowedActions: Array<ReservationAction>;
  arrival: Scalars["Instant"]["output"];
  balance?: Maybe<GrossPrice>;
  bookedPmsUnitGroupId?: Maybe<Scalars["String"]["output"]>;
  booker?: Maybe<Person>;
  bookingChannel?: Maybe<BookingChannelFilterValue>;
  bookingMagicLink: Scalars["String"]["output"];
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]["output"]>;
  checkoutOnAr?: Maybe<Scalars["Boolean"]["output"]>;
  checkoutTime?: Maybe<Scalars["Instant"]["output"]>;
  childrenAges?: Maybe<Array<Scalars["Int"]["output"]>>;
  childrenCount: Scalars["Int"]["output"];
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  contractualCheckInTime?: Maybe<Scalars["Instant"]["output"]>;
  createdAt: Scalars["Instant"]["output"];
  departure: Scalars["Instant"]["output"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]["output"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]["output"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]["output"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]["output"]>;
  files: Array<BackofficeMagicFileDescriptor>;
  idCheckStatus: IdCheckStatus;
  lastConfirmedCheckoutPage?: Maybe<GuestJourneyCheckoutFlowCheckpoint>;
  lastConfirmedPage?: Maybe<GuestFlowCheckPoint>;
  magicId: Scalars["String"]["output"];
  magicLink: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  notes?: Maybe<BackofficeNote>;
  pmsOriginalPropertyId: Scalars["String"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  pmsReservationId: Scalars["String"]["output"];
  primaryGuest?: Maybe<Person>;
  primaryGuestSameAsBooker?: Maybe<Scalars["Boolean"]["output"]>;
  reservationCondition?: Maybe<ReservationCondition>;
  reservationConditionReasons?: Maybe<Scalars["Json"]["output"]>;
  reservationIsPaid?: Maybe<Scalars["Boolean"]["output"]>;
  reservationStatus?: Maybe<ReservationStatus>;
  services: Array<BackofficeService>;
  travelBuddies: Array<Person>;
  unit?: Maybe<BackofficeUnit>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]["output"]>;
};

export type BackofficeReservationOverviewTablePage = {
  __typename?: "BackofficeReservationOverviewTablePage";
  content: Array<BackofficeReservationOverviewTable>;
  totalElements: Scalars["Int"]["output"];
};

export type BackofficeSentNotification = {
  __typename?: "BackofficeSentNotification";
  /**  leaving these without 'pms' in front because we can reuse the DTO */
  bookingId?: Maybe<Scalars["String"]["output"]>;
  channel?: Maybe<CommunicationChannel>;
  contentLink?: Maybe<Scalars["String"]["output"]>;
  conversationId?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Instant"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  language?: Maybe<CommunicationLanguage>;
  lastUpdateTimestamp?: Maybe<Scalars["Instant"]["output"]>;
  magicId?: Maybe<Scalars["String"]["output"]>;
  recipient?: Maybe<Scalars["String"]["output"]>;
  reservationId?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  template?: Maybe<NotificationType>;
  travelBuddyId?: Maybe<Scalars["String"]["output"]>;
};

/**  deprecated */
export type BackofficeService = {
  __typename?: "BackofficeService";
  code?: Maybe<ServiceCodeEnum>;
  dates: Array<Scalars["LocalDate"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Price>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
};

export type BackofficeUnit = {
  __typename?: "BackofficeUnit";
  /** # todo check todo in v1 */
  condition?: Maybe<UnitState>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsUnitGroupId?: Maybe<Scalars["String"]["output"]>;
  pmsUnitId?: Maybe<Scalars["String"]["output"]>;
  privacyMode?: Maybe<Scalars["Boolean"]["output"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]["output"]>;
};

export enum BalanceFilterValue {
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
  Zero = "ZERO"
}

export enum BatteryLevel {
  Critical = "CRITICAL",
  Fresh = "FRESH",
  Good = "GOOD",
  Low = "LOW",
  None = "NONE",
  Unknown = "UNKNOWN"
}

export type BillingConfirmed = SecondScreenEvent & {
  __typename?: "BillingConfirmed";
  deviceId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type BookedService = {
  __typename?: "BookedService";
  dates: Array<BookedServiceDate>;
  service?: Maybe<BookedServiceDetails>;
};

export type BookedServiceDate = {
  __typename?: "BookedServiceDate";
  /**
   *  We split one ServiceOrder with a single getPrice() field into n entries depending on serviceOrder.getQuantity().
   *  So we don't know the actual price of this entry, just the calculated average
   */
  amount?: Maybe<Price>;
  serviceDate?: Maybe<Scalars["LocalDate"]["output"]>;
  tags?: Maybe<Array<BookedServiceTag>>;
};

export type BookedServiceDetails = {
  __typename?: "BookedServiceDetails";
  included?: Maybe<Scalars["Boolean"]["output"]>;
  magicServiceCodeEnum?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  pmsCategoryId?: Maybe<Scalars["String"]["output"]>;
  pmsServiceId: Scalars["String"]["output"];
  tags?: Maybe<Array<BookedServiceTag>>;
  totalAmount?: Maybe<Price>;
};

export enum BookedServiceTag {
  /** KEY */
  Included = "INCLUDED",
  Purchased = "PURCHASED"
}

export type Booking = {
  __typename?: "Booking";
  actor: Actor;
  bookingComment?: Maybe<Scalars["String"]["output"]>;
  expectedNumberOfReservations: Scalars["Int"]["output"];
  flowState: FlowState;
  id: Scalars["Int"]["output"];
  magicId: Scalars["String"]["output"];
  magicLink: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
};

export enum BookingChannelFilterValue {
  Agoda = "AGODA",
  Airbnb = "AIRBNB",
  Booking = "BOOKING",
  Direct = "DIRECT",
  Expedia = "EXPEDIA",
  Ibe = "IBE",
  Other = "OTHER"
}

export type BookingFilter = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum BookingOnBehalfOf {
  Myself = "MYSELF",
  Others = "OTHERS"
}

export type BookingOverview = {
  __typename?: "BookingOverview";
  actor: Actor;
  bookingId: Scalars["Int"]["output"];
  flowState: FlowState;
  items: Array<BookingOverviewItem>;
  magicId: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  pmsBookingId: Scalars["String"]["output"];
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
};

export type BookingOverviewItem = {
  __typename?: "BookingOverviewItem";
  active: Scalars["Boolean"]["output"];
  arrival: Scalars["Instant"]["output"];
  bookingId: Scalars["Int"]["output"];
  bookingManagementCompleted: Scalars["Boolean"]["output"];
  departure: Scalars["Instant"]["output"];
  id: Scalars["String"]["output"];
  pmsBookingId: Scalars["String"]["output"];
  pmsPropertyId: Scalars["String"]["output"];
  propertyId: Scalars["Int"]["output"];
  reservations: Array<Reservation>;
  travelBuddies: Array<TravelBuddy>;
};

export type BookingPrice = {
  __typename?: "BookingPrice";
  accommodation?: Maybe<Price>;
  prepaid?: Maybe<Scalars["Boolean"]["output"]>;
  prepayment?: Maybe<GuaranteeType>;
  total?: Maybe<Price>;
};

export type CalculatedUpsellInformation = {
  __typename?: "CalculatedUpsellInformation";
  averagePerNightAmount: GrossPrice;
  averagePerNightAmountDifference: GrossPrice;
  originalAveragePerNightAmount: GrossPrice;
  totalAmountDifference: GrossPrice;
};

export type CancelTerminalPaymentRequest = {
  pmsPropertyId: Scalars["String"]["input"];
  serviceId: Scalars["String"]["input"];
  terminalId: Scalars["String"]["input"];
  transactionId: Scalars["String"]["input"];
};

export type Canceled = SecondScreenEvent & {
  __typename?: "Canceled";
  deviceId: Scalars["String"]["output"];
  payload: CanceledPayload;
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type CanceledPayload = {
  __typename?: "CanceledPayload";
  pmsReservationId: Scalars["String"]["output"];
};

export type CancellationFee = {
  __typename?: "CancellationFee";
  description?: Maybe<Scalars["String"]["output"]>;
  dueDateTime?: Maybe<Scalars["Instant"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<GrossPrice>;
};

export type CardOnFilePaymentRequest = {
  amount: Amount;
  applySurcharges?: InputMaybe<Scalars["Boolean"]["input"]>;
  magicId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
  pointOfSales?: InputMaybe<Scalars["Boolean"]["input"]>;
  shopperReference: Scalars["String"]["input"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  token: Scalars["String"]["input"];
};

export type CardPaymentMethod = {
  __typename?: "CardPaymentMethod";
  hashCode: Scalars["String"]["output"];
  paymentMethods?: Maybe<Array<Maybe<PossiblePaymentMethod>>>;
  storedPaymentMethods?: Maybe<Array<Maybe<AdyenStoredPaymentMethod>>>;
  type: PaymentMethodType;
};

export type CashPaymentMethod = {
  __typename?: "CashPaymentMethod";
  action?: Maybe<Scalars["String"]["output"]>;
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export enum Category {
  Accommodation = "ACCOMMODATION",
  Additional = "ADDITIONAL",
  CityTax = "CITY_TAX",
  Deposit = "DEPOSIT",
  ExchangeRateDifference = "EXCHANGE_RATE_DIFFERENCE",
  Fee = "FEE",
  Others = "OTHERS",
  Rebate = "REBATE",
  Surcharge = "SURCHARGE",
  TaxCorrection = "TAX_CORRECTION"
}

export enum ChargeMode {
  EndOfReservation = "END_OF_RESERVATION",
  Once = "ONCE",
  PerPerson = "PER_PERSON",
  PerPersonPerTimeUnit = "PER_PERSON_PER_TIME_UNIT",
  StartOfReservation = "START_OF_RESERVATION",
  TimeUnit = "TIME_UNIT"
}

export enum ChargeUnit {
  Booking = "BOOKING",
  Person = "PERSON",
  Reservation = "RESERVATION"
}

export type CleaningDto = {
  __typename?: "CleaningDTO";
  adults?: Maybe<Scalars["Int"]["output"]>;
  arrival?: Maybe<Scalars["Instant"]["output"]>;
  arrivalPmsReservationId?: Maybe<Scalars["String"]["output"]>;
  children?: Maybe<Scalars["Int"]["output"]>;
  condition: UnitState;
  contractualCheckInTime?: Maybe<Scalars["Instant"]["output"]>;
  departure?: Maybe<Scalars["Instant"]["output"]>;
  departurePmsReservationId?: Maybe<Scalars["String"]["output"]>;
  expectedDeparture?: Maybe<Scalars["Instant"]["output"]>;
  floorNumber?: Maybe<Scalars["Int"]["output"]>;
  name: Scalars["String"]["output"];
  occupied: Scalars["Boolean"]["output"];
  pmsUnitGroupIds?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  pmsUnitId: Scalars["String"]["output"];
  priority: Priority;
  servicePmsReservationId?: Maybe<Scalars["String"]["output"]>;
  services?: Maybe<Array<Maybe<BackofficeService>>>;
};

export type Comment = {
  __typename?: "Comment";
  comment: Scalars["String"]["output"];
  pmsId?: Maybe<Scalars["String"]["output"]>;
  pmsType?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  type: CommentTypeEnum;
};

export enum CommentTypeEnum {
  Booker = "BOOKER",
  Booking = "BOOKING",
  Guest = "GUEST",
  Other = "OTHER",
  Reservation = "RESERVATION"
}

export enum CommunicationChannel {
  Email = "EMAIL",
  Sms = "SMS",
  Whatsapp = "WHATSAPP"
}

export enum CommunicationLanguage {
  De = "DE",
  En = "EN",
  Fr = "FR",
  It = "IT"
}

export type DateAndCount = {
  __typename?: "DateAndCount";
  count?: Maybe<Scalars["Int"]["output"]>;
  date: Scalars["LocalDate"]["output"];
};

export enum DayOfWeek {
  Friday = "FRIDAY",
  Monday = "MONDAY",
  Saturday = "SATURDAY",
  Sunday = "SUNDAY",
  Thursday = "THURSDAY",
  Tuesday = "TUESDAY",
  Wednesday = "WEDNESDAY"
}

export enum DebitorType {
  Company = "COMPANY",
  Guest = "GUEST",
  Others = "OTHERS",
  Virtual = "VIRTUAL"
}

export enum DefaultDatePickerSelection {
  All = "ALL",
  None = "NONE"
}

export enum DefaultTargetAudience {
  Adult = "ADULT",
  All = "ALL",
  Child = "CHILD",
  Five = "FIVE",
  Four = "FOUR",
  One = "ONE",
  Three = "THREE",
  Two = "TWO"
}

export type DoorAccessRequestor = {
  __typename?: "DoorAccessRequestor";
  principal?: Maybe<Scalars["String"]["output"]>;
  role?: Maybe<Scalars["String"]["output"]>;
  travelBuddyGuestId?: Maybe<Scalars["String"]["output"]>;
};

export enum DoorAccessState {
  None = "NONE",
  Public = "PUBLIC",
  PublicAndPrivate = "PUBLIC_AND_PRIVATE",
  PublicInhouse = "PUBLIC_INHOUSE"
}

export enum DoorAccessStatus {
  NotOk = "NOT_OK",
  Ok = "OK"
}

export enum DoorLockAccessibility {
  BackofficeOnly = "BACKOFFICE_ONLY",
  Private = "PRIVATE",
  PrivateService = "PRIVATE_SERVICE",
  Public = "PUBLIC",
  PublicInhouse = "PUBLIC_INHOUSE",
  Restricted = "RESTRICTED",
  Service = "SERVICE"
}

export type DropinPayloadDetails = {
  redirectResult?: InputMaybe<Scalars["String"]["input"]>;
};

export type DropinPayloadInput = {
  details?: InputMaybe<DropinPayloadDetails>;
};

export type FlowState = IFlowState & {
  __typename?: "FlowState";
  completed: Scalars["Boolean"]["output"];
  context: Scalars["Json"]["output"];
  fastCheckinAvailable: Scalars["Boolean"]["output"];
  notificationPending: Scalars["Boolean"]["output"];
  notificationSent: Scalars["Boolean"]["output"];
  performedActions: Array<Scalars["String"]["output"]>;
  sentNotifications: Array<Scalars["String"]["output"]>;
  wasOrIsCompleted: Scalars["Boolean"]["output"];
};

export enum FlowStateFilterValue {
  Completed = "COMPLETED",
  NotStarted = "NOT_STARTED",
  Started = "STARTED"
}

export type FlowStateUpdateInput = {
  billConfirmed?: InputMaybe<Scalars["Boolean"]["input"]>;
  bookingOnBehalfOf?: InputMaybe<BookingOnBehalfOf>;
  cleanUnitDialogSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
  context?: InputMaybe<Scalars["Json"]["input"]>;
  dirtyUnitDialogSeen?: InputMaybe<Scalars["Boolean"]["input"]>;
  notificationPending?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Folio = {
  __typename?: "Folio";
  closed?: Maybe<Scalars["Instant"]["output"]>;
  created?: Maybe<Scalars["Instant"]["output"]>;
  debitorType: DebitorType;
  groupedCharges: Array<GroupedCharge>;
  id?: Maybe<Scalars["Int"]["output"]>;
  metadata: Scalars["Json"]["output"];
  number?: Maybe<Scalars["String"]["output"]>;
  orderItems: Array<OrderItem>;
  origin?: Maybe<Origin>;
  payments: Array<Payment>;
  pendingPayments: Array<PendingPayment>;
  pmsCompanyId?: Maybe<Scalars["String"]["output"]>;
  pmsCustomerId?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  prepaymentType: PrepaymentType;
  propertyId?: Maybe<Scalars["Int"]["output"]>;
  totalAllowedPayment?: Maybe<GrossPrice>;
  totalCharges?: Maybe<Price>;
  totalPayments?: Maybe<GrossPrice>;
  type: FolioType;
  updated?: Maybe<Scalars["Instant"]["output"]>;
};

export enum FolioTargetType {
  ExistingFolio = "EXISTING_FOLIO",
  NewFolio = "NEW_FOLIO"
}

export enum FolioType {
  Invoice = "INVOICE",
  Receipt = "RECEIPT"
}

export type FrontendPayload = {
  additionalDetailsDTO?: InputMaybe<AdditionalDetails>;
  dropinPayload?: InputMaybe<Scalars["Json"]["input"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER"
}

export type GenericFilter = {
  filters?: InputMaybe<Scalars["Json"]["input"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum Granularity {
  AnyDay = "ANY_DAY",
  AnyDayExceptArrivalDay = "ANY_DAY_EXCEPT_ARRIVAL_DAY",
  ArrivalDay = "ARRIVAL_DAY",
  DepartureDay = "DEPARTURE_DAY",
  EarlyCheckin = "EARLY_CHECKIN",
  LateCheckout = "LATE_CHECKOUT",
  Never = "NEVER",
  WholeStay = "WHOLE_STAY",
  WholeStayPayOnce = "WHOLE_STAY_PAY_ONCE"
}

export type GrossPrice = {
  __typename?: "GrossPrice";
  currency?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]["output"]>;
};

export type GrossPriceInput = {
  currency: Scalars["String"]["input"];
  grossPriceInCents: Scalars["Int"]["input"];
};

export type GroupedCharge = {
  __typename?: "GroupedCharge";
  hide: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  pmsServiceId?: Maybe<Scalars["String"]["output"]>;
  prepayable: Scalars["Boolean"]["output"];
  subName?: Maybe<Scalars["String"]["output"]>;
  totalPrice: Price;
  totalQuantity: Scalars["Int"]["output"];
  translatedNames?: Maybe<Scalars["Json"]["output"]>;
};

export type GroupedRateBreakdown = {
  __typename?: "GroupedRateBreakdown";
  rateSpliceTypeTuples?: Maybe<Array<RateSpliceTypeEnumTuple>>;
};

export enum GuaranteeType {
  Company = "COMPANY",
  CreditCard = "CREDIT_CARD",
  Pm6Hold = "PM6HOLD",
  Prepayment = "PREPAYMENT"
}

export enum GuaranteeTypeFilterValue {
  Company = "COMPANY",
  CreditCard = "CREDIT_CARD",
  Pm6Hold = "PM6HOLD",
  Prepayment = "PREPAYMENT"
}

export enum GuestFlowCheckPoint {
  Address = "ADDRESS",
  Confirmation = "CONFIRMATION",
  Legal = "LEGAL",
  Overview = "OVERVIEW",
  Payment = "PAYMENT",
  PersonalData = "PERSONAL_DATA",
  PreferredChannel = "PREFERRED_CHANNEL",
  Services = "SERVICES",
  TermsAndConditions = "TERMS_AND_CONDITIONS"
}

export enum GuestJourneyCheckoutFlowCheckpoint {
  Bill = "BILL",
  Confirmation = "CONFIRMATION",
  DepartureTime = "DEPARTURE_TIME",
  Minibar = "MINIBAR",
  Payment = "PAYMENT"
}

export type IFlowState = {
  completed: Scalars["Boolean"]["output"];
  context: Scalars["Json"]["output"];
  fastCheckinAvailable: Scalars["Boolean"]["output"];
  notificationPending: Scalars["Boolean"]["output"];
  notificationSent: Scalars["Boolean"]["output"];
  performedActions: Array<Scalars["String"]["output"]>;
  sentNotifications: Array<Scalars["String"]["output"]>;
  wasOrIsCompleted: Scalars["Boolean"]["output"];
};

export enum IdCheckStatus {
  Confirmed = "CONFIRMED",
  Declined = "DECLINED",
  Reuploaded = "REUPLOADED",
  Unconfirmed = "UNCONFIRMED"
}

export type InitiatePaymentResponse = PaymentAction | Void;

export type MagicFileDescriptor = {
  __typename?: "MagicFileDescriptor";
  contentLength: Scalars["Int"]["output"];
  contentType: Scalars["String"]["output"];
  fileName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  magicFileType: Scalars["String"]["output"];
  ownerId: Scalars["String"]["output"];
};

export type MagicObject = Booking | Reservation | TravelBuddy;

export enum MatchingBehaviour {
  All = "ALL",
  Any = "ANY"
}

export enum MemberShipClassEnum {
  FrequentFlyer = "FREQUENT_FLYER",
  LoyaltyProgram = "LOYALTY_PROGRAM",
  Other = "OTHER"
}

export type MembershipAccount = {
  __typename?: "MembershipAccount";
  membershipClass?: Maybe<MemberShipClassEnum>;
  membershipId?: Maybe<Scalars["String"]["output"]>;
  membershipTypeCode?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
};

export type MembershipAccountInput = {
  membershipClass?: InputMaybe<MemberShipClassEnum>;
  membershipId?: InputMaybe<Scalars["String"]["input"]>;
  membershipTypeCode?: InputMaybe<Scalars["String"]["input"]>;
};

export type MembershipType = {
  __typename?: "MembershipType";
  code: Scalars["String"]["output"];
  membershipClass: MemberShipClassEnum;
  name: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
};

export type MembershipTypeFilter = {
  membershipClass?: InputMaybe<Array<MemberShipClassEnum>>;
};

export type MewsPaymentMethod = {
  __typename?: "MewsPaymentMethod";
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type MoveOrderItemsInput = {
  folioTargetType: FolioTargetType;
  pmsOrderItemIds: Array<Scalars["String"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
  sourcePmsFolioId: Scalars["String"]["input"];
  sourcePmsReservationId: Scalars["String"]["input"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  targetPmsFolioId?: InputMaybe<Scalars["String"]["input"]>;
  targetPmsReservationId: Scalars["String"]["input"];
};

export type MovePaymentInput = {
  folioTargetType: FolioTargetType;
  pmsPaymentIds: Array<Scalars["String"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
  sourcePmsFolioId: Scalars["String"]["input"];
  sourcePmsReservationId: Scalars["String"]["input"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  targetPmsFolioId?: InputMaybe<Scalars["String"]["input"]>;
  targetPmsReservationId: Scalars["String"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Adds membership to the primary guest. */
  AddMembershipToPrimaryGuest?: Maybe<Scalars["Boolean"]["output"]>;
  AddNoteToProfile: ProfileNote;
  AddPreferenceToProfile: UserProfile;
  AddServicesToReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  AddToBlacklist: Scalars["Boolean"]["output"];
  AddUpsellToReservation?: Maybe<Scalars["Boolean"]["output"]>;
  /** Open any door using admin rights. */
  AdminOpenDoor?: Maybe<Scalars["Boolean"]["output"]>;
  /** Post an OrderItem from the list GetOrderItemTransactionTypes on a specific folio or folioWindow of a reservation */
  BackofficeAddArbitraryOrderItems?: Maybe<Scalars["Boolean"]["output"]>;
  /** Adds a new membership to a guest profile in a reservation, and assigns that membership to the reservation. */
  BackofficeAddMembershipToProfile?: Maybe<Scalars["Boolean"]["output"]>;
  /** Adds new services to the reservation from backoffice context. */
  BackofficeAddServicesToReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  /** Post Adjustment charge for order item */
  BackofficeAdjustOrderItem?: Maybe<Array<Maybe<OrderItem>>>;
  /** Sends a Canceled request for a specific second screen with the pmsReservationId */
  BackofficeCancelProcessOnSecondScreen?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Update the flow state context manual checkin checkpoint for a reservation from backoffice context.
   * Used during Checkin wizard to skip sign via tablet or payment steps. Skip to services step would mean to set the
   * checkpoint to SERVICES.
   */
  BackofficeManualCheckinSkipToStep?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Sends SecondScreenEvents: VerifyBill, VerifyAndSign, Canceled to a specific second screen device
   * using the pmsReservationId
   */
  BackofficePerformSecondScreenAction?: Maybe<Scalars["Boolean"]["output"]>;
  /** Sends a sign and verify request for a specific reservation to a second screen device identified by the deviceId. */
  BackofficeSignViaTablet?: Maybe<Scalars["Boolean"]["output"]>;
  /** Updates the primary guest of a reservation identified by the pmsReservationId, from backoffice context. */
  BackofficeUpdatePrimaryGuest: Person;
  /** Updates the flow state for a reservation from backoffice context. */
  BackofficeUpdateReservationFlowState?: Maybe<ReservationFlowState>;
  /** Updates the secondary guests of a reservation identified by the pmsReservationId, from backoffice context. */
  BackofficeUpdateSecondaryGuestDetails: Array<Person>;
  /** Upsell reservations, using specific upsell rule and to specific unit group. */
  BackofficeUpsellReservation?: Maybe<Scalars["Boolean"]["output"]>;
  BulkUpdateUnitState?: Maybe<Scalars["Boolean"]["output"]>;
  Cancel?: Maybe<Reservation>;
  /**
   * Cancel an ongoing terminal payment using the transaction id and service id retrieved upon initiation of the
   * terminal payment.
   */
  CancelTerminalPayment?: Maybe<Scalars["Boolean"]["output"]>;
  CardOnFilePayment?: Maybe<Scalars["Boolean"]["output"]>;
  CashPayment?: Maybe<Scalars["String"]["output"]>;
  Checkin?: Maybe<Reservation>;
  Checkout?: Maybe<Reservation>;
  CreateBooking: Booking;
  CreatePaymentLink?: Maybe<PaymentLinkResponse>;
  /**  CreatePaymentIntent(paymentIntentRequest: PaymentIntentRequest!): PaymentIntentResponse */
  CreatePaymentRequest: PaymentRequestResponse;
  CreatePaymentSession: PaymentSessionResponse;
  CreateTemplatedTask?: Maybe<Scalars["Boolean"]["output"]>;
  DeleteNote: Scalars["Boolean"]["output"];
  /**
   *  Deletes the given user account and removes it from bookings, reservations, and user profile
   *  if userAccountUuid is not specified -> deletes the logged-in user account
   *  only admin and operators can delete userAccountUuid of other users
   */
  DeleteUserAccount: Scalars["Boolean"]["output"];
  EncodeKey?: Maybe<MagicObject>;
  ExpirePaymentLink?: Maybe<PaymentLinkResponse>;
  InitiatePayment?: Maybe<InitiatePaymentResponse>;
  LogDoorAccess?: Maybe<MagicObject>;
  /** Move order items from one folio to another. */
  MoveOrderItems?: Maybe<Scalars["Boolean"]["output"]>;
  /** Move payments from one folio to another. */
  MovePayments?: Maybe<Scalars["Boolean"]["output"]>;
  OpenDoor?: Maybe<MagicObject>;
  PayAndCreateBooking: PayAndCreateBookingResponse;
  PayDetailsAndCreateBooking: Booking;
  PaymentDetails?: Maybe<Void>;
  /**
   * Performs a specific reservation action on a reservation identified by the pmsReservationId, with the given payload.
   * The action must be in the allowed list for the reservation for it to work.
   */
  PerformAction?: Maybe<Scalars["Boolean"]["output"]>;
  /**  "Post a payment for a magicId. Only positive values are supported." */
  PostPayment?: Maybe<Array<Maybe<PerformedPaymentResponse>>>;
  RefundPayment?: Maybe<Void>;
  RemoveFromBlacklist: Scalars["Boolean"]["output"];
  RemovePreferenceFromProfile: UserProfile;
  /**  Note: not supported by mews-connector */
  RemoveServicesFromReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  RequestAccess?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * This mutation is used to trigger an action from the second screen device.
   * eventType can be any event implementing SecondScreenEventType. The payload type can specific to the event type.
   */
  SecondScreenAction?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   *  If logged-in user has no account, it creates one with the given password. Otherwise it updates the password
   *  then it protects all bookings and reservations where the user is primary guest or booker
   *  can be called with magicId of a booking (from the booker) or reservation (from the primary guest)
   */
  SetPassword: UserProfile;
  /** Updates the preferred cleaning days of a primary guest DB column and ensures services are up to date. */
  SetPreferredCleaningDays: Person;
  /** Settle a specific folio or folioWindow */
  SettleFolio?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * Synchronizes the door access for specific reservation identified by the pmsReservationId, for the level of access
   * defined by the status field.
   */
  SyncDoorAccess?: Maybe<Scalars["Boolean"]["output"]>;
  SyncPaymentRequest: PaymentRequestResponse;
  /**
   *  Unlinks the given idp
   *  if userAccountUuid is not specified -> unlink from the logged-in user account
   *  only admin and operators can unlink idps of other users
   */
  UnlinkIdp: Scalars["Boolean"]["output"];
  /**
   * Updates the personal data of the booker;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateBookerDetails: Person;
  UpdateEstimatedDepartureTime?: Maybe<Scalars["Boolean"]["output"]>;
  UpdateFlowState: ReservationFlowState;
  /**
   * Updates the personal data of the main actor from a specific context (reservation, travel buddy, booking).
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateMainActor: Person;
  UpdateNote: ProfileNote;
  UpdatePaymentSession?: Maybe<UpdatePaymentSessionResponse>;
  /**
   * Updates the preferred communication channel of a primary guest/secondary guest;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdatePreferredCommunicationChannel: Person;
  UpdateReservationTermsAndConditions: Reservation;
  /**
   * Updates the personal data of the secondary guests;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateSecondaryGuestDetails: Array<Person>;
  UpdateUnitState: Unit;
};

export type MutationAddMembershipToPrimaryGuestArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  membershipAccount: MembershipAccountInput;
};

export type MutationAddNoteToProfileArgs = {
  note: NoteInput;
  userProfileId: Scalars["Int"]["input"];
};

export type MutationAddPreferenceToProfileArgs = {
  pmsPreferenceId: Scalars["String"]["input"];
  userProfileId: Scalars["Int"]["input"];
};

export type MutationAddServicesToReservationArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  services: Array<ServiceOrderRequest>;
};

export type MutationAddToBlacklistArgs = {
  userProfileId: Scalars["Int"]["input"];
};

export type MutationAddUpsellToReservationArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
  pmsUpsellRuleId: Scalars["String"]["input"];
};

export type MutationAdminOpenDoorArgs = {
  doorId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
};

export type MutationBackofficeAddArbitraryOrderItemsArgs = {
  addArbitraryOrderItemsInput: Array<AddArbitraryOrderItemInput>;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeAddMembershipToProfileArgs = {
  membershipAccount: MembershipAccountInput;
  pmsProfileId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeAddServicesToReservationArgs = {
  pmsReservationId: Scalars["String"]["input"];
  services: Array<ServiceOrderRequest>;
};

export type MutationBackofficeAdjustOrderItemArgs = {
  adjustOrderItemInput?: InputMaybe<AdjustOrderItemInput>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type MutationBackofficeCancelProcessOnSecondScreenArgs = {
  deviceId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeManualCheckinSkipToStepArgs = {
  checkpoint: BackofficeManualCheckInCheckpoint;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficePerformSecondScreenActionArgs = {
  deviceId: Scalars["String"]["input"];
  eventType: SecondScreenEventType;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeSignViaTabletArgs = {
  deviceId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeUpdatePrimaryGuestArgs = {
  person: UpdatePersonInput;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeUpdateReservationFlowStateArgs = {
  flowState: FlowStateUpdateInput;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeUpdateSecondaryGuestDetailsArgs = {
  persons: Array<SecondaryPersonInput>;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationBackofficeUpsellReservationArgs = {
  pmsReservationId: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
  pmsUpsellRuleId: Scalars["String"]["input"];
};

export type MutationBulkUpdateUnitStateArgs = {
  pmsUnitIds: Array<Scalars["String"]["input"]>;
  state: UnitState;
};

export type MutationCancelArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationCancelTerminalPaymentArgs = {
  cancelTerminalPaymentRequest: CancelTerminalPaymentRequest;
};

export type MutationCardOnFilePaymentArgs = {
  cardOnFilePaymentRequest: CardOnFilePaymentRequest;
};

export type MutationCashPaymentArgs = {
  cashPaymentRequest?: InputMaybe<PaymentRequest>;
  magicId: Scalars["String"]["input"];
};

export type MutationCheckinArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationCheckoutArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationCreateBookingArgs = {
  booking: PotentialBooking;
};

export type MutationCreatePaymentLinkArgs = {
  paymentLinkRequest?: InputMaybe<PaymentLinkRequest>;
};

export type MutationCreatePaymentRequestArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  paymentRequest: PaymentRequestInput;
};

export type MutationCreatePaymentSessionArgs = {
  paymentSessionRequest: PaymentSessionRequest;
};

export type MutationCreateTemplatedTaskArgs = {
  due?: InputMaybe<Scalars["Instant"]["input"]>;
  guestNote?: InputMaybe<Scalars["String"]["input"]>;
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  taskTemplate: TaskTemplate;
};

export type MutationDeleteNoteArgs = {
  pmsProfileNoteId: Scalars["String"]["input"];
};

export type MutationDeleteUserAccountArgs = {
  userAccountUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationEncodeKeyArgs = {
  encoderId: Scalars["String"]["input"];
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationExpirePaymentLinkArgs = {
  paymentLinkId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
};

export type MutationInitiatePaymentArgs = {
  magicId: Scalars["String"]["input"];
  payment: WidgetPaymentRequest;
};

export type MutationLogDoorAccessArgs = {
  doorAccessStatus: DoorAccessStatus;
  doorId: Scalars["String"]["input"];
  doorLog?: InputMaybe<Scalars["String"]["input"]>;
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationMoveOrderItemsArgs = {
  moveOrderItemsInput: MoveOrderItemsInput;
};

export type MutationMovePaymentsArgs = {
  movePaymentsInput: MovePaymentInput;
};

export type MutationOpenDoorArgs = {
  doorId: Scalars["String"]["input"];
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationPayAndCreateBookingArgs = {
  booking: PotentialBooking;
  payment: WidgetPaymentRequest;
  pmsPropertyId: Scalars["String"]["input"];
};

export type MutationPayDetailsAndCreateBookingArgs = {
  booking: PotentialBooking;
  payment: WidgetPaymentDetailsRequest;
  pmsPropertyId: Scalars["String"]["input"];
};

export type MutationPaymentDetailsArgs = {
  magicId: Scalars["String"]["input"];
  paymentDetails: WidgetPaymentDetailsRequest;
};

export type MutationPerformActionArgs = {
  payload?: InputMaybe<Scalars["Json"]["input"]>;
  pmsReservationId: Scalars["String"]["input"];
  reservationAction: ReservationAction;
};

export type MutationPostPaymentArgs = {
  performedPayment: PerformedPaymentInput;
};

export type MutationRefundPaymentArgs = {
  refundPaymentRequest?: InputMaybe<RefundPaymentRequest>;
};

export type MutationRemoveFromBlacklistArgs = {
  userProfileId: Scalars["Int"]["input"];
};

export type MutationRemovePreferenceFromProfileArgs = {
  pmsPreferenceId: Scalars["String"]["input"];
  userProfileId: Scalars["Int"]["input"];
};

export type MutationRemoveServicesFromReservationArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  serviceOrderIds: Array<Scalars["String"]["input"]>;
};

export type MutationRequestAccessArgs = {
  magicId: Scalars["String"]["input"];
};

export type MutationSecondScreenActionArgs = {
  deviceId: Scalars["String"]["input"];
  eventType: SecondScreenEventType;
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  payload?: InputMaybe<Scalars["Json"]["input"]>;
};

export type MutationSetPasswordArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationSetPreferredCleaningDaysArgs = {
  days: Array<DayOfWeek>;
  pmsReservationId: Scalars["String"]["input"];
};

export type MutationSettleFolioArgs = {
  settleFolioInput: SettleFolioInput;
};

export type MutationSyncDoorAccessArgs = {
  pmsReservationId: Scalars["String"]["input"];
  status: DoorAccessState;
};

export type MutationSyncPaymentRequestArgs = {
  magicId: Scalars["String"]["input"];
};

export type MutationUnlinkIdpArgs = {
  idp: Scalars["String"]["input"];
  userAccountUuid?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateBookerDetailsArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  person: PersonInput;
};

export type MutationUpdateEstimatedDepartureTimeArgs = {
  estimatedDepartureTime: Scalars["Instant"]["input"];
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationUpdateFlowStateArgs = {
  flowState: FlowStateUpdateInput;
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationUpdateMainActorArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  person: UpdatePersonInput;
};

export type MutationUpdateNoteArgs = {
  note: NoteInput;
  pmsProfileNoteId: Scalars["String"]["input"];
};

export type MutationUpdatePaymentSessionArgs = {
  correlationId: Scalars["String"]["input"];
  paymentSessionRequest: PaymentSessionRequest;
  sessionId: Scalars["String"]["input"];
};

export type MutationUpdatePreferredCommunicationChannelArgs = {
  channel?: InputMaybe<CommunicationChannel>;
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type MutationUpdateReservationTermsAndConditionsArgs = {
  magicToken: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
  tc: ReservationTermsAndConditionsInput;
};

export type MutationUpdateSecondaryGuestDetailsArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  persons: Array<SecondaryPersonInput>;
};

export type MutationUpdateUnitStateArgs = {
  pmsUnitId: Scalars["String"]["input"];
  state: UnitState;
};

export type NoteInput = {
  pmsProfileNoteCategoryId?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

/**  ch.aaap.magic.common.notification.guest.journey.NotificationTemplate */
export enum NotificationType {
  AccountActions = "ACCOUNT_ACTIONS",
  AccountEmailTest = "ACCOUNT_EMAIL_TEST",
  AccountEmailVerification = "ACCOUNT_EMAIL_VERIFICATION",
  AccountResetPassword = "ACCOUNT_RESET_PASSWORD",
  BookerFlowOverview = "BOOKER_FLOW_OVERVIEW",
  BookerMagicOnboarding = "BOOKER_MAGIC_ONBOARDING",
  BookingCreated = "BOOKING_CREATED",
  BookingReminder = "BOOKING_REMINDER",
  BuddyFlowOverview = "BUDDY_FLOW_OVERVIEW",
  CheckedIn = "CHECKED_IN",
  Checkin = "CHECKIN",
  CheckinIntroduction = "CHECKIN_INTRODUCTION",
  CheckinReminder = "CHECKIN_REMINDER",
  CheckoutAlert = "CHECKOUT_ALERT",
  CheckoutFollowUp = "CHECKOUT_FOLLOW_UP",
  CheckoutIntroduction = "CHECKOUT_INTRODUCTION",
  CheckoutReminder = "CHECKOUT_REMINDER",
  CompanionAdded = "COMPANION_ADDED",
  GuestFlowOverview = "GUEST_FLOW_OVERVIEW",
  GuestMagicOnboarding = "GUEST_MAGIC_ONBOARDING",
  Invoice = "INVOICE",
  InvoiceBilling = "INVOICE_BILLING",
  MarketingConsent = "MARKETING_CONSENT",
  NewsLetter_1 = "NEWS_LETTER_1",
  NewsLetter_2 = "NEWS_LETTER_2",
  NewsLetter_3 = "NEWS_LETTER_3",
  PayByLink = "PAY_BY_LINK",
  PreCheckinReminder = "PRE_CHECKIN_REMINDER",
  ResendGuestflowLink = "RESEND_GUESTFLOW_LINK",
  ReservationCanceled = "RESERVATION_CANCELED",
  ReservationCreated = "RESERVATION_CREATED"
}

export type OptionalPersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
};

export type OrderItem = {
  __typename?: "OrderItem";
  accountingState: AccountingState;
  category: Category;
  created?: Maybe<Scalars["Instant"]["output"]>;
  displayableReservationId?: Maybe<Scalars["String"]["output"]>;
  end?: Maybe<Scalars["Instant"]["output"]>;
  hide?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["Int"]["output"];
  included?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  note?: Maybe<Scalars["String"]["output"]>;
  pmsAccountId?: Maybe<Scalars["String"]["output"]>;
  pmsFolioId?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  pmsReservationId?: Maybe<Scalars["String"]["output"]>;
  pmsServiceId?: Maybe<Scalars["String"]["output"]>;
  prepaid?: Maybe<Scalars["Boolean"]["output"]>;
  price: Price;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  reference?: Maybe<Scalars["String"]["output"]>;
  start?: Maybe<Scalars["Instant"]["output"]>;
  subName?: Maybe<Scalars["String"]["output"]>;
  translatedNames?: Maybe<Scalars["Json"]["output"]>;
};

export type OrderItemAdjustmentReasonCode = {
  __typename?: "OrderItemAdjustmentReasonCode";
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
};

export type OrderItemTransactionType = {
  __typename?: "OrderItemTransactionType";
  defaultPrice?: Maybe<GrossPrice>;
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  transactionTypeCode?: Maybe<Scalars["String"]["output"]>;
};

export type OrderItemsPage = {
  __typename?: "OrderItemsPage";
  content: Array<OrderItem>;
  totalElements: Scalars["Int"]["output"];
};

export type OrderItemsPageFilter = {
  filters?: InputMaybe<Scalars["Json"]["input"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  userProfileId?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum Origin {
  Apaleo = "APALEO",
  Likemagic = "LIKEMAGIC",
  Mews = "MEWS",
  Ohip = "OHIP",
  ProtelCloud = "PROTEL_CLOUD",
  ProtelOnprem = "PROTEL_ONPREM",
  Stayntouch = "STAYNTOUCH"
}

export enum Page {
  Homepage = "HOMEPAGE"
}

export type Pageable = {
  pageNumber: Scalars["Int"]["input"];
  pageSize: Scalars["Int"]["input"];
  sort?: InputMaybe<Scalars["String"]["input"]>;
};

export type PayAndCreateBookingResponse = Booking | PaymentAction;

export type PayAtCheckinPaymentMethod = {
  __typename?: "PayAtCheckinPaymentMethod";
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type PayAtCheckoutPaymentMethod = {
  __typename?: "PayAtCheckoutPaymentMethod";
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type Payment = {
  __typename?: "Payment";
  created?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["Int"]["output"];
  pmsAccountId?: Maybe<Scalars["String"]["output"]>;
  pmsFolioId?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  price: Price;
  refundStatus?: Maybe<RefundStatus>;
  refundable: Scalars["Boolean"]["output"];
  state: PaymentState;
  type: PaymentType;
  updated?: Maybe<Scalars["Instant"]["output"]>;
};

export type PaymentAction = {
  __typename?: "PaymentAction";
  checkoutPaymentsAction?: Maybe<Scalars["Json"]["output"]>;
  correlationId?: Maybe<Scalars["String"]["output"]>;
  transitoryPaymentDTO?: Maybe<TransitoryPayment>;
};

export type PaymentDataInput = {
  paymentMethod?: InputMaybe<PaymentMethodInput>;
};

export type PaymentDetailsRequest = {
  dropinPayload?: InputMaybe<DropinPayloadInput>;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
};

export type PaymentIntentRequest = {
  grossPrice: GrossPriceInput;
};

export type PaymentIntentResponse = {
  __typename?: "PaymentIntentResponse";
  clientSecret?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentLinkPaymentMethod = {
  __typename?: "PaymentLinkPaymentMethod";
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type PaymentLinkRequest = {
  amount: Amount;
  email: Scalars["String"]["input"];
  expiresAt?: InputMaybe<Scalars["Instant"]["input"]>;
  magicId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
  shopperReference?: InputMaybe<Scalars["String"]["input"]>;
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
};

export type PaymentLinkResponse = {
  __typename?: "PaymentLinkResponse";
  expiresAt?: Maybe<Scalars["Instant"]["output"]>;
  paymentLinkId: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type PaymentMethod =
  | CardPaymentMethod
  | CashPaymentMethod
  | MewsPaymentMethod
  | PayAtCheckinPaymentMethod
  | PayAtCheckoutPaymentMethod
  | PaymentLinkPaymentMethod
  | PreAuthorizationPaymentMethod
  | StoredPaymentMethod
  | TerminalPaymentMethod;

export type PaymentMethodInput = {
  encryptedCard?: InputMaybe<Scalars["String"]["input"]>;
  encryptedExpiryMonth?: InputMaybe<Scalars["String"]["input"]>;
  encryptedExpiryYear?: InputMaybe<Scalars["String"]["input"]>;
  encryptedSecurityCode?: InputMaybe<Scalars["String"]["input"]>;
  holderName?: InputMaybe<Scalars["String"]["input"]>;
  riskData?: InputMaybe<Scalars["String"]["input"]>;
  tokenizedCard?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export enum PaymentMethodType {
  AdyenWidget = "ADYEN_WIDGET",
  Cash = "CASH",
  DatatransWidget = "DATATRANS_WIDGET",
  MewsPayment = "MEWS_PAYMENT",
  PaymentLink = "PAYMENT_LINK",
  PayAtCheckin = "PAY_AT_CHECKIN",
  PayAtCheckout = "PAY_AT_CHECKOUT",
  PreAuthorization = "PRE_AUTHORIZATION",
  StoredPaymentMethod = "STORED_PAYMENT_METHOD",
  TerminalPayment = "TERMINAL_PAYMENT"
}

export type PaymentMethodsResponse = {
  __typename?: "PaymentMethodsResponse";
  groups?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  oneClickPaymentMethods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  paymentMethods?: Maybe<Array<Maybe<PossiblePaymentMethod>>>;
  storedPaymentMethods?: Maybe<Array<Maybe<StoredPaymentMethod>>>;
  storingMethods?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentRequest = {
  amount?: InputMaybe<GrossPriceInput>;
  frontendPayload?: InputMaybe<FrontendPayload>;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
};

export type PaymentRequestInput = {
  grossPrice: GrossPriceInput;
  services: Array<ServiceOrderRequest>;
};

export type PaymentRequestResponse = {
  __typename?: "PaymentRequestResponse";
  paymentLink: Scalars["String"]["output"];
  /**  todo remove this, probably not needed by FE */
  pmsId: Scalars["String"]["output"];
  state: Scalars["String"]["output"];
};

export type PaymentSessionRequest = {
  amount?: InputMaybe<Amount>;
  booking?: InputMaybe<PotentialBooking>;
  locale?: InputMaybe<Scalars["String"]["input"]>;
  magicId?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  returnUrl?: InputMaybe<Scalars["String"]["input"]>;
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type PaymentSessionResponse = {
  __typename?: "PaymentSessionResponse";
  transactionId?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentState {
  Canceled = "CANCELED",
  Charged = "CHARGED",
  Failed = "FAILED",
  Pending = "PENDING",
  Verifing = "VERIFING"
}

export enum PaymentType {
  Cash = "CASH",
  CreditCard = "CREDIT_CARD",
  Invoice = "INVOICE",
  Others = "OTHERS",
  Virtual = "VIRTUAL"
}

export type PendingPayment = {
  __typename?: "PendingPayment";
  created?: Maybe<Scalars["Instant"]["output"]>;
  id: Scalars["String"]["output"];
  pmsFolioId?: Maybe<Scalars["String"]["output"]>;
  price: Price;
};

export type PerformedPaymentInput = {
  grossPrice: GrossPriceInput;
  magicId: Scalars["String"]["input"];
  paymentId: Scalars["String"]["input"];
};

export type PerformedPaymentResponse = {
  __typename?: "PerformedPaymentResponse";
  pmsPaymentId?: Maybe<Scalars["String"]["output"]>;
};

export type Person = {
  __typename?: "Person";
  address?: Maybe<Address>;
  age?: Maybe<Scalars["Int"]["output"]>;
  birthdate?: Maybe<Scalars["LocalDate"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  emergencyEvacuationHelpNeeded: Scalars["Boolean"]["output"];
  emergencyEvacuationHelpNotes?: Maybe<Scalars["String"]["output"]>;
  enrolledInLoyaltyProgram: Scalars["Boolean"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  id: Scalars["Int"]["output"];
  identificationDocumentNumber?: Maybe<Scalars["String"]["output"]>;
  identificationDocumentType?: Maybe<Scalars["String"]["output"]>;
  isMinor?: Maybe<Scalars["Boolean"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  membershipAccounts: Array<MembershipAccount>;
  nationality?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]["output"]>;
  publicTransportationTickets?: Maybe<Array<Maybe<PublicTransportationTicket>>>;
  termsAndConditionsGeneral: Scalars["Boolean"]["output"];
  termsAndConditionsMarketingConsent: Scalars["Boolean"]["output"];
  termsAndConditionsMarketingConsentDoubleOptin: Scalars["Boolean"]["output"];
  termsAndConditionsOnline: Scalars["Boolean"]["output"];
  /** userProfile is only available to ADMIN, SERVICE_ACCOUNT, OPERATOR, HOST, HOUSE_KEEPER */
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars["Int"]["output"]>;
  vipCode?: Maybe<Scalars["String"]["output"]>;
};

export type PersonAddressInput = {
  addressLine1?: InputMaybe<Scalars["String"]["input"]>;
  addressLine2?: InputMaybe<Scalars["String"]["input"]>;
  city?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  postalCode?: InputMaybe<Scalars["String"]["input"]>;
  region?: InputMaybe<Scalars["String"]["input"]>;
};

export type PersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  identificationDocumentNumber?: InputMaybe<Scalars["String"]["input"]>;
  identificationDocumentType?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
};

export type PmsIdRateSplicesTuple = {
  __typename?: "PmsIdRateSplicesTuple";
  pmsId?: Maybe<Scalars["String"]["output"]>;
  rateSplices?: Maybe<Array<BackofficeRateSplice>>;
};

export type PmsIdToRateSplicesTuple = {
  __typename?: "PmsIdToRateSplicesTuple";
  pmsId?: Maybe<Scalars["String"]["output"]>;
  rateSplices?: Maybe<Array<RateSplice>>;
};

export type PossiblePaymentMethod = {
  __typename?: "PossiblePaymentMethod";
  brand?: Maybe<Scalars["String"]["output"]>;
  brands?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  configuration?: Maybe<Scalars["Json"]["output"]>;
  fundingSource?: Maybe<Scalars["String"]["output"]>;
  group?: Maybe<Scalars["Json"]["output"]>;
  inputDetails?: Maybe<Scalars["String"]["output"]>;
  issuers?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type PotentialBooking = {
  booker?: InputMaybe<OptionalPersonInput>;
  reservations: Array<InputMaybe<PotentialReservation>>;
};

export type PotentialReservation = {
  adultsAmount: Scalars["Int"]["input"];
  arrival: Scalars["Instant"]["input"];
  childrenAges?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  childrenAmount?: InputMaybe<Scalars["Int"]["input"]>;
  departure: Scalars["Instant"]["input"];
  pmsCode?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
  pmsRatePlanId: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
};

export type PreAuthorizationPaymentMethod = {
  __typename?: "PreAuthorizationPaymentMethod";
  availableTerminals?: Maybe<Array<Maybe<AvailableTerminal>>>;
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type Preference = {
  __typename?: "Preference";
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
  pmsPreferenceCategoryId: Scalars["String"]["output"];
  /** A null pmsPropertyId means the preference is a global one */
  pmsPropertyId?: Maybe<Scalars["String"]["output"]>;
};

export type PreferenceCategory = {
  __typename?: "PreferenceCategory";
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
  preferences: Array<Preference>;
};

export type PreferenceCategoryPreferencesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum PrepaymentType {
  AgodaVcc = "AGODA_VCC",
  Airbnb = "AIRBNB",
  BookingDotCom = "BOOKING_DOT_COM",
  BookingDotComBankTransferPrepayment = "BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT",
  BookingDotComVccCreditCard = "BOOKING_DOT_COM_VCC_CREDIT_CARD",
  BookingDotComVccPrepayment = "BOOKING_DOT_COM_VCC_PREPAYMENT",
  CheckoutOnAr = "CHECKOUT_ON_AR",
  CheckoutOnArCustomer = "CHECKOUT_ON_AR_CUSTOMER",
  Ctrip = "CTRIP",
  Expedia = "EXPEDIA",
  GenericOta = "GENERIC_OTA",
  Hotelbeds = "HOTELBEDS",
  None = "NONE",
  NotDeterminable = "NOT_DETERMINABLE",
  Prepaid = "PREPAID",
  RealCreditCard = "REAL_CREDIT_CARD"
}

export type Price = {
  __typename?: "Price";
  currency?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]["output"]>;
  netPrice?: Maybe<Scalars["BigDecimal"]["output"]>;
  netPriceInCents?: Maybe<Scalars["Int"]["output"]>;
  taxes?: Maybe<Array<Maybe<Tax>>>;
};

export enum PriceAdjustmentType {
  FlatAmount = "FLAT_AMOUNT",
  Percentage = "PERCENTAGE"
}

export type PrimaryGuestFilter = {
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM"
}

export type ProfileNote = {
  __typename?: "ProfileNote";
  category?: Maybe<ProfileNoteCategory>;
  pmsId: Scalars["String"]["output"];
  pmsProfileNoteCategoryId?: Maybe<Scalars["String"]["output"]>;
  pmsPropertyId?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ProfileNoteCategory = {
  __typename?: "ProfileNoteCategory";
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId: Scalars["String"]["output"];
};

export type PropertiesByCityAggregate = {
  __typename?: "PropertiesByCityAggregate";
  grouped?: Maybe<Array<Maybe<PropertyGroup>>>;
};

export type Property = {
  __typename?: "Property";
  address?: Maybe<Address>;
  availableDoors?: Maybe<Array<Maybe<AvailableDoorDto>>>;
  defaultCurrency?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Origin>;
  pmsId: Scalars["String"]["output"];
  supportedSyncStates?: Maybe<Array<Maybe<DoorAccessState>>>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  toBeCleanedToday?: Maybe<Array<Maybe<CleaningDto>>>;
};

export type PropertyAvailabilityFilter = {
  adultsAmount: Scalars["Int"]["input"];
  arrival: Scalars["LocalDate"]["input"];
  childrenAges?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  childrenAmount?: InputMaybe<Scalars["Int"]["input"]>;
  departure: Scalars["LocalDate"]["input"];
  pmsCode?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type PropertyFilter = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
};

export type PropertyGroup = {
  __typename?: "PropertyGroup";
  key?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Array<Maybe<Property>>>;
};

export type PublicTransportationTicket = {
  __typename?: "PublicTransportationTicket";
  code?: Maybe<Scalars["String"]["output"]>;
  link?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  ownerMagicId?: Maybe<Scalars["String"]["output"]>;
  valid?: Maybe<Scalars["Boolean"]["output"]>;
  validUntil?: Maybe<Scalars["Instant"]["output"]>;
};

export type Query = {
  __typename?: "Query";
  AvailableTemplatesForSendCustomNotification: Array<NotificationType>;
  /**
   * Returns the available additional services for a specific reservation identified by the pmsReservationId.
   * Returns an empty list if no additional services are available.
   */
  BackofficeGetAdditionalServicesAvailability?: Maybe<
    Array<Maybe<AdditionalServiceAvailabilityDto>>
  >;
  BackofficeGetUpsellOffers: Array<ReservationUpsellOffer>;
  /** Returns the counts of the reservation conditions (Minor, Critical, Ok) based on the given filter. */
  ConditionCounts?: Maybe<Scalars["Json"]["output"]>;
  /**
   *  not used & no plan to be used by FE => removing it for now
   *  TODO completely remove if confirmed not needed
   *  GetServices(pmsPropertyId: String!, pmsReservationId: String): [Service]
   */
  GetAdditionalServicesAvailability?: Maybe<Array<Maybe<AdditionalServiceAvailabilityDto>>>;
  GetAllBookings?: Maybe<Array<Maybe<Booking>>>;
  /** Returns a filtered list of properties based on the users keycloak rights  */
  GetAllProperties?: Maybe<Array<Maybe<Property>>>;
  GetAllReservations?: Maybe<Array<Maybe<Reservation>>>;
  GetAssignableUnits: Array<Unit>;
  GetAvailableProperties: Array<UnitGroupAvailability>;
  GetAvailableUnitGroups: Array<UnitGroupAvailability>;
  GetBooking?: Maybe<Booking>;
  GetBookingOverviews: Array<BookingOverview>;
  GetGuests?: Maybe<Array<Maybe<Person>>>;
  GetLatestMagicObject?: Maybe<MagicObject>;
  GetMagicBookingOverviews: Array<BookingOverview>;
  GetMagicObject?: Maybe<MagicObject>;
  /** Get membership types for a sepcific property e.g frequent flyer, loyalty program */
  GetMembershipTypes: Array<MembershipType>;
  GetOrderItemAdjustmentReasonCodes: Array<OrderItemAdjustmentReasonCode>;
  GetOrderItemTransactionTypes: Array<OrderItemTransactionType>;
  GetOrderItemsPage: OrderItemsPage;
  GetPaymentMethods: Array<PaymentMethod>;
  GetPaymentMethodsMagicId: Array<PaymentMethod>;
  GetPotentialBookingPrice?: Maybe<BookingPrice>;
  GetPotentialReservationPrice?: Maybe<Price>;
  /** Get relevant preferences by property. If pmsPropertyIds is null -> return all of them */
  GetPreferences: Array<PreferenceCategory>;
  /** Get relevant preferences by property. If pmsPropertyIds is null -> return all of them */
  GetProfileNoteCategories: Array<ProfileNoteCategory>;
  GetProperties?: Maybe<Array<Maybe<Property>>>;
  /**
   *  returns properties grouped by city.
   *  Filters by pms_properties token attribute, when present (BO request)
   */
  GetPropertiesByCity?: Maybe<PropertiesByCityAggregate>;
  GetProperty?: Maybe<Property>;
  GetReservation?: Maybe<Reservation>;
  GetReservationsPage: ReservationsPage;
  GetTotalUnpaidAmount?: Maybe<GrossPrice>;
  GetUnitGroups?: Maybe<Array<Maybe<UnitGroup>>>;
  GetUnits?: Maybe<Array<Maybe<Unit>>>;
  GetUpsellOffers?: Maybe<Array<Maybe<ReservationUpsellOffer>>>;
  /** Profile of the caller */
  GetUserProfile?: Maybe<UserProfile>;
  /** Gets UserProfile by userProfileId or null if not found */
  GetUserProfileById?: Maybe<UserProfile>;
  /** List of user profiles */
  GetUserProfiles: UserProfilesPage;
  /** Returns the details of a specific reservation identified by the pmsReservationId. */
  OverviewDetails?: Maybe<BackofficeReservationOverviewDetail>;
  /** Returns a list of reservations based on the given filter. The result is paginated. */
  OverviewTable?: Maybe<BackofficeReservationOverviewTablePage>;
  SearchReservations: Array<ReservationSearchResult>;
  ValidatePromoCodeMagic: ValidatePromoCodeMagicResponse;
  ValidatePromoCodePms: Scalars["Boolean"]["output"];
};

export type QueryAvailableTemplatesForSendCustomNotificationArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type QueryBackofficeGetAdditionalServicesAvailabilityArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type QueryBackofficeGetUpsellOffersArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type QueryConditionCountsArgs = {
  filter: BackofficeConditionCountsFilter;
};

export type QueryGetAdditionalServicesAvailabilityArgs = {
  magicId: Scalars["String"]["input"];
  shopTypesIncludeFilter?: InputMaybe<Array<InputMaybe<ShopType>>>;
};

export type QueryGetAllBookingsArgs = {
  filter?: InputMaybe<BookingFilter>;
};

export type QueryGetAllReservationsArgs = {
  filter?: InputMaybe<ReservationFilter>;
};

export type QueryGetAssignableUnitsArgs = {
  pmsReservationId: Scalars["String"]["input"];
  pmsUnitGroupId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetAvailablePropertiesArgs = {
  filter: PropertyAvailabilityFilter;
};

export type QueryGetAvailableUnitGroupsArgs = {
  filter: UnitGroupAvailabilityFilter;
};

export type QueryGetBookingArgs = {
  filter?: InputMaybe<BookingFilter>;
};

export type QueryGetBookingOverviewsArgs = {
  active: Scalars["Boolean"]["input"];
};

export type QueryGetGuestsArgs = {
  filter?: InputMaybe<PersonInput>;
};

export type QueryGetMagicBookingOverviewsArgs = {
  magicId: Scalars["String"]["input"];
};

export type QueryGetMagicObjectArgs = {
  magicId: Scalars["String"]["input"];
};

export type QueryGetMembershipTypesArgs = {
  filter?: InputMaybe<MembershipTypeFilter>;
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetOrderItemAdjustmentReasonCodesArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetOrderItemTransactionTypesArgs = {
  pmsPropertyId: Scalars["String"]["input"];
};

export type QueryGetOrderItemsPageArgs = {
  filter: OrderItemsPageFilter;
};

export type QueryGetPaymentMethodsArgs = {
  pmsPropertyId: Scalars["String"]["input"];
  shopperReference: Scalars["String"]["input"];
};

export type QueryGetPaymentMethodsMagicIdArgs = {
  magicId?: InputMaybe<Scalars["String"]["input"]>;
  shopContext?: InputMaybe<ShopContext>;
  shopperReference: Scalars["String"]["input"];
};

export type QueryGetPotentialBookingPriceArgs = {
  booking?: InputMaybe<PotentialBooking>;
};

export type QueryGetPotentialReservationPriceArgs = {
  reservation?: InputMaybe<PotentialReservation>;
};

export type QueryGetPreferencesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryGetProfileNoteCategoriesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type QueryGetPropertiesArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetPropertiesByCityArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetPropertyArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetReservationArgs = {
  filter?: InputMaybe<ReservationFilter>;
};

export type QueryGetReservationsPageArgs = {
  filter: ReservationsPageFilter;
};

export type QueryGetTotalUnpaidAmountArgs = {
  magicId: Scalars["String"]["input"];
  reservationUpsellRequest?: InputMaybe<ReservationUpsellRequestInput>;
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type QueryGetUnitGroupsArgs = {
  filter?: InputMaybe<UnitGroupFilter>;
};

export type QueryGetUnitsArgs = {
  filter?: InputMaybe<UnitFilter>;
};

export type QueryGetUpsellOffersArgs = {
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
};

export type QueryGetUserProfileByIdArgs = {
  userProfileId: Scalars["Int"]["input"];
};

export type QueryGetUserProfilesArgs = {
  filter: GenericFilter;
};

export type QueryOverviewDetailsArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type QueryOverviewTableArgs = {
  filter: BackofficeReservationOverviewFilter;
};

export type QuerySearchReservationsArgs = {
  filter: ReservationSearchFilter;
};

export type QueryValidatePromoCodeMagicArgs = {
  promoCode: Scalars["String"]["input"];
};

export type QueryValidatePromoCodePmsArgs = {
  promoCode: Scalars["String"]["input"];
};

export type Rate = {
  __typename?: "Rate";
  averagePricePerNight: GrossPrice;
  cancellationFees: Array<CancellationFee>;
  currentCancellationFee?: Maybe<CancellationFee>;
  guaranteeType?: Maybe<GuaranteeType>;
  ratePlan?: Maybe<RatePlan>;
  totalPrice: GrossPrice;
};

export type RatePlan = {
  __typename?: "RatePlan";
  name?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
};

export type RateSplice = {
  __typename?: "RateSplice";
  date?: Maybe<Scalars["LocalDate"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  grossPrice?: Maybe<GrossPrice>;
  hide?: Maybe<Scalars["Boolean"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  prepaid?: Maybe<Scalars["Boolean"]["output"]>;
  prepaymentType?: Maybe<PrepaymentType>;
  quantity?: Maybe<Scalars["Int"]["output"]>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export enum RateSpliceTypeEnumContract {
  Accommodation = "ACCOMMODATION",
  Service = "SERVICE"
}

export type RateSpliceTypeEnumTuple = {
  __typename?: "RateSpliceTypeEnumTuple";
  pmsIdToRateSplicesTuples?: Maybe<Array<PmsIdToRateSplicesTuple>>;
  totalCharges?: Maybe<GrossPrice>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export type RateSpliceTypeTuple = {
  __typename?: "RateSpliceTypeTuple";
  pmsIdRateSplicesTuples?: Maybe<Array<PmsIdRateSplicesTuple>>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export type RateToDate = {
  __typename?: "RateToDate";
  date: Scalars["LocalDate"]["output"];
  grossPrice: GrossPrice;
};

export enum RecurringFilterValue {
  New = "NEW",
  Recurring = "RECURRING"
}

export type RefundPaymentRequest = {
  folioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  magicId: Scalars["String"]["input"];
  pmsPaymentId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
};

export enum RefundStatus {
  Failed = "FAILED",
  Initiated = "INITIATED",
  Refunded = "REFUNDED"
}

export type Reservation = ReservationIdentification & {
  __typename?: "Reservation";
  accessibleDoors?: Maybe<Array<Maybe<AccessibleDoor>>>;
  actor: Actor;
  adultsAmount?: Maybe<Scalars["Int"]["output"]>;
  arrival: Scalars["Instant"]["output"];
  bookedServicesOverview: Array<BookedService>;
  booker?: Maybe<Person>;
  booking?: Maybe<Booking>;
  bookingOverviewItemId: Scalars["String"]["output"];
  cancellationFees: Array<CancellationFee>;
  cancellationTime?: Maybe<Scalars["Instant"]["output"]>;
  channel?: Maybe<Scalars["String"]["output"]>;
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]["output"]>;
  checkoutTime?: Maybe<Scalars["Instant"]["output"]>;
  childrenAmount?: Maybe<Scalars["Int"]["output"]>;
  clevaQUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Instant"]["output"]>;
  currentCancellationFee?: Maybe<CancellationFee>;
  departure: Scalars["Instant"]["output"];
  displayId: Scalars["String"]["output"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]["output"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]["output"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]["output"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]["output"]>;
  files: Array<MagicFileDescriptor>;
  flowState: ReservationFlowState;
  folios: Array<Folio>;
  foliosToBePaid: Array<Folio>;
  groupedRateBreakdown?: Maybe<GroupedRateBreakdown>;
  guaranteeType?: Maybe<GuaranteeType>;
  id: Scalars["Int"]["output"];
  idCheckStatus: IdCheckStatus;
  isExternalReservation: Scalars["Boolean"]["output"];
  magicId: Scalars["String"]["output"];
  magicLink: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  maxCompanions: Scalars["Int"]["output"];
  membershipAccounts?: Maybe<Array<MembershipAccount>>;
  origin?: Maybe<Origin>;
  pmsBookingId: Scalars["String"]["output"];
  pmsId: Scalars["String"]["output"];
  primaryGuest?: Maybe<Person>;
  property: Property;
  reservationComment?: Maybe<Scalars["String"]["output"]>;
  reservationConflicts?: Maybe<Array<Maybe<ReservationConflict>>>;
  reservationUpsellInformation?: Maybe<ReservationUpsellInformation>;
  restaurantReservations?: Maybe<Array<Maybe<RestaurantReservation>>>;
  secondaryGuests?: Maybe<Array<Maybe<Person>>>;
  status: ReservationStatus;
  termsAndConditionsMinors?: Maybe<Scalars["Boolean"]["output"]>;
  totalAllowedPayment?: Maybe<GrossPrice>;
  totalCharges?: Maybe<Price>;
  totalPayments?: Maybe<GrossPrice>;
  travelBuddies: Array<TravelBuddy>;
  unit?: Maybe<Unit>;
  unitGroup?: Maybe<UnitGroup>;
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationRestaurantReservationsArgs = {
  filter?: InputMaybe<RestaurantReservationFilter>;
};

export enum ReservationAction {
  /**
   *     EDIT_PREFERENCES
   *     EDIT_PREFERENCES_TEXT
   */
  AssignUnit = "ASSIGN_UNIT",
  /**     CHANGE_STATUS_TO_NO_SHOW */
  ChangeIdCheckStatus = "CHANGE_ID_CHECK_STATUS",
  ChangeStatusToCanceled = "CHANGE_STATUS_TO_CANCELED",
  ChangeStatusToCheckedOut = "CHANGE_STATUS_TO_CHECKED_OUT",
  ChangeStatusToInHouse = "CHANGE_STATUS_TO_IN_HOUSE",
  ChangeUnitConditionToClean = "CHANGE_UNIT_CONDITION_TO_CLEAN",
  ChangeUnitConditionToCleanToBeInspected = "CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED",
  /**     CHANGE_RESERVATION_COMMENT */
  ChangeUnitConditionToDirty = "CHANGE_UNIT_CONDITION_TO_DIRTY",
  CopyGuestflowLink = "COPY_GUESTFLOW_LINK",
  /**     RESET_GUEST_FLOW */
  EncodeKey = "ENCODE_KEY",
  JumpToBooking = "JUMP_TO_BOOKING",
  JumpToReservation = "JUMP_TO_RESERVATION",
  ManualCheckin = "MANUAL_CHECKIN",
  ManualCheckout = "MANUAL_CHECKOUT",
  ResendGuestflowLink = "RESEND_GUESTFLOW_LINK",
  ResendNotification = "RESEND_NOTIFICATION",
  SendCustomNotification = "SEND_CUSTOM_NOTIFICATION",
  /**     CHANGE_PIN_CONFIGURATION */
  ValidateReservation = "VALIDATE_RESERVATION"
}

export enum ReservationCheckInOutAuthor {
  Backoffice = "BACKOFFICE",
  CheckinWizard = "CHECKIN_WIZARD",
  Guest = "GUEST",
  Kiosk = "KIOSK",
  LmAutomation = "LM_AUTOMATION",
  Pms = "PMS"
}

export enum ReservationCondition {
  Critical = "CRITICAL",
  Minor = "MINOR",
  Ok = "OK",
  Unknown = "UNKNOWN"
}

export type ReservationConflict = {
  __typename?: "ReservationConflict";
  conflict?: Maybe<ReservationConflictType>;
  reservationId?: Maybe<Scalars["Int"]["output"]>;
};

export enum ReservationConflictType {
  InvalidEmail = "INVALID_EMAIL",
  InvalidPhoneNumber = "INVALID_PHONE_NUMBER",
  NotAttachedToUserAccount = "NOT_ATTACHED_TO_USER_ACCOUNT",
  NoUserprofileExists = "NO_USERPROFILE_EXISTS",
  OverlappingStaySamePrimaryGuestDifferentBooking = "OVERLAPPING_STAY_SAME_PRIMARY_GUEST_DIFFERENT_BOOKING",
  OverlappingStaySamePrimaryGuestSameBooking = "OVERLAPPING_STAY_SAME_PRIMARY_GUEST_SAME_BOOKING",
  SameArrivalSamePrimaryGuestDifferentBooking = "SAME_ARRIVAL_SAME_PRIMARY_GUEST_DIFFERENT_BOOKING",
  SameArrivalSamePrimaryGuestSameBooking = "SAME_ARRIVAL_SAME_PRIMARY_GUEST_SAME_BOOKING"
}

export type ReservationFilter = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  magicId?: InputMaybe<Scalars["String"]["input"]>;
  matchingBehaviour?: InputMaybe<MatchingBehaviour>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<ReservationStatus>;
};

export type ReservationFlowState = IFlowState & {
  __typename?: "ReservationFlowState";
  billConfirmed?: Maybe<Scalars["Boolean"]["output"]>;
  bookingOnBehalfOf?: Maybe<BookingOnBehalfOf>;
  cleanUnitDialogSeen?: Maybe<Scalars["Boolean"]["output"]>;
  completed: Scalars["Boolean"]["output"];
  context: Scalars["Json"]["output"];
  dirtyUnitDialogSeen?: Maybe<Scalars["Boolean"]["output"]>;
  fastCheckinAvailable: Scalars["Boolean"]["output"];
  notificationPending: Scalars["Boolean"]["output"];
  notificationSent: Scalars["Boolean"]["output"];
  performedActions: Array<Scalars["String"]["output"]>;
  sentNotifications: Array<Scalars["String"]["output"]>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]["output"]>;
  wasOrIsCompleted: Scalars["Boolean"]["output"];
};

export type ReservationIdentification = {
  id: Scalars["Int"]["output"];
  pmsId: Scalars["String"]["output"];
};

export type ReservationInput = {
  adultsAmount?: InputMaybe<Scalars["Int"]["input"]>;
  arrival?: InputMaybe<Scalars["Instant"]["input"]>;
  childrenAmount?: InputMaybe<Scalars["Int"]["input"]>;
  departure?: InputMaybe<Scalars["Instant"]["input"]>;
  pmsUnitId?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReservationSearchFilter = {
  arrival: Scalars["LocalDate"]["input"];
  departure: Scalars["LocalDate"]["input"];
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  primaryGuest: PrimaryGuestFilter;
  propertyId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ReservationSearchResult = {
  __typename?: "ReservationSearchResult";
  adultsAmount?: Maybe<Scalars["Int"]["output"]>;
  childrenAmount?: Maybe<Scalars["Int"]["output"]>;
  magicId: Scalars["String"]["output"];
  unit?: Maybe<ReservationSearchResultUnit>;
  unitGroup?: Maybe<ReservationSearchResultUnitGroup>;
};

export type ReservationSearchResultUnit = {
  __typename?: "ReservationSearchResultUnit";
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationSearchResultUnitGroup = {
  __typename?: "ReservationSearchResultUnitGroup";
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationServices = ReservationIdentification & {
  __typename?: "ReservationServices";
  id: Scalars["Int"]["output"];
  pmsId: Scalars["String"]["output"];
  serviceOrders?: Maybe<Array<Maybe<ServiceOrder>>>;
};

export enum ReservationStatus {
  Cancelled = "CANCELLED",
  CheckedOut = "CHECKED_OUT",
  Confirmed = "CONFIRMED",
  InHouse = "IN_HOUSE",
  NoShow = "NO_SHOW"
}

export type ReservationTermsAndConditionsInput = {
  termsAndConditionsMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReservationUpsellInformation = {
  __typename?: "ReservationUpsellInformation";
  originalPmsUnitGroupId?: Maybe<Scalars["String"]["output"]>;
};

export type ReservationUpsellOffer = {
  __typename?: "ReservationUpsellOffer";
  calculatedUpsellInformation: CalculatedUpsellInformation;
  firstNightAmount: GrossPrice;
  pmsRatePlanId: Scalars["String"]["output"];
  pmsUnitGroupId: Scalars["String"]["output"];
  pmsUpsellRuleId: Scalars["String"]["output"];
  rates: Array<RateToDate>;
  totalAmount: GrossPrice;
};

export type ReservationUpsellRequestInput = {
  pmsRatePlanId: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
  pmsUpsellRuleId: Scalars["String"]["input"];
};

export type ReservationsPage = {
  __typename?: "ReservationsPage";
  content: Array<Reservation>;
  totalElements: Scalars["Int"]["output"];
};

export type ReservationsPageFilter = {
  filters?: InputMaybe<Scalars["Json"]["input"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
  userProfileId?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RestaurantReservation = {
  __typename?: "RestaurantReservation";
  actualNumberOfGuests?: Maybe<Scalars["Int"]["output"]>;
  createdAt: Scalars["Instant"]["output"];
  duration?: Maybe<Scalars["String"]["output"]>;
  expectedNumberOfGuests?: Maybe<Scalars["Int"]["output"]>;
  extraFoodAndBeverageComplexProperties?: Maybe<Scalars["Json"]["output"]>;
  extraFoodAndBeverageSimpleProperties?: Maybe<Scalars["Json"]["output"]>;
  foodAndBeverageId: Scalars["String"]["output"];
  foodAndBeveragePropertyId: Scalars["String"]["output"];
  foodAndBeveragePropertyName: Scalars["String"]["output"];
  foodAndBeverageReservationId: Scalars["String"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  reservationDateTime: Scalars["Instant"]["output"];
  reservationTime?: Maybe<Scalars["String"]["output"]>;
  status: RestaurantReservationStatus;
  tableNumbers?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  updatedAt: Scalars["Instant"]["output"];
};

export type RestaurantReservationFilter = {
  statuses?: InputMaybe<Array<RestaurantReservationStatus>>;
};

export enum RestaurantReservationStatus {
  Arrived = "ARRIVED",
  Booked = "BOOKED",
  Canceled = "CANCELED",
  Completed = "COMPLETED",
  Confirmed = "CONFIRMED",
  NoShow = "NO_SHOW",
  Seated = "SEATED"
}

export type SecondScreenBackofficeEvent = BackofficeOverviewDetailEvent & {
  __typename?: "SecondScreenBackofficeEvent";
  payload: SecondScreenEvent;
  pmsReservationId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: BackofficeOverviewDetailEventType;
};

export type SecondScreenEvent = {
  deviceId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export enum SecondScreenEventType {
  BillingConfirmed = "BILLING_CONFIRMED",
  Canceled = "CANCELED",
  Signed = "SIGNED",
  VerifyAndSign = "VERIFY_AND_SIGN",
  VerifyAndSignAck = "VERIFY_AND_SIGN_ACK",
  VerifyBill = "VERIFY_BILL",
  VerifyBillAck = "VERIFY_BILL_ACK"
}

export type SecondaryPersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Service = {
  __typename?: "Service";
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  active?: Maybe<Scalars["Boolean"]["output"]>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  chargeMode?: Maybe<ChargeMode>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  chargeUnit?: Maybe<ChargeUnit>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName?: Maybe<Scalars["String"]["output"]>;
  included?: Maybe<Scalars["Boolean"]["output"]>;
  magicServiceCodeEnum?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  pmsCategoryId?: Maybe<Scalars["String"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Price>;
  tags: Array<Scalars["String"]["output"]>;
};

export enum ServiceCodeEnum {
  BabyBed = "BABY_BED",
  BabyBed2 = "BABY_BED2",
  Breakfast = "BREAKFAST",
  Breakfast2 = "BREAKFAST2",
  Breakfast3 = "BREAKFAST3",
  Breakfast4 = "BREAKFAST4",
  Breakfast5 = "BREAKFAST5",
  Breakfast6 = "BREAKFAST6",
  ChildrenBreakfast1 = "CHILDREN_BREAKFAST1",
  ChildrenBreakfast2 = "CHILDREN_BREAKFAST2",
  ChildrenBreakfast3 = "CHILDREN_BREAKFAST3",
  DailyPet = "DAILY_PET",
  DonateBreakfast = "DONATE_BREAKFAST",
  EarlyCheckin = "EARLY_CHECKIN",
  ExternalBreakfast1 = "EXTERNAL_BREAKFAST1",
  ExternalBreakfast2 = "EXTERNAL_BREAKFAST2",
  ExternalBreakfast3 = "EXTERNAL_BREAKFAST3",
  ExternalBreakfast4 = "EXTERNAL_BREAKFAST4",
  Fitness1 = "FITNESS1",
  Fitness30 = "FITNESS30",
  FreeBreakfast = "FREE_BREAKFAST",
  FreeCleaning = "FREE_CLEANING",
  HairDryer = "HAIR_DRYER",
  LateCheckout = "LATE_CHECKOUT",
  NeckPillow = "NECK_PILLOW",
  PaidCleaning = "PAID_CLEANING",
  Parking = "PARKING",
  ParkingPerDay = "PARKING_PER_DAY",
  ParkingPerMonth = "PARKING_PER_MONTH",
  ParkingPerWeek = "PARKING_PER_WEEK",
  Storage = "STORAGE",
  Ventilator = "VENTILATOR",
  WelcomeDrink = "WELCOME_DRINK"
}

/** deprecated */
export type ServiceOrder = {
  __typename?: "ServiceOrder";
  date?: Maybe<Scalars["Instant"]["output"]>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  price?: Maybe<Price>;
  service: Service;
  tags: Array<Scalars["String"]["output"]>;
};

/** deprecated */
export type ServiceOrderRequest = {
  dates?: InputMaybe<Array<InputMaybe<Scalars["LocalDate"]["input"]>>>;
  grossPrice?: InputMaybe<GrossPriceInput>;
  pmsServiceId: Scalars["String"]["input"];
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
};

/** deprecated */
export type ServiceOverview = {
  __typename?: "ServiceOverview";
  dates: Array<ServiceOrder>;
  service: Service;
  totalPrice?: Maybe<GrossPrice>;
};

export type SettleFolioInput = {
  folioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  pmsPropertyId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
};

export enum ShopContext {
  AdditionalServices = "ADDITIONAL_SERVICES",
  Amenities = "AMENITIES",
  LoyaltyShop = "LOYALTY_SHOP",
  Minibar = "MINIBAR",
  MinibarCheckoutFlow = "MINIBAR_CHECKOUT_FLOW",
  None = "NONE",
  SelfPouringStation = "SELF_POURING_STATION"
}

export enum ShopType {
  AdditionalServices = "ADDITIONAL_SERVICES",
  Amenities = "AMENITIES",
  LoyaltyShop = "LOYALTY_SHOP",
  Minibar = "MINIBAR",
  MinibarCheckoutFlow = "MINIBAR_CHECKOUT_FLOW",
  SelfPouringStation = "SELF_POURING_STATION"
}

export type Signed = SecondScreenEvent & {
  __typename?: "Signed";
  deviceId: Scalars["String"]["output"];
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type StoredPaymentConfig = {
  __typename?: "StoredPaymentConfig";
  brand?: Maybe<Scalars["String"]["output"]>;
  expiryMonth?: Maybe<Scalars["String"]["output"]>;
  expiryYear?: Maybe<Scalars["String"]["output"]>;
  holderName?: Maybe<Scalars["String"]["output"]>;
  iban?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["String"]["output"]>;
  lastFour?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  ownerName?: Maybe<Scalars["String"]["output"]>;
  shopperEmail?: Maybe<Scalars["String"]["output"]>;
  supportedShopperInteractions?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type StoredPaymentMethod = {
  __typename?: "StoredPaymentMethod";
  config?: Maybe<StoredPaymentConfig>;
  hashCode: Scalars["String"]["output"];
  pointOfSales: Scalars["Boolean"]["output"];
  shopperReference?: Maybe<Scalars["String"]["output"]>;
  type: PaymentMethodType;
};

export type StringWrapper = {
  __typename?: "StringWrapper";
  value: Scalars["String"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  /**
   * Streams any event related to a specific reservation identified by the pmsReservationId,
   * in the ReservationOverviewDetails view. Events needs to implement BackofficeOverviewDetailEvent interface.
   * @deprecated Use the 'StreamBackofficeSecondScreenEvents' subscription instead
   */
  StreamBackofficeReservationOverviewDetails?: Maybe<BackofficeOverviewDetailEvent>;
  /**
   * Streams any event related to a specific reservation identified by the pmsReservationId,
   * in the ReservationOverviewDetails view. Events needs to implement BackofficeOverviewDetailEvent interface.
   */
  StreamBackofficeSecondScreenEvents?: Maybe<BackofficeOverviewDetailEvent>;
  StreamBooking?: Maybe<Booking>;
  StreamBookingOverview: BookingOverview;
  StreamMagicObject?: Maybe<MagicObject>;
  StreamReservation?: Maybe<Reservation>;
  /** Streams second screen related events for a specific second screen device, identified by the deviceId. */
  StreamSecondScreenDeviceEvents?: Maybe<SecondScreenEvent>;
  /**
   * Initiates a terminal payment. There will be two messages in the lifetime of this subscription.
   * The initial message gives information about the transaction id and the service id. The second and last message
   * indicates the outcome of the terminal payment.
   *
   * The transaction id uniquely identifies this interaction.
   * The service id identifies the terminal request.
   * Both are required to cancel an ongoing terminal payment. A terminal payment can only be cancelled until a payment
   * method will be presented and the payment interaction concludes.
   */
  TerminalPayment?: Maybe<TerminalResponse>;
};

export type SubscriptionStreamBackofficeReservationOverviewDetailsArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type SubscriptionStreamBackofficeSecondScreenEventsArgs = {
  pmsReservationId: Scalars["String"]["input"];
};

export type SubscriptionStreamBookingArgs = {
  id: Scalars["Int"]["input"];
};

export type SubscriptionStreamBookingOverviewArgs = {
  magicId: Scalars["String"]["input"];
  magicToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubscriptionStreamMagicObjectArgs = {
  magicId: Scalars["String"]["input"];
  magicToken?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubscriptionStreamReservationArgs = {
  id: Scalars["Int"]["input"];
};

export type SubscriptionStreamSecondScreenDeviceEventsArgs = {
  deviceId: Scalars["String"]["input"];
};

export type SubscriptionTerminalPaymentArgs = {
  paymentRequest?: InputMaybe<TerminalPaymentRequest>;
};

export enum TaskTemplate {
  CheckoutNote = "CHECKOUT_NOTE",
  /** ch.aaap.magic.common.TaskTemplate.java. But only the ones that the guest should be able to create */
  LuggagePickup = "LUGGAGE_PICKUP"
}

export type Tax = {
  __typename?: "Tax";
  amount?: Maybe<Scalars["BigDecimal"]["output"]>;
  amountInCents?: Maybe<Scalars["Int"]["output"]>;
  code?: Maybe<Scalars["String"]["output"]>;
};

export type TerminalPaymentMethod = {
  __typename?: "TerminalPaymentMethod";
  availableTerminals?: Maybe<Array<Maybe<AvailableTerminal>>>;
  hashCode: Scalars["String"]["output"];
  type: PaymentMethodType;
};

export type TerminalPaymentRequest = {
  amount?: InputMaybe<Amount>;
  magicId: Scalars["String"]["input"];
  pmsPropertyId: Scalars["String"]["input"];
  shopperReference: Scalars["String"]["input"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]["input"]>;
  terminalId: Scalars["String"]["input"];
};

export type TerminalResponse = {
  __typename?: "TerminalResponse";
  errorCode?: Maybe<Scalars["String"]["output"]>;
  errorReason?: Maybe<Scalars["String"]["output"]>;
  serviceId?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
  transactionId?: Maybe<Scalars["String"]["output"]>;
};

export type TransitoryPayment = {
  __typename?: "TransitoryPayment";
  paymentMethod?: Maybe<Scalars["String"]["output"]>;
  shopperReference?: Maybe<Scalars["String"]["output"]>;
};

export type TravelBuddy = {
  __typename?: "TravelBuddy";
  accessibleDoors?: Maybe<Array<Maybe<AccessibleDoor>>>;
  actor: Actor;
  arrival: Scalars["Instant"]["output"];
  bookingId: Scalars["Int"]["output"];
  bookingMagicId: Scalars["String"]["output"];
  bookingOverviewItemId: Scalars["String"]["output"];
  channel?: Maybe<Scalars["String"]["output"]>;
  checkinTime?: Maybe<Scalars["Instant"]["output"]>;
  checkoutTime?: Maybe<Scalars["Instant"]["output"]>;
  createdAt: Scalars["Instant"]["output"];
  currency?: Maybe<Scalars["String"]["output"]>;
  departure: Scalars["Instant"]["output"];
  flowState: FlowState;
  magicId: Scalars["String"]["output"];
  magicLink: Scalars["String"]["output"];
  magicToken: Scalars["String"]["output"];
  notificationLanguage?: Maybe<Scalars["String"]["output"]>;
  person: Person;
  pmsBookingId: Scalars["String"]["output"];
  /**  LM-1490 - what's the idea behind these two fields that contain the same thing */
  pmsLanguage?: Maybe<Scalars["String"]["output"]>;
  pmsPropertyId: Scalars["String"]["output"];
  pmsReservationId: Scalars["String"]["output"];
  /**  TODO not this PR - remove this DB id */
  propertyId: Scalars["Int"]["output"];
  /**  TODO not this PR - remove this DB id */
  reservationId: Scalars["Int"]["output"];
  reservationMagicId: Scalars["String"]["output"];
  status: ReservationStatus;
  unit?: Maybe<Unit>;
  /**
   *  TODO not this PR - why isn't this in FlowState?
   *  Does FE even need it?
   */
  unitCleanOnCheckin: Scalars["Boolean"]["output"];
  unitGroup?: Maybe<UnitGroup>;
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
  userProfileId?: Maybe<Scalars["Int"]["output"]>;
};

export type Unit = {
  __typename?: "Unit";
  accessibility?: Maybe<DoorLockAccessibility>;
  active?: Maybe<Scalars["Boolean"]["output"]>;
  batteryLevel?: Maybe<BatteryLevel>;
  floor?: Maybe<Scalars["String"]["output"]>;
  gatewayId?: Maybe<Scalars["String"]["output"]>;
  lockId?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notes?: Maybe<Scalars["String"]["output"]>;
  occupied?: Maybe<Scalars["Boolean"]["output"]>;
  online?: Maybe<Scalars["Boolean"]["output"]>;
  onlineLastChangeTimestamp?: Maybe<Scalars["Instant"]["output"]>;
  openAlert?: Maybe<Scalars["Boolean"]["output"]>;
  origin?: Maybe<Origin>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  pmsServiceId?: Maybe<Scalars["String"]["output"]>;
  pmsUnitGroupIds?: Maybe<Array<Scalars["String"]["output"]>>;
  privacyMode?: Maybe<Scalars["Boolean"]["output"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]["output"]>;
  property?: Maybe<Property>;
  state?: Maybe<UnitState>;
};

export type UnitFilter = {
  floor?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  origin?: InputMaybe<Origin>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<UnitState>;
};

export type UnitGroup = {
  __typename?: "UnitGroup";
  active?: Maybe<Scalars["Boolean"]["output"]>;
  capacity?: Maybe<Scalars["Int"]["output"]>;
  extraCapacity?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  origin?: Maybe<Origin>;
  pmsId?: Maybe<Scalars["String"]["output"]>;
  property?: Maybe<Property>;
  serviceId?: Maybe<Scalars["String"]["output"]>;
};

export type UnitGroupAvailability = {
  __typename?: "UnitGroupAvailability";
  arrival: Scalars["Instant"]["output"];
  availableUnitsThreshold: Scalars["Int"]["output"];
  cheapestRate: Rate;
  departure: Scalars["Instant"]["output"];
  numberOfAvailableUnits: Scalars["Int"]["output"];
  numberOfNights: Scalars["Int"]["output"];
  pmsPropertyId?: Maybe<Scalars["String"]["output"]>;
  property?: Maybe<Property>;
  rates: Array<Rate>;
  unitGroup?: Maybe<UnitGroup>;
};

export type UnitGroupAvailabilityFilter = {
  adultsAmount: Scalars["Int"]["input"];
  arrival: Scalars["LocalDate"]["input"];
  childrenAges?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  childrenAmount?: InputMaybe<Scalars["Int"]["input"]>;
  departure: Scalars["LocalDate"]["input"];
  pmsCode?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnitGroupFilter = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  capacity?: InputMaybe<Scalars["Int"]["input"]>;
  extraCapacity?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  origin?: InputMaybe<Origin>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]["input"]>;
  serviceId?: InputMaybe<Scalars["String"]["input"]>;
};

export type UnitInput = {
  floor?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<UnitState>;
};

export enum UnitState {
  Clean = "CLEAN",
  CleanToBeInspected = "CLEAN_TO_BE_INSPECTED",
  Dirty = "DIRTY",
  OutOfOrder = "OUT_OF_ORDER",
  OutOfService = "OUT_OF_SERVICE",
  Pickup = "PICKUP"
}

export type UpdatePaymentSessionResponse = {
  __typename?: "UpdatePaymentSessionResponse";
  newBookingMagicId?: Maybe<Scalars["String"]["output"]>;
};

export type UpdatePersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  emergencyEvacuationHelpNeeded?: InputMaybe<Scalars["Boolean"]["input"]>;
  emergencyEvacuationHelpNotes?: InputMaybe<Scalars["String"]["input"]>;
  enrolledInLoyaltyProgram?: InputMaybe<Scalars["Boolean"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Gender>;
  identificationDocumentNumber?: InputMaybe<Scalars["String"]["input"]>;
  identificationDocumentType?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  licensePlate?: InputMaybe<Scalars["String"]["input"]>;
  nationality?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  pmsId?: InputMaybe<Scalars["String"]["input"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]["input"]>;
  termsAndConditionsGeneral?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsAndConditionsMarketingConsent?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsAndConditionsMarketingConsentDoubleOptin?: InputMaybe<Scalars["Boolean"]["input"]>;
  termsAndConditionsOnline?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateUserProfileInput = {
  pmsPreferenceIds?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  address?: Maybe<Address>;
  birthdate?: Maybe<Scalars["LocalDate"]["output"]>;
  blacklisted: Scalars["Boolean"]["output"];
  documents: UserProfileDocuments;
  email?: Maybe<Scalars["String"]["output"]>;
  emergencyEvacuationHelpNeeded: Scalars["Boolean"]["output"];
  emergencyEvacuationHelpNotes?: Maybe<Scalars["String"]["output"]>;
  enrolledInLoyaltyProgram: Scalars["Boolean"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Gender>;
  guestIdentityId?: Maybe<Scalars["Int"]["output"]>;
  id: Scalars["Int"]["output"];
  identificationDocumentNumber?: Maybe<Scalars["String"]["output"]>;
  identificationDocumentType?: Maybe<Scalars["String"]["output"]>;
  identificationDocumentUploaded: Scalars["Boolean"]["output"];
  isEmailVerified: Scalars["Boolean"]["output"];
  isRecurring: Scalars["Boolean"]["output"];
  lastName?: Maybe<Scalars["String"]["output"]>;
  licensePlate?: Maybe<Scalars["String"]["output"]>;
  membershipAccounts: Array<MembershipAccount>;
  nationality?: Maybe<Scalars["String"]["output"]>;
  notes: Array<ProfileNote>;
  numberOfPastBookingsAsBooker: Scalars["Int"]["output"];
  numberOfPastReservationsAsPrimaryGuest: Scalars["Int"]["output"];
  numberOfPastReservationsAsTravelBuddy: Scalars["Int"]["output"];
  numberOfReservations: Scalars["Int"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  preferences: Array<Preference>;
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]["output"]>;
  signatureDocumentUploaded: Scalars["Boolean"]["output"];
  termsAndConditionsGeneral: Scalars["Boolean"]["output"];
  termsAndConditionsMarketingConsent: Scalars["Boolean"]["output"];
  termsAndConditionsMarketingConsentDoubleOptin: Scalars["Boolean"]["output"];
  termsAndConditionsOnline: Scalars["Boolean"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  userAccountUuid?: Maybe<Scalars["String"]["output"]>;
  vipCode?: Maybe<Scalars["String"]["output"]>;
};

export type UserProfileDocuments = {
  __typename?: "UserProfileDocuments";
  passport?: Maybe<MagicFileDescriptor>;
  signature?: Maybe<MagicFileDescriptor>;
};

export type UserProfilesPage = {
  __typename?: "UserProfilesPage";
  content: Array<UserProfile>;
  totalElements: Scalars["Int"]["output"];
};

export type ValidatePromoCodeMagicResponse = {
  __typename?: "ValidatePromoCodeMagicResponse";
  discountHidden?: Maybe<Scalars["Boolean"]["output"]>;
  valid: Scalars["Boolean"]["output"];
};

export type VerifyAndSign = SecondScreenEvent & {
  __typename?: "VerifyAndSign";
  deviceId: Scalars["String"]["output"];
  payload: VerifyAndSignPayload;
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type VerifyAndSignAck = SecondScreenEvent & {
  __typename?: "VerifyAndSignAck";
  deviceId: Scalars["String"]["output"];
  payload: VerifyAndSignAckPayload;
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type VerifyAndSignAckPayload = {
  __typename?: "VerifyAndSignAckPayload";
  accepted: Scalars["Boolean"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type VerifyAndSignPayload = {
  __typename?: "VerifyAndSignPayload";
  reservation: Reservation;
};

export type VerifyBill = SecondScreenEvent & {
  __typename?: "VerifyBill";
  deviceId: Scalars["String"]["output"];
  payload: VerifyBillPayload;
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type VerifyBillAck = SecondScreenEvent & {
  __typename?: "VerifyBillAck";
  deviceId: Scalars["String"]["output"];
  payload: VerifyBillAckPayload;
  tenantName: Scalars["String"]["output"];
  type: SecondScreenEventType;
};

export type VerifyBillAckPayload = {
  __typename?: "VerifyBillAckPayload";
  accepted: Scalars["Boolean"]["output"];
  reason?: Maybe<Scalars["String"]["output"]>;
};

export type VerifyBillPayload = {
  __typename?: "VerifyBillPayload";
  reservation: Reservation;
};

export enum Visibility {
  Backoffice = "BACKOFFICE",
  GuestFlow = "GUEST_FLOW",
  MyStay = "MY_STAY"
}

export type Void = {
  __typename?: "Void";
  /** Will never be filled */
  None?: Maybe<Scalars["ID"]["output"]>;
};

export type WidgetPaymentDetailsRequest = {
  amount: GrossPriceInput;
  frontendPayload: FrontendPayload;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]["input"]>;
  reservationUpsellRequest?: InputMaybe<ReservationUpsellRequestInput>;
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type WidgetPaymentRequest = {
  amount: GrossPriceInput;
  frontendPayload: FrontendPayload;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference: Scalars["String"]["input"];
  reservationUpsellRequest?: InputMaybe<ReservationUpsellRequestInput>;
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type PersonFragmentFragment = {
  __typename?: "Person";
  id: number;
  pmsId: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  email?: string | null;
  gender?: Gender | null;
  birthdate?: any | null;
  age?: number | null;
  isMinor?: boolean | null;
  preferredCommunicationChannel?: CommunicationChannel | null;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  emergencyEvacuationHelpNeeded: boolean;
  emergencyEvacuationHelpNotes?: string | null;
  enrolledInLoyaltyProgram: boolean;
  identificationDocumentNumber?: string | null;
  identificationDocumentType?: string | null;
  userProfileId?: number | null;
  preferredLanguage?: string | null;
  nationality?: string | null;
  vipCode?: string | null;
  licensePlate?: string | null;
  publicTransportationTickets?: Array<{
    __typename?: "PublicTransportationTicket";
    code?: string | null;
    link?: string | null;
    name?: string | null;
    valid?: boolean | null;
    validUntil?: any | null;
    ownerMagicId?: string | null;
  } | null> | null;
  userProfile?: {
    __typename?: "UserProfile";
    isRecurring: boolean;
    userAccountUuid?: string | null;
    blacklisted: boolean;
    licensePlate?: string | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
    notes: Array<{
      __typename?: "ProfileNote";
      pmsId: string;
      title?: string | null;
      text?: string | null;
      pmsPropertyId?: string | null;
      pmsProfileNoteCategoryId?: string | null;
      category?: { __typename?: "ProfileNoteCategory"; pmsId: string; name?: string | null } | null;
    }>;
  } | null;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    countryCode?: string | null;
    postalCode?: string | null;
    region?: string | null;
  } | null;
  membershipAccounts: Array<{
    __typename?: "MembershipAccount";
    membershipTypeCode?: string | null;
    membershipId?: string | null;
    membershipClass?: MemberShipClassEnum | null;
    pmsId?: string | null;
    name?: string | null;
  }>;
};

export type CashPaymentMutationVariables = Exact<{
  cashPaymentRequest?: InputMaybe<PaymentRequest>;
  magicId: Scalars["String"]["input"];
}>;

export type CashPaymentMutation = { __typename?: "Mutation"; CashPayment?: string | null };

export type AddArbitraryOrderItemsMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  addArbitraryOrderItemsInput: Array<AddArbitraryOrderItemInput> | AddArbitraryOrderItemInput;
}>;

export type AddArbitraryOrderItemsMutation = {
  __typename?: "Mutation";
  BackofficeAddArbitraryOrderItems?: boolean | null;
};

export type AddNoteToProfileMutationVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
  note: NoteInput;
}>;

export type AddNoteToProfileMutation = {
  __typename?: "Mutation";
  AddNoteToProfile: { __typename?: "ProfileNote"; pmsId: string };
};

export type AddPreferenceToProfileMutationVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
  pmsPreferenceId: Scalars["String"]["input"];
}>;

export type AddPreferenceToProfileMutation = {
  __typename?: "Mutation";
  AddPreferenceToProfile: {
    __typename?: "UserProfile";
    id: number;
    userAccountUuid?: string | null;
  };
};

export type AddServicesToReservationMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  services: Array<ServiceOrderRequest> | ServiceOrderRequest;
}>;

export type AddServicesToReservationMutation = {
  __typename?: "Mutation";
  BackofficeAddServicesToReservation?: Array<{
    __typename?: "ServiceOrder";
    pmsId?: string | null;
  } | null> | null;
};

export type AddToBlacklistMutationVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
}>;

export type AddToBlacklistMutation = { __typename?: "Mutation"; AddToBlacklist: boolean };

export type BackofficeAdjustOrderItemMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  adjustOrderItemInput?: InputMaybe<AdjustOrderItemInput>;
}>;

export type BackofficeAdjustOrderItemMutation = {
  __typename?: "Mutation";
  BackofficeAdjustOrderItem?: Array<{ __typename?: "OrderItem"; pmsId: string } | null> | null;
};

export type AdminOpenDoorMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  doorId: Scalars["String"]["input"];
}>;

export type AdminOpenDoorMutation = { __typename?: "Mutation"; AdminOpenDoor?: boolean | null };

export type SyncDoorAccessMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  status: DoorAccessState;
}>;

export type SyncDoorAccessMutation = { __typename?: "Mutation"; SyncDoorAccess?: boolean | null };

export type BackofficeAddMembershipToProfileMutationVariables = Exact<{
  pmsProfileId: Scalars["String"]["input"];
  pmsReservationId: Scalars["String"]["input"];
  membershipAccount: MembershipAccountInput;
}>;

export type BackofficeAddMembershipToProfileMutation = {
  __typename?: "Mutation";
  BackofficeAddMembershipToProfile?: boolean | null;
};

export type CancelTerminalPaymentMutationVariables = Exact<{
  cancelTerminalPaymentRequest: CancelTerminalPaymentRequest;
}>;

export type CancelTerminalPaymentMutation = {
  __typename?: "Mutation";
  CancelTerminalPayment?: boolean | null;
};

export type CardOnfilePaymentMutationVariables = Exact<{
  cardOnFilePaymentRequest: CardOnFilePaymentRequest;
}>;

export type CardOnfilePaymentMutation = {
  __typename?: "Mutation";
  CardOnFilePayment?: boolean | null;
};

export type CreatePaymentLinkMutationVariables = Exact<{
  paymentLinkRequest?: InputMaybe<PaymentLinkRequest>;
}>;

export type CreatePaymentLinkMutation = {
  __typename?: "Mutation";
  CreatePaymentLink?: { __typename?: "PaymentLinkResponse"; url: string } | null;
};

export type DeleteNoteMutationVariables = Exact<{
  pmsProfileNoteId: Scalars["String"]["input"];
}>;

export type DeleteNoteMutation = { __typename?: "Mutation"; DeleteNote: boolean };

export type BackofficeManualCheckinSkipToStepMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  checkpoint: BackofficeManualCheckInCheckpoint;
}>;

export type BackofficeManualCheckinSkipToStepMutation = {
  __typename?: "Mutation";
  BackofficeManualCheckinSkipToStep?: boolean | null;
};

export type MoveOrderItemsMutationVariables = Exact<{
  moveOrderItemsInput: MoveOrderItemsInput;
}>;

export type MoveOrderItemsMutation = { __typename?: "Mutation"; MoveOrderItems?: boolean | null };

export type MovePaymentsMutationVariables = Exact<{
  movePaymentsInput: MovePaymentInput;
}>;

export type MovePaymentsMutation = { __typename?: "Mutation"; MovePayments?: boolean | null };

export type OpenDoorMutationVariables = Exact<{
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
  doorId: Scalars["String"]["input"];
}>;

export type OpenDoorMutation = {
  __typename?: "Mutation";
  OpenDoor?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export type PerformActionMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  reservationAction: ReservationAction;
  payload?: InputMaybe<Scalars["Json"]["input"]>;
}>;

export type PerformActionMutation = { __typename?: "Mutation"; PerformAction?: boolean | null };

export type PerformSecondScreenActionMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  deviceId: Scalars["String"]["input"];
  eventType: SecondScreenEventType;
}>;

export type PerformSecondScreenActionMutation = {
  __typename?: "Mutation";
  BackofficePerformSecondScreenAction?: boolean | null;
};

export type RefundPaymentMutationVariables = Exact<{
  refundPaymentRequest?: InputMaybe<RefundPaymentRequest>;
}>;

export type RefundPaymentMutation = {
  __typename?: "Mutation";
  RefundPayment?: { __typename?: "Void"; None?: string | null } | null;
};

export type RemoveFromBlacklistMutationVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
}>;

export type RemoveFromBlacklistMutation = { __typename?: "Mutation"; RemoveFromBlacklist: boolean };

export type RemovePreferenceFromProfileMutationVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
  pmsPreferenceId: Scalars["String"]["input"];
}>;

export type RemovePreferenceFromProfileMutation = {
  __typename?: "Mutation";
  RemovePreferenceFromProfile: {
    __typename?: "UserProfile";
    id: number;
    userAccountUuid?: string | null;
  };
};

export type SendCustomNotificationMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  payload?: InputMaybe<Scalars["Json"]["input"]>;
}>;

export type SendCustomNotificationMutation = {
  __typename?: "Mutation";
  PerformAction?: boolean | null;
};

export type SetPreferredCleaningDaysMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  days: Array<DayOfWeek> | DayOfWeek;
}>;

export type SetPreferredCleaningDaysMutation = {
  __typename?: "Mutation";
  SetPreferredCleaningDays: { __typename?: "Person"; id: number };
};

export type UpdateNoteMutationVariables = Exact<{
  pmsProfileNoteId: Scalars["String"]["input"];
  note: NoteInput;
}>;

export type UpdateNoteMutation = {
  __typename?: "Mutation";
  UpdateNote: { __typename?: "ProfileNote"; pmsId: string };
};

export type UpdateRegistrationCardGuestsMutationVariables = Exact<{
  person: UpdatePersonInput;
  pmsReservationId: Scalars["String"]["input"];
  persons: Array<SecondaryPersonInput> | SecondaryPersonInput;
}>;

export type UpdateRegistrationCardGuestsMutation = {
  __typename?: "Mutation";
  BackofficeUpdatePrimaryGuest: { __typename?: "Person"; id: number };
  BackofficeUpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; id: number }>;
};

export type BulkUpdateUnitStateMutationVariables = Exact<{
  pmsUnitIds: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  state: UnitState;
}>;

export type BulkUpdateUnitStateMutation = {
  __typename?: "Mutation";
  BulkUpdateUnitState?: boolean | null;
};

export type BackofficeUpsellReservationMutationVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
  pmsUpsellRuleId: Scalars["String"]["input"];
}>;

export type BackofficeUpsellReservationMutation = {
  __typename?: "Mutation";
  BackofficeUpsellReservation?: boolean | null;
};

export type AvailableTemplatesForSendCustomNotificationQueryVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
}>;

export type AvailableTemplatesForSendCustomNotificationQuery = {
  __typename?: "Query";
  AvailableTemplatesForSendCustomNotification: Array<NotificationType>;
};

export type BackofficeGetUpsellOffersQueryVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
}>;

export type BackofficeGetUpsellOffersQuery = {
  __typename?: "Query";
  BackofficeGetUpsellOffers: Array<{
    __typename?: "ReservationUpsellOffer";
    pmsRatePlanId: string;
    pmsUnitGroupId: string;
    pmsUpsellRuleId: string;
    calculatedUpsellInformation: {
      __typename?: "CalculatedUpsellInformation";
      averagePerNightAmountDifference: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
      };
      totalAmountDifference: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
      };
    };
  }>;
};

export type GetAdditionalServicesAvailabilityQueryVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
}>;

export type GetAdditionalServicesAvailabilityQuery = {
  __typename?: "Query";
  BackofficeGetAdditionalServicesAvailability?: Array<{
    __typename?: "AdditionalServiceAvailabilityDTO";
    pmsServiceId: string;
    available: boolean;
    magicServiceCodeEnum?: string | null;
    granularity: Granularity;
    defaultDatePickerSelection?: DefaultDatePickerSelection | null;
    defaultTargetAudience?: DefaultTargetAudience | null;
    name?: string | null;
    canBookMultiple?: boolean | null;
    maximumHours?: number | null;
    bookedDates: Array<{ __typename?: "DateAndCount"; date: any; count?: number | null }>;
    bookableDates: Array<{ __typename?: "DateAndCount"; date: any; count?: number | null }>;
    basePrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    };
  } | null> | null;
};

export type GetAllPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPropertiesQuery = {
  __typename?: "Query";
  GetAllProperties?: Array<{
    __typename?: "Property";
    pmsId: string;
    name?: string | null;
    defaultCurrency?: string | null;
    timezone?: string | null;
  } | null> | null;
};

export type GetAssignableUnitsQueryVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
  pmsUnitGroupId: Scalars["String"]["input"];
}>;

export type GetAssignableUnitsQuery = {
  __typename?: "Query";
  GetAssignableUnits: Array<{
    __typename?: "Unit";
    name?: string | null;
    state?: UnitState | null;
    active?: boolean | null;
    occupied?: boolean | null;
    pmsId?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    pmsUnitGroupIds?: Array<string> | null;
  }>;
};

export type ConditionCountsQueryVariables = Exact<{
  filter: BackofficeConditionCountsFilter;
}>;

export type ConditionCountsQuery = { __typename?: "Query"; ConditionCounts?: any | null };

export type GetMembershipTypesQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetMembershipTypesQuery = {
  __typename?: "Query";
  GetMembershipTypes: Array<{
    __typename?: "MembershipType";
    code: string;
    name: string;
    membershipClass: MemberShipClassEnum;
    pmsId: string;
  }>;
};

export type GetOrderItemAdjustmentReasonCodesQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetOrderItemAdjustmentReasonCodesQuery = {
  __typename?: "Query";
  GetOrderItemAdjustmentReasonCodes: Array<{
    __typename?: "OrderItemAdjustmentReasonCode";
    pmsId: string;
    name: string;
  }>;
};

export type GetOrderItemTransactionTypesQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
}>;

export type GetOrderItemTransactionTypesQuery = {
  __typename?: "Query";
  GetOrderItemTransactionTypes: Array<{
    __typename?: "OrderItemTransactionType";
    pmsId?: string | null;
    name?: string | null;
    transactionTypeCode?: string | null;
    defaultPrice?: {
      __typename?: "GrossPrice";
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      currency?: string | null;
    } | null;
  }>;
};

export type GetOrderItemsPageQueryVariables = Exact<{
  filter: OrderItemsPageFilter;
}>;

export type GetOrderItemsPageQuery = {
  __typename?: "Query";
  GetOrderItemsPage: {
    __typename?: "OrderItemsPage";
    totalElements: number;
    content: Array<{
      __typename?: "OrderItem";
      id: number;
      pmsId: string;
      pmsReservationId?: string | null;
      displayableReservationId?: string | null;
      pmsAccountId?: string | null;
      pmsFolioId?: string | null;
      name?: string | null;
      pmsServiceId?: string | null;
      start?: any | null;
      end?: any | null;
      accountingState: AccountingState;
      prepaid?: boolean | null;
      hide?: boolean | null;
      price: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          amountInCents?: number | null;
          code?: string | null;
        } | null> | null;
      };
    }>;
  };
};

export type GetPaymentMethodsQueryVariables = Exact<{
  pmsPropertyId: Scalars["String"]["input"];
  shopperReference: Scalars["String"]["input"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  GetPaymentMethods: Array<
    | {
        __typename: "CardPaymentMethod";
        hashCode: string;
        type: PaymentMethodType;
        paymentMethods?: Array<{
          __typename?: "PossiblePaymentMethod";
          brand?: string | null;
          brands?: Array<string | null> | null;
          configuration?: any | null;
          fundingSource?: string | null;
          group?: any | null;
          inputDetails?: string | null;
          issuers?: string | null;
          name?: string | null;
          type?: string | null;
        } | null> | null;
      }
    | { __typename: "CashPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | { __typename: "MewsPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | { __typename: "PayAtCheckinPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | { __typename: "PayAtCheckoutPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | { __typename: "PaymentLinkPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | { __typename: "PreAuthorizationPaymentMethod"; hashCode: string; type: PaymentMethodType }
    | {
        __typename: "StoredPaymentMethod";
        hashCode: string;
        pointOfSales: boolean;
        shopperReference?: string | null;
        type: PaymentMethodType;
        config?: {
          __typename?: "StoredPaymentConfig";
          brand?: string | null;
          expiryMonth?: string | null;
          expiryYear?: string | null;
          holderName?: string | null;
          iban?: string | null;
          id?: string | null;
          lastFour?: string | null;
          name?: string | null;
          ownerName?: string | null;
          shopperEmail?: string | null;
          supportedShopperInteractions?: Array<string | null> | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename: "TerminalPaymentMethod";
        hashCode: string;
        type: PaymentMethodType;
        availableTerminals?: Array<{
          __typename?: "AvailableTerminal";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
  >;
};

export type GetPreferencesQueryVariables = Exact<{
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type GetPreferencesQuery = {
  __typename?: "Query";
  GetPreferences: Array<{
    __typename?: "PreferenceCategory";
    pmsId: string;
    name: string;
    description?: string | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
  }>;
};

export type GetProfileNoteCategoriesQueryVariables = Exact<{
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type GetProfileNoteCategoriesQuery = {
  __typename?: "Query";
  GetProfileNoteCategories: Array<{
    __typename?: "ProfileNoteCategory";
    pmsId: string;
    name?: string | null;
  }>;
};

export type OverviewDetailsQueryVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
}>;

export type OverviewDetailsQuery = {
  __typename?: "Query";
  OverviewDetails?: {
    __typename?: "BackofficeReservationOverviewDetail";
    adultsCount: number;
    allowedActions: Array<ReservationAction>;
    arrival: any;
    maxCompanions: number;
    bookedPmsUnitGroupId?: string | null;
    bookingChannel?: BookingChannelFilterValue | null;
    bookingMagicLink: string;
    checkinTime?: any | null;
    checkoutTime?: any | null;
    estimatedArrivalTime?: any | null;
    estimatedDepartureTime?: any | null;
    checkoutOnAr?: boolean | null;
    childrenAges?: Array<number> | null;
    childrenCount: number;
    completed?: boolean | null;
    contractualCheckInTime?: any | null;
    createdAt: any;
    departure: any;
    idCheckStatus: IdCheckStatus;
    lastConfirmedPage?: GuestFlowCheckPoint | null;
    lastConfirmedCheckoutPage?: GuestJourneyCheckoutFlowCheckpoint | null;
    magicId: string;
    magicLink: string;
    magicToken: string;
    pmsOriginalPropertyId: string;
    pmsPropertyId: string;
    pmsReservationId: string;
    primaryGuestSameAsBooker?: boolean | null;
    reservationCondition?: ReservationCondition | null;
    reservationConditionReasons?: any | null;
    reservationIsPaid?: boolean | null;
    reservationStatus?: ReservationStatus | null;
    unitCleanOnCheckin?: boolean | null;
    extraPmsSimpleProperties?: any | null;
    checkedInBy?: ReservationCheckInOutAuthor | null;
    checkedOutBy?: ReservationCheckInOutAuthor | null;
    canCheckout: boolean;
    preferredCleaningDays?: Array<DayOfWeek> | null;
    folios: Array<{
      __typename?: "Folio";
      closed?: any | null;
      created?: any | null;
      debitorType: DebitorType;
      metadata: any;
      number?: string | null;
      id?: number | null;
      origin?: Origin | null;
      pmsCompanyId?: string | null;
      pmsCustomerId?: string | null;
      pmsId?: string | null;
      prepaymentType: PrepaymentType;
      propertyId?: number | null;
      updated?: any | null;
      orderItems: Array<{
        __typename?: "OrderItem";
        name?: string | null;
        pmsServiceId?: string | null;
        prepaid?: boolean | null;
        pmsId: string;
        quantity?: number | null;
        created?: any | null;
        note?: string | null;
        subName?: string | null;
        reference?: string | null;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
          taxes?: Array<{
            __typename?: "Tax";
            amount?: any | null;
            amountInCents?: number | null;
            code?: string | null;
          } | null> | null;
        };
      }>;
      payments: Array<{
        __typename?: "Payment";
        pmsId: string;
        created?: any | null;
        refundable: boolean;
        refundStatus?: RefundStatus | null;
        type: PaymentType;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPriceInCents?: number | null;
        };
      }>;
      totalAllowedPayment?: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      } | null;
      pendingPayments: Array<{
        __typename?: "PendingPayment";
        id: string;
        created?: any | null;
        price: {
          __typename?: "Price";
          currency?: string | null;
          grossPriceInCents?: number | null;
        };
      }>;
    }>;
    groupedRateBreakdown?: {
      __typename?: "GroupedRateBreakdown";
      rateSpliceTypeTuples?: Array<{
        __typename?: "RateSpliceTypeEnumTuple";
        type?: RateSpliceTypeEnumContract | null;
        pmsIdToRateSplicesTuples?: Array<{
          __typename?: "PmsIdToRateSplicesTuple";
          pmsId?: string | null;
          rateSplices?: Array<{
            __typename?: "RateSplice";
            displayName?: string | null;
            quantity?: number | null;
            type?: RateSpliceTypeEnumContract | null;
            grossPrice?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
          }> | null;
        }> | null;
      }> | null;
    } | null;
    accessibleDoors: Array<{
      __typename?: "BackofficeAccessibleDoor";
      id: string;
      isGeneral?: boolean | null;
      lockAccessibility?: DoorLockAccessibility | null;
      pin?: string | null;
      privacyMode?: boolean | null;
      sortPriority?: number | null;
      title?: string | null;
    }>;
    keyCards?: Array<{
      __typename?: "BackofficeKeyCard";
      id: string;
      createdAt?: any | null;
      isCopy?: boolean | null;
      requestor?: {
        __typename?: "DoorAccessRequestor";
        principal?: string | null;
        role?: string | null;
        travelBuddyGuestId?: string | null;
      } | null;
    }> | null;
    balance?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
    } | null;
    booker?: {
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      emergencyEvacuationHelpNotes?: string | null;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      licensePlate?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        blacklisted: boolean;
        licensePlate?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    } | null;
    files: Array<{
      __typename?: "BackofficeMagicFileDescriptor";
      contentType: string;
      fileName: string;
      metaData?: any | null;
    }>;
    notes?: {
      __typename?: "BackofficeNote";
      previousVisits?: number | null;
      guestComment?: Array<string> | null;
      bookerComment?: Array<string> | null;
      bookingComment?: Array<string> | null;
      rateBreakdownComment?: Array<string> | null;
      beddingComment?: Array<string> | null;
      comment?: Array<string> | null;
    } | null;
    primaryGuest?: {
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      emergencyEvacuationHelpNotes?: string | null;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      licensePlate?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        blacklisted: boolean;
        licensePlate?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    } | null;
    sentNotifications: Array<{
      __typename?: "BackofficeSentNotification";
      bookingId?: string | null;
      channel?: CommunicationChannel | null;
      contentLink?: string | null;
      conversationId?: string | null;
      createdAt?: any | null;
      id?: string | null;
      language?: CommunicationLanguage | null;
      magicId?: string | null;
      recipient?: string | null;
      reservationId?: string | null;
      status?: string | null;
      template?: NotificationType | null;
      lastUpdateTimestamp?: any | null;
      travelBuddyId?: string | null;
    }>;
    services: Array<{
      __typename?: "BackofficeService";
      quantity?: number | null;
      name?: string | null;
      code?: ServiceCodeEnum | null;
      dates: Array<any>;
      price?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          amountInCents?: number | null;
          code?: string | null;
        } | null> | null;
      } | null;
    }>;
    travelBuddies: Array<{
      __typename?: "Person";
      id: number;
      pmsId: string;
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
      email?: string | null;
      gender?: Gender | null;
      birthdate?: any | null;
      age?: number | null;
      isMinor?: boolean | null;
      preferredCommunicationChannel?: CommunicationChannel | null;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      emergencyEvacuationHelpNeeded: boolean;
      emergencyEvacuationHelpNotes?: string | null;
      enrolledInLoyaltyProgram: boolean;
      identificationDocumentNumber?: string | null;
      identificationDocumentType?: string | null;
      userProfileId?: number | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      licensePlate?: string | null;
      publicTransportationTickets?: Array<{
        __typename?: "PublicTransportationTicket";
        code?: string | null;
        link?: string | null;
        name?: string | null;
        valid?: boolean | null;
        validUntil?: any | null;
        ownerMagicId?: string | null;
      } | null> | null;
      userProfile?: {
        __typename?: "UserProfile";
        isRecurring: boolean;
        userAccountUuid?: string | null;
        blacklisted: boolean;
        licensePlate?: string | null;
        preferences: Array<{
          __typename?: "Preference";
          pmsId: string;
          pmsPreferenceCategoryId: string;
          name: string;
          description?: string | null;
          pmsPropertyId?: string | null;
        }>;
        notes: Array<{
          __typename?: "ProfileNote";
          pmsId: string;
          title?: string | null;
          text?: string | null;
          pmsPropertyId?: string | null;
          pmsProfileNoteCategoryId?: string | null;
          category?: {
            __typename?: "ProfileNoteCategory";
            pmsId: string;
            name?: string | null;
          } | null;
        }>;
      } | null;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        city?: string | null;
        countryCode?: string | null;
        postalCode?: string | null;
        region?: string | null;
      } | null;
      membershipAccounts: Array<{
        __typename?: "MembershipAccount";
        membershipTypeCode?: string | null;
        membershipId?: string | null;
        membershipClass?: MemberShipClassEnum | null;
        pmsId?: string | null;
        name?: string | null;
      }>;
    }>;
    unit?: {
      __typename?: "BackofficeUnit";
      pmsUnitId?: string | null;
      pmsUnitGroupId?: string | null;
      name?: string | null;
      privacyMode?: boolean | null;
      privacyModeLastChangeTimestamp?: any | null;
      condition?: UnitState | null;
    } | null;
    restaurantReservations?: Array<{
      __typename?: "RestaurantReservation";
      foodAndBeverageReservationId: string;
      foodAndBeveragePropertyId: string;
      foodAndBeveragePropertyName: string;
      reservationDateTime: any;
      status: RestaurantReservationStatus;
      notes?: string | null;
      expectedNumberOfGuests?: number | null;
      actualNumberOfGuests?: number | null;
      tableNumbers?: Array<string | null> | null;
      createdAt: any;
      updatedAt: any;
    } | null> | null;
    reservationUpsellInformation?: {
      __typename?: "ReservationUpsellInformation";
      originalPmsUnitGroupId?: string | null;
    } | null;
  } | null;
};

export type OverviewTableQueryVariables = Exact<{
  filter: BackofficeReservationOverviewFilter;
}>;

export type OverviewTableQuery = {
  __typename?: "Query";
  OverviewTable?: {
    __typename?: "BackofficeReservationOverviewTablePage";
    totalElements: number;
    content: Array<{
      __typename?: "BackofficeReservationOverviewDetail";
      adultsCount: number;
      allowedActions: Array<ReservationAction>;
      arrival: any;
      bookedPmsUnitGroupId?: string | null;
      bookingChannel?: BookingChannelFilterValue | null;
      bookingMagicLink: string;
      estimatedArrivalTime?: any | null;
      estimatedDepartureTime?: any | null;
      checkinTime?: any | null;
      checkoutTime?: any | null;
      checkoutOnAr?: boolean | null;
      childrenAges?: Array<number> | null;
      childrenCount: number;
      completed?: boolean | null;
      contractualCheckInTime?: any | null;
      createdAt: any;
      departure: any;
      idCheckStatus: IdCheckStatus;
      lastConfirmedPage?: GuestFlowCheckPoint | null;
      magicId: string;
      magicLink: string;
      magicToken: string;
      pmsOriginalPropertyId: string;
      pmsPropertyId: string;
      pmsReservationId: string;
      primaryGuestSameAsBooker?: boolean | null;
      reservationCondition?: ReservationCondition | null;
      reservationConditionReasons?: any | null;
      reservationIsPaid?: boolean | null;
      reservationStatus?: ReservationStatus | null;
      unitCleanOnCheckin?: boolean | null;
      booker?: {
        __typename?: "Person";
        id: number;
        pmsId: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
        gender?: Gender | null;
        birthdate?: any | null;
        age?: number | null;
        isMinor?: boolean | null;
        preferredCommunicationChannel?: CommunicationChannel | null;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        emergencyEvacuationHelpNeeded: boolean;
        emergencyEvacuationHelpNotes?: string | null;
        enrolledInLoyaltyProgram: boolean;
        identificationDocumentNumber?: string | null;
        identificationDocumentType?: string | null;
        userProfileId?: number | null;
        preferredLanguage?: string | null;
        nationality?: string | null;
        vipCode?: string | null;
        licensePlate?: string | null;
        publicTransportationTickets?: Array<{
          __typename?: "PublicTransportationTicket";
          code?: string | null;
          link?: string | null;
          name?: string | null;
          valid?: boolean | null;
          validUntil?: any | null;
          ownerMagicId?: string | null;
        } | null> | null;
        userProfile?: {
          __typename?: "UserProfile";
          isRecurring: boolean;
          userAccountUuid?: string | null;
          blacklisted: boolean;
          licensePlate?: string | null;
          preferences: Array<{
            __typename?: "Preference";
            pmsId: string;
            pmsPreferenceCategoryId: string;
            name: string;
            description?: string | null;
            pmsPropertyId?: string | null;
          }>;
          notes: Array<{
            __typename?: "ProfileNote";
            pmsId: string;
            title?: string | null;
            text?: string | null;
            pmsPropertyId?: string | null;
            pmsProfileNoteCategoryId?: string | null;
            category?: {
              __typename?: "ProfileNoteCategory";
              pmsId: string;
              name?: string | null;
            } | null;
          }>;
        } | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          countryCode?: string | null;
          postalCode?: string | null;
          region?: string | null;
        } | null;
        membershipAccounts: Array<{
          __typename?: "MembershipAccount";
          membershipTypeCode?: string | null;
          membershipId?: string | null;
          membershipClass?: MemberShipClassEnum | null;
          pmsId?: string | null;
          name?: string | null;
        }>;
      } | null;
      files: Array<{
        __typename?: "BackofficeMagicFileDescriptor";
        contentType: string;
        fileName: string;
        metaData?: any | null;
      }>;
      notes?: {
        __typename?: "BackofficeNote";
        previousVisits?: number | null;
        guestComment?: Array<string> | null;
        bookerComment?: Array<string> | null;
        bookingComment?: Array<string> | null;
        rateBreakdownComment?: Array<string> | null;
        beddingComment?: Array<string> | null;
        comment?: Array<string> | null;
      } | null;
      primaryGuest?: {
        __typename?: "Person";
        id: number;
        pmsId: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
        email?: string | null;
        gender?: Gender | null;
        birthdate?: any | null;
        age?: number | null;
        isMinor?: boolean | null;
        preferredCommunicationChannel?: CommunicationChannel | null;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        emergencyEvacuationHelpNeeded: boolean;
        emergencyEvacuationHelpNotes?: string | null;
        enrolledInLoyaltyProgram: boolean;
        identificationDocumentNumber?: string | null;
        identificationDocumentType?: string | null;
        userProfileId?: number | null;
        preferredLanguage?: string | null;
        nationality?: string | null;
        vipCode?: string | null;
        licensePlate?: string | null;
        publicTransportationTickets?: Array<{
          __typename?: "PublicTransportationTicket";
          code?: string | null;
          link?: string | null;
          name?: string | null;
          valid?: boolean | null;
          validUntil?: any | null;
          ownerMagicId?: string | null;
        } | null> | null;
        userProfile?: {
          __typename?: "UserProfile";
          isRecurring: boolean;
          userAccountUuid?: string | null;
          blacklisted: boolean;
          licensePlate?: string | null;
          preferences: Array<{
            __typename?: "Preference";
            pmsId: string;
            pmsPreferenceCategoryId: string;
            name: string;
            description?: string | null;
            pmsPropertyId?: string | null;
          }>;
          notes: Array<{
            __typename?: "ProfileNote";
            pmsId: string;
            title?: string | null;
            text?: string | null;
            pmsPropertyId?: string | null;
            pmsProfileNoteCategoryId?: string | null;
            category?: {
              __typename?: "ProfileNoteCategory";
              pmsId: string;
              name?: string | null;
            } | null;
          }>;
        } | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          city?: string | null;
          countryCode?: string | null;
          postalCode?: string | null;
          region?: string | null;
        } | null;
        membershipAccounts: Array<{
          __typename?: "MembershipAccount";
          membershipTypeCode?: string | null;
          membershipId?: string | null;
          membershipClass?: MemberShipClassEnum | null;
          pmsId?: string | null;
          name?: string | null;
        }>;
      } | null;
      unit?: {
        __typename?: "BackofficeUnit";
        pmsUnitId?: string | null;
        pmsUnitGroupId?: string | null;
        name?: string | null;
        privacyMode?: boolean | null;
        privacyModeLastChangeTimestamp?: any | null;
        condition?: UnitState | null;
      } | null;
    }>;
  } | null;
};

export type GetReservationsPageQueryVariables = Exact<{
  filter: ReservationsPageFilter;
}>;

export type GetReservationsPageQuery = {
  __typename?: "Query";
  GetReservationsPage: {
    __typename?: "ReservationsPage";
    totalElements: number;
    content: Array<{
      __typename?: "Reservation";
      displayId: string;
      id: number;
      arrival: any;
      departure: any;
      magicId: string;
      status: ReservationStatus;
      pmsId: string;
      channel?: string | null;
      unit?: { __typename?: "Unit"; name?: string | null } | null;
      property: { __typename?: "Property"; name?: string | null; timezone?: string | null };
      primaryGuest?: {
        __typename?: "Person";
        userProfileId?: number | null;
        firstName?: string | null;
        lastName?: string | null;
      } | null;
    }>;
  };
};

export type GetSupportSyncStatesQueryVariables = Exact<{
  filter?: InputMaybe<PropertyFilter>;
}>;

export type GetSupportSyncStatesQuery = {
  __typename?: "Query";
  GetProperty?: {
    __typename?: "Property";
    supportedSyncStates?: Array<DoorAccessState | null> | null;
    availableDoors?: Array<{
      __typename?: "AvailableDoorDto";
      unitId?: string | null;
      id?: string | null;
      title?: string | null;
      lockAccessibility?: DoorLockAccessibility | null;
      batteryLevel?: BatteryLevel | null;
      privacyMode?: boolean | null;
      privacyModeLastChangeTimestamp?: any | null;
      pin?: string | null;
      sortPriority?: number | null;
    } | null> | null;
  } | null;
};

export type GetToBeCleanedTodayQueryVariables = Exact<{
  propertyPmsId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetToBeCleanedTodayQuery = {
  __typename?: "Query";
  GetProperty?: {
    __typename?: "Property";
    pmsId: string;
    toBeCleanedToday?: Array<{
      __typename?: "CleaningDTO";
      pmsUnitId: string;
      arrivalPmsReservationId?: string | null;
      departurePmsReservationId?: string | null;
      servicePmsReservationId?: string | null;
      pmsUnitGroupIds?: Array<string | null> | null;
      name: string;
      occupied: boolean;
      condition: UnitState;
      priority: Priority;
      floorNumber?: number | null;
      adults?: number | null;
      children?: number | null;
      arrival?: any | null;
      contractualCheckInTime?: any | null;
      departure?: any | null;
      expectedDeparture?: any | null;
      services?: Array<{
        __typename?: "BackofficeService";
        name?: string | null;
        code?: ServiceCodeEnum | null;
        quantity?: number | null;
        price?: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetUnitGroupsQueryVariables = Exact<{
  filter?: InputMaybe<UnitGroupFilter>;
}>;

export type GetUnitGroupsQuery = {
  __typename?: "Query";
  GetUnitGroups?: Array<{
    __typename?: "UnitGroup";
    pmsId?: string | null;
    name?: string | null;
    property?: { __typename?: "Property"; pmsId: string } | null;
  } | null> | null;
};

export type GetUnitsQueryVariables = Exact<{
  filter?: InputMaybe<UnitFilter>;
}>;

export type GetUnitsQuery = {
  __typename?: "Query";
  GetUnits?: Array<{
    __typename?: "Unit";
    pmsId?: string | null;
    name?: string | null;
    pmsUnitGroupIds?: Array<string> | null;
    state?: UnitState | null;
    occupied?: boolean | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    batteryLevel?: BatteryLevel | null;
    property?: { __typename?: "Property"; pmsId: string } | null;
  } | null> | null;
};

export type GetUserProfileByIdQueryVariables = Exact<{
  userProfileId: Scalars["Int"]["input"];
}>;

export type GetUserProfileByIdQuery = {
  __typename?: "Query";
  GetUserProfileById?: {
    __typename?: "UserProfile";
    id: number;
    firstName?: string | null;
    lastName?: string | null;
    title?: string | null;
    userAccountUuid?: string | null;
    guestIdentityId?: number | null;
    phone?: string | null;
    email?: string | null;
    gender?: Gender | null;
    birthdate?: any | null;
    preferredCommunicationChannel?: CommunicationChannel | null;
    preferredLanguage?: string | null;
    nationality?: string | null;
    vipCode?: string | null;
    blacklisted: boolean;
    licensePlate?: string | null;
    address?: {
      __typename?: "Address";
      addressLine1?: string | null;
      addressLine2?: string | null;
      postalCode?: string | null;
      city?: string | null;
      countryCode?: string | null;
      region?: string | null;
    } | null;
    preferences: Array<{
      __typename?: "Preference";
      pmsId: string;
      pmsPreferenceCategoryId: string;
      name: string;
      description?: string | null;
      pmsPropertyId?: string | null;
    }>;
    notes: Array<{
      __typename?: "ProfileNote";
      pmsId: string;
      title?: string | null;
      text?: string | null;
      pmsPropertyId?: string | null;
      pmsProfileNoteCategoryId?: string | null;
      category?: { __typename?: "ProfileNoteCategory"; pmsId: string; name?: string | null } | null;
    }>;
  } | null;
};

export type GetUserProfilesQueryVariables = Exact<{
  filter: GenericFilter;
}>;

export type GetUserProfilesQuery = {
  __typename?: "Query";
  GetUserProfiles: {
    __typename?: "UserProfilesPage";
    totalElements: number;
    content: Array<{
      __typename?: "UserProfile";
      id: number;
      numberOfReservations: number;
      isRecurring: boolean;
      guestIdentityId?: number | null;
      title?: string | null;
      firstName?: string | null;
      lastName?: string | null;
      userAccountUuid?: string | null;
      preferredLanguage?: string | null;
      nationality?: string | null;
      vipCode?: string | null;
      phone?: string | null;
      email?: string | null;
      blacklisted: boolean;
      preferredCommunicationChannel?: CommunicationChannel | null;
      preferences: Array<{
        __typename?: "Preference";
        pmsId: string;
        pmsPreferenceCategoryId: string;
        name: string;
        description?: string | null;
        pmsPropertyId?: string | null;
      }>;
    }>;
  };
};

export type StreamSecondScreenEventsSubscriptionVariables = Exact<{
  pmsReservationId: Scalars["String"]["input"];
}>;

export type StreamSecondScreenEventsSubscription = {
  __typename?: "Subscription";
  StreamBackofficeSecondScreenEvents?:
    | {
        __typename: "BackofficePing";
        pmsReservationId: string;
        tenantName: string;
        type: BackofficeOverviewDetailEventType;
      }
    | {
        __typename: "SecondScreenBackofficeEvent";
        pmsReservationId: string;
        tenantName: string;
        type: BackofficeOverviewDetailEventType;
        payload:
          | {
              __typename: "BillingConfirmed";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "Canceled";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "Signed";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyAndSign";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyAndSignAck";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
              payload: {
                __typename: "VerifyAndSignAckPayload";
                reason?: string | null;
                accepted: boolean;
              };
            }
          | {
              __typename: "VerifyBill";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
            }
          | {
              __typename: "VerifyBillAck";
              deviceId: string;
              type: SecondScreenEventType;
              tenantName: string;
              payload: {
                __typename: "VerifyBillAckPayload";
                reason?: string | null;
                accepted: boolean;
              };
            };
      }
    | null;
};

export type StreamMagicObjectSubscriptionVariables = Exact<{
  magicId: Scalars["String"]["input"];
  magicToken: Scalars["String"]["input"];
}>;

export type StreamMagicObjectSubscription = {
  __typename?: "Subscription";
  StreamMagicObject?:
    | { __typename: "Booking" }
    | { __typename: "Reservation"; pmsId: string }
    | { __typename: "TravelBuddy" }
    | null;
};

export type TerminalPaymentSubscriptionVariables = Exact<{
  paymentRequest?: InputMaybe<TerminalPaymentRequest>;
}>;

export type TerminalPaymentSubscription = {
  __typename?: "Subscription";
  TerminalPayment?: {
    __typename?: "TerminalResponse";
    success?: boolean | null;
    errorCode?: string | null;
    errorReason?: string | null;
    serviceId?: string | null;
    transactionId?: string | null;
  } | null;
};
