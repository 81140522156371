import { formatTime, ParagraphSmall, TenantContext } from "@likemagic-tech/sv-magic-library";
import { FC, useContext, useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { Priority, UnitToBeCleaned } from "src/domain/UnitToBeCleaned";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Divider, Grid, styled } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import { getUnitType } from "src/utils/tenant-unit-type";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { computeDoNotDisturbLabel } from "../../utils/reservation-utils";
import { DisplayServiceIcon } from "../../features/reservations/reservation-details/reservation-details-tabs/services/display-service-icon";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface DisplayUnitsLabelsProps {
  unit: UnitToBeCleaned;
}
const DoNotDisturbStyledGrid = styled(Grid)(({ theme }) => ({
  marginRight: theme.spacing(1),
  padding: theme.spacing(0.5),
  paddingBottom: 0, // some weird 0.5 * theme.padding by default on the bottom
  border: 1,
  borderRadius: theme.spacing(0.5),
  borderColor: theme.palette.error.light,
  backgroundColor: theme.palette.error.light,
  width: "fit-content"
}));

export const DisplayUnitsLabels: FC<DisplayUnitsLabelsProps> = ({ unit }) => {
  const theme = useTheme();
  const { theme: selectedTheme } = useContext(TenantContext);
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  // Plurals for i18n don't work in our version of TS https://github.com/i18next/i18next/issues/1683
  const displayOccupantsLabel = useMemo(() => {
    return `${unit.adults} ${
      /*@ts-ignore*/ t("labels__adult", {
        count: unit.adults
      })
    }${
      unit.children
        ? ", " +
          unit.children +
          " " +
          /*@ts-ignore*/
          t("labels__kid", { count: unit.children })
        : ""
    }`;
  }, [t, unit.adults, unit.children]);

  return (
    <Grid container paddingBottom={1} direction="column">
      {unit.privacyMode && (
        <Grid container display="flex" alignItems="center">
          <DoNotDisturbStyledGrid item>
            <DoNotDisturbOnOutlinedIcon sx={{ color: "error.main" }} fontSize={"small"} />
          </DoNotDisturbStyledGrid>
          <Grid item>
            {/* Backend value for privacyModeLastChangeTimestamp is not consistent on the BE side so we don't show it in the Units and Housekeeping (Dirty Units) pages, only in the reservation table*/}
            {/* Once it's fixed, we can just pass the value in the computeDoNotDisturbLabel(t, unit.privacyModeLastChangeTimestamp)*/}
            <ParagraphSmall color="error">{computeDoNotDisturbLabel(t)}</ParagraphSmall>
          </Grid>
        </Grid>
      )}
      {unit?.departure && (
        <>
          <ParagraphSmall color="text.secondary" gutterBottom>
            {t("labels__unit_empty_since", {
              unitType: getUnitType(selectedTheme),
              departure:
                formatTime(unit.departure, language, selectedProperty?.details.timeZone) ?? ""
            })}
          </ParagraphSmall>
          <Divider />
        </>
      )}
      {unit?.expectedDeparture && (
        <>
          <ParagraphSmall color="text.secondary" gutterBottom>
            {t("labels__unit_empty_at", {
              unitType: getUnitType(selectedTheme),
              expectedDeparture:
                formatTime(unit.expectedDeparture, language, selectedProperty?.details.timeZone) ??
                ""
            })}
          </ParagraphSmall>
          <Divider />
        </>
      )}
      {unit?.arrival && (
        <ParagraphSmall color="text.secondary" paddingTop={0.5}>
          {t("labels__unit_occupied_since", {
            unitType: getUnitType(selectedTheme),
            arrival: formatTime(unit.arrival, language, selectedProperty?.details.timeZone) ?? ""
          })}
        </ParagraphSmall>
      )}
      {unit?.contractualCheckInTime && (
        <ParagraphSmall color="text.secondary" paddingTop={0.5}>
          {t("labels__unit_should_be_ready_by", {
            unitType: getUnitType(selectedTheme),
            contractualCheckInTime:
              formatTime(
                unit.contractualCheckInTime,
                language,
                selectedProperty?.details.timeZone
              ) ?? ""
          })}
        </ParagraphSmall>
      )}
      {unit.priority === Priority.MEDIUM && (
        <ParagraphSmall color="text.secondary" paddingTop={0.5}>
          {t("labels__unit_scheduled_cleaning")}
        </ParagraphSmall>
      )}
      {unit?.adults && (
        <Grid container direction="row" alignItems="center">
          {unit.priority !== Priority.MEDIUM && (
            <ParagraphSmall color="text.secondary" paddingRight={0.5}>
              {t("labels__housekeeping_prepare_for")}
            </ParagraphSmall>
          )}
          <GroupIcon sx={{ color: theme.palette.text.secondary }} fontSize="small" />
          <ParagraphSmall color="text.secondary" paddingLeft={0.5}>
            {displayOccupantsLabel}
          </ParagraphSmall>
        </Grid>
      )}
      {unit?.services && unit?.services.length > 0 && (
        <>
          <ParagraphSmall color="text.secondary" paddingRight={0.5}>
            {t("labels__housekeeping_service")}
          </ParagraphSmall>
          <ParagraphSmall color="text.secondary" paddingLeft={0.5}>
            {unit.services.map((service) => (
              <li key={service.name}>
                <DisplayServiceIcon code={service?.code} fontSize={"small"} />
                <span> {service.quantity}</span> <span> {service.name}</span>
              </li>
            ))}
          </ParagraphSmall>
        </>
      )}
    </Grid>
  );
};
