import React, { FC, useCallback, useEffect, useState } from "react";
import { Badge, Drawer, Grid, useTheme } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Heading4 } from "@likemagic-tech/sv-magic-library";
import { useSinglePrismicDocument } from "@prismicio/react";
import { ReleaseNotesCard } from "src/features/release-notes/release-notes-card";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { getI18nSelectedLanguage } from "src/utils/language";

export enum LanguageType {
  "en" = "en-us",
  "de" = "de-ch",
  "it" = "it-ch",
  "fr" = "fr-ch"
}

export type ReleaseNotes = {
  date: string;
  title: string;
  description: string;
};

const RELEASE_NOTES_LOCAL_STORAGE_KEY = "releaseNotes";

export const ReleaseNotePopover: FC = () => {
  const theme = useTheme();
  const { t } = useTranslationWrapper();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNotes[]>([]);
  const [newFeaturesCounter, setNewFeaturesCounter] = useState(0);
  const [newFeaturesChecked, setNewFeaturesChecked] = useState(false);
  const navigate = useNavigateWithPropertyId();
  const language = getI18nSelectedLanguage();

  const release_notes = useSinglePrismicDocument("release_notes", {
    lang: LanguageType[language as keyof typeof LanguageType]
  });

  /**
   * Compares the number of releases notes in the localStorage with the
   * lenght of the release notes array to check if there are new features
   * @param index: lenght of the releaseNotes array
   */
  const checkForNewFeatures = useCallback((amount: number) => {
    let newNotes: Object;
    const notes = JSON.parse(localStorage.getItem(RELEASE_NOTES_LOCAL_STORAGE_KEY) ?? "{}");
    if (notes) {
      if (notes.counter < amount) {
        newNotes = {
          counter: amount,
          checked: false,
          notRead: amount - notes.counter
        };
        localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
        setNewFeaturesChecked(false);
        setNewFeaturesCounter(amount - notes.counter);
      } else {
        if (!notes.checked) {
          setNewFeaturesChecked(false);
          setNewFeaturesCounter(notes.notRead);
        } else {
          setNewFeaturesChecked(false);
        }
      }
    } else {
      newNotes = {
        counter: amount,
        checked: false,
        notRead: amount
      };
      localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
      setNewFeaturesChecked(false);
      setNewFeaturesCounter(amount);
    }
  }, []);

  /**
   * Handles actions when the Release Notes icon is pressed
   */
  const handleIconClick = () => {
    const notes = JSON.parse(localStorage.getItem(RELEASE_NOTES_LOCAL_STORAGE_KEY) ?? "{}");
    setOpenDrawer(true);
    setNewFeaturesChecked(true);
    const newNotes = {
      counter: +notes.counter,
      checked: true,
      notRead: 0
    };
    localStorage.setItem(RELEASE_NOTES_LOCAL_STORAGE_KEY, JSON.stringify(newNotes));
  };

  /**
   * Transforms the data receive by prismic and stores it in an array
   */
  const formatPrismicDataToBeUsed = useCallback(
    (data: Array<any>) => {
      const arrayOfReleaseNotes = data[0]?.data.release_notes; //need to test if it's always this position
      if (arrayOfReleaseNotes) {
        checkForNewFeatures(arrayOfReleaseNotes.length);
        // used so we don't mutate the releaseNotes array
        let helperArray: ReleaseNotes[] = [];
        for (const arr of arrayOfReleaseNotes.reverse()) {
          helperArray = [
            ...helperArray,
            {
              date: arr.date,
              title: arr.title,
              description: arr.description
            }
          ];
        }
        setReleaseNotes(helperArray);
      }
    },
    [checkForNewFeatures]
  );

  useEffect(() => {
    formatPrismicDataToBeUsed(release_notes);
  }, [release_notes, formatPrismicDataToBeUsed]);

  return (
    <React.Fragment>
      <Badge
        color="secondary"
        badgeContent={newFeaturesCounter}
        invisible={newFeaturesChecked}
        max={9}
      >
        <CampaignIcon
          fontSize="large"
          onClick={handleIconClick}
          sx={{ cursor: "pointer", color: theme.palette.background.paper }}
        />
      </Badge>
      <Drawer
        anchor="right"
        SlideProps={{ appear: false }}
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
        sx={{ zIndex: 1301 }}
        PaperProps={{
          sx: {
            maxWidth: theme.spacing(45)
          }
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{
            backgroundColor: theme.palette.primary.main,
            minHeight: theme.spacing(8)
          }}
        >
          <Grid item>
            <Heading4 sx={{ color: grey[200], margin: theme.spacing(2) }}>
              {t("release__what__new")}
            </Heading4>
          </Grid>
          <Grid item>
            <OpenInNewIcon
              fontSize="medium"
              sx={{
                color: grey[200],
                margin: theme.spacing(2),
                cursor: "pointer"
              }}
              onClick={() => {
                navigate("/release-notes");
                setOpenDrawer(false);
              }}
            />
          </Grid>
        </Grid>
        <ReleaseNotesCard releaseNotes={releaseNotes} />
      </Drawer>
    </React.Fragment>
  );
};
