import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type SetPreferredCleaningDaysMutationVariables = Types.Exact<{
  pmsReservationId: Types.Scalars["String"]["input"];
  days: Array<Types.DayOfWeek> | Types.DayOfWeek;
}>;

export type SetPreferredCleaningDaysMutation = {
  __typename?: "Mutation";
  SetPreferredCleaningDays: { __typename?: "Person"; id: number };
};

export const SetPreferredCleaningDaysDocument = `
    mutation SetPreferredCleaningDays($pmsReservationId: String!, $days: [DayOfWeek!]!) {
  SetPreferredCleaningDays(pmsReservationId: $pmsReservationId, days: $days) {
    id
  }
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    SetPreferredCleaningDays: build.mutation<
      SetPreferredCleaningDaysMutation,
      SetPreferredCleaningDaysMutationVariables
    >({
      query: (variables) => ({ document: SetPreferredCleaningDaysDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useSetPreferredCleaningDaysMutation } = injectedRtkApi;
