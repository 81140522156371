import {
  combineTimeAndDate,
  getTime,
  Heading2,
  Paragraph,
  Select
} from "@likemagic-tech/sv-magic-library";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  useTheme
} from "@mui/material";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useUnits } from "src/hooks/use-unit-by-id";
import CloseIcon from "@mui/icons-material/Close";
import DateTimePicker from "./date-time-picker/date-time-picker";
import { useProperty } from "src/hooks/use-property";
import {
  MultipleAutocompleteComponent,
  MultipleAutocompleteOption
} from "../multiple-autocomplete-component/multiple-autocomplete-component";
import {
  BoxLabelForMaintenanceAndStatus,
  findUnitWithLabel,
  mapLabelsToUnitsForAssignRoom
} from "src/utils/unit-utils";
import { DialogModal } from "../submit-modal/dialog";
import { Form, Formik } from "formik";
import { useFormValidations } from "src/hooks/use-form-validation";
import { format, isSameDay, isValid } from "date-fns";
import { MaintenanceSlotArgs } from "src/store/endpoints/unit.endpoints";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import { useDeleteMaintenanceSlot } from "src/hooks/use-maintenance";
import { useDispatch } from "src/store";
import { openBanner } from "src/slices/banner.slice";
import { useFetchUnitsToBeCleaned } from "src/hooks/use-fetch-units-to-be-cleaned";
import { MaintenanceSlot, MaintenanceSlotType } from "../../graphql-tasks/generated/graphql";
import { useInvalidateHousekeepingCards } from "../../hooks/use-invalidate-housekeeping-cards";

interface MaintenanceModalProps {
  onClose: () => void;
  maintenance?: MaintenanceInformationProps;
  onSubmit: (values: MaintenanceSlotArgs) => void;
}

export interface MaintenanceInformationProps extends MaintenanceSlot {
  unitIds?: string[];
}

export const MaintenanceModal: FC<MaintenanceModalProps> = ({ onClose, maintenance, onSubmit }) => {
  const { selectedProperty } = useProperty();
  const timezone = selectedProperty?.details?.timeZone ?? "Europe/Zurich";
  const [deleteMaintenance, deleteResult] = useDeleteMaintenanceSlot();
  const dispatch = useDispatch();
  const { fetchAction } = useFetchUnitsToBeCleaned({
    propertyId: selectedProperty?.propertyId ?? "",
    skipInitialLoad: true
  });
  const { t } = useTranslationWrapper();
  const { data: units } = useUnits({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const { invalidateHousekeepingCards } = useInvalidateHousekeepingCards();
  const isStartDateEditable =
    !maintenance?.id ||
    (maintenance?.from &&
      isSameDay(utcToZonedTime(new Date(), timezone), utcToZonedTime(maintenance?.from, timezone)));
  const initialValues = useMemo(
    () => ({
      description: maintenance?.description ?? undefined,
      id: maintenance?.id ?? undefined,
      type: maintenance?.type ?? undefined,
      status: maintenance?.status ?? undefined,
      unitIds: maintenance?.unitIds?.[0]
        ? [findUnitWithLabel(units ?? [], maintenance?.unitIds[0], t)]
        : [],
      fromDate: maintenance?.from ? utcToZonedTime(maintenance?.from, timezone) : undefined,
      fromTime: maintenance?.from ? utcToZonedTime(maintenance?.from, timezone) : undefined,
      toDate: maintenance?.to ? utcToZonedTime(maintenance?.to, timezone) : undefined,
      toTime: maintenance?.to ? utcToZonedTime(maintenance?.to, timezone) : undefined
    }),
    [maintenance, units, t, timezone]
  );
  const { maintenanceFormValidation } = useFormValidations();
  const { spacing } = useTheme();

  const maintenanceSlotOptions = Object.values(MaintenanceSlotType).map((value) => ({
    value: value,
    label: t("labels__maintenance_" + value)
  }));

  const unitOptions = useMemo(() => {
    if (!units) return [];

    return mapLabelsToUnitsForAssignRoom(units, t);
  }, [t, units]);

  const handleSubmit = (values: typeof initialValues) => {
    onSubmit({
      id: values.id ?? undefined,
      to: !!values.toDate
        ? zonedTimeToUtc(combineTimeAndDate(values.toDate, values.toTime), timezone)
        : "",
      type: values.type || MaintenanceSlotType.OutOfService,
      description: values.description,
      unitIds: values?.unitIds?.map((item) => item?.id),
      from: !!values.fromDate
        ? zonedTimeToUtc(combineTimeAndDate(values.fromDate, values.fromTime), timezone)
        : ""
    });
  };

  const minimumTime = (minDate: Date) => {
    if (isValid(minDate)) {
      return format(minDate, "HH:mm");
    }
  };

  const isSubmitDisabled = ({
    type,
    fromDate,
    fromTime,
    toDate,
    toTime,
    unitIds
  }: typeof initialValues) => {
    const isToSameDate = toDate && fromDate && toDate.toDateString() === fromDate.toDateString();
    const isToValid = !(isToSameDate && fromTime ? getTime(fromTime) > getTime(toTime) : !fromTime);

    return !(type && fromDate && fromTime && toDate && toTime && unitIds && isToValid);
  };

  const getUnitOptionItem = useCallback(
    (id: string) => unitOptions.find((item) => item.id === id),
    [unitOptions]
  );
  useEffect(() => {
    if (deleteResult?.isSuccess) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
      deleteResult.reset();
      fetchAction();
      onClose();
    }
  }, [dispatch, t, fetchAction, deleteResult, onClose]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={maintenanceFormValidation}
    >
      {(formik) => (
        <Form id="maintenanceForm" onSubmit={formik.handleSubmit}>
          <DialogModal isOpen={true} handleClose={onClose}>
            <Grid container p={3} flexDirection="column">
              <DialogTitle sx={{ flex: "1 1 0", padding: "0" }}>
                <Grid container direction="row">
                  <Grid item xs={11}>
                    <Heading2 color="text.primary">
                      {maintenance?.id
                        ? t("labels__edit_maintenance")
                        : t("labels__add_maintenance")}
                    </Heading2>
                  </Grid>
                  <Grid item xs={1} display="flex" justifyContent="end">
                    <IconButton onClick={onClose}>
                      <CloseIcon fontSize="medium" />
                    </IconButton>
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent sx={{ padding: "0" }}>
                <Box sx={{ marginTop: spacing(2) }}>
                  <MultipleAutocompleteComponent
                    disabled={!!maintenance?.id}
                    label={t("labels__units")}
                    onSelected={(values: MultipleAutocompleteOption[]) =>
                      formik.setFieldValue("unitIds", values)
                    }
                    value={formik.values.unitIds}
                    displayItem={(_, option) => {
                      const unitOptionItem = getUnitOptionItem(option.id);
                      return (
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Grid item>
                            <Paragraph>{unitOptionItem?.label}</Paragraph>
                          </Grid>
                          <Grid item>
                            <BoxLabelForMaintenanceAndStatus
                              maintenanceType={unitOptionItem?.maintenance}
                              status={unitOptionItem?.status}
                              t={t}
                            />
                          </Grid>
                        </Grid>
                      );
                    }}
                    options={unitOptions}
                  />
                </Box>
                <Box sx={{ marginTop: spacing(2) }}>
                  <Select
                    fullWidth
                    sx={{
                      borderRadius: spacing(2),
                      "& .MuiInputLabel-formControl": { opacity: "0.5" }
                    }}
                    variant={"outlined"}
                    disabled={!!maintenance?.id}
                    label={t("labels__type_of_maintenance")}
                    onChange={(e) =>
                      formik.setFieldValue("type", e.target.value as MaintenanceSlotType)
                    }
                    value={formik.values.type ?? ""}
                    options={maintenanceSlotOptions}
                  />
                </Box>
                <Box sx={{ marginTop: spacing(2) }}>
                  <DateTimePicker
                    disabled={!isStartDateEditable}
                    label={t("labels__start_date")}
                    time={formik.values.fromTime}
                    date={formik.values.fromDate}
                    minDate={utcToZonedTime(new Date(), timezone)}
                    setDate={(date: Date) => formik.setFieldValue("fromDate", date)}
                    setTime={(value: Date) => formik.setFieldValue("fromTime", value)}
                  />
                </Box>
                <Box sx={{ marginTop: spacing(2) }}>
                  <DateTimePicker
                    minimumTime={
                      formik.values.fromDate && isValid(formik.values.fromDate)
                        ? minimumTime(
                            combineTimeAndDate(formik.values.fromDate, formik.values.fromTime)
                          )
                        : undefined
                    }
                    minDate={
                      formik.values.fromDate && isValid(formik.values.fromDate)
                        ? combineTimeAndDate(formik.values.fromDate, formik.values.fromTime)
                        : utcToZonedTime(new Date(), timezone)
                    }
                    label={t("labels__end_date")}
                    time={formik.values.toTime}
                    date={formik.values.toDate}
                    setDate={(date: Date) => formik.setFieldValue("toDate", date)}
                    setTime={(value: Date) => formik.setFieldValue("toTime", value)}
                  />
                </Box>
                <Box sx={{ marginTop: spacing(2) }}>
                  <TextField
                    value={formik.values.description}
                    onChange={(e) => formik.setFieldValue("description", e.target.value)}
                    placeholder="Description"
                    multiline
                    fullWidth
                  />
                </Box>
              </DialogContent>
              <DialogActions
                sx={{
                  flex: "1 1 0",
                  px: "0",
                  marginTop: spacing(5),
                  flexDirection: "column",
                  gap: 1
                }}
              >
                {maintenance?.id && (
                  <Button
                    variant={formik.isSubmitting || !isStartDateEditable ? "disabled" : "error"}
                    onClick={() =>
                      maintenance?.id &&
                      deleteMaintenance(maintenance?.id).then(() => {
                        invalidateHousekeepingCards();
                      })
                    }
                    disabled={formik.isSubmitting || !isStartDateEditable}
                    fullWidth
                  >
                    {t("labels__delete")}
                  </Button>
                )}
                <Button
                  variant={
                    isSubmitDisabled(formik.values) || formik.isSubmitting || formik.isValid
                      ? "disabled"
                      : "primary"
                  }
                  onClick={() => {
                    handleSubmit(formik.values);
                  }}
                  disabled={
                    isSubmitDisabled(formik.values) || formik.isSubmitting || formik.isValid
                  }
                  fullWidth
                >
                  {t("labels__save")}
                </Button>
              </DialogActions>
            </Grid>
          </DialogModal>
        </Form>
      )}
    </Formik>
  );
};
