import { FC, useMemo } from "react";
import {
  BaseTableFiltersProps,
  FilterObjectType,
  serializeIdOfFilter
} from "src/components/table/filters/table-filters";
import { FilterOperator } from "src/types/filters/filters";
import { Box, FormControl, Grid, MenuItem, useTheme } from "@mui/material";
import ChevronDown from "src/icons/ChevronDown";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { ReservationStatus } from "../../../domain/reservation-status";
import { AdditionalFiltersEnum, BalanceStatusEnum, GuestFlowStatusEnum } from "../domain/enums";
import { CustomSelector } from "../../../components/table/filters/components/additional-filters-selector";

interface ReservationAdditionalFiltersProps extends BaseTableFiltersProps {}

export enum AdditionalDateFiltersEnum {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  TOMORROW = "TOMORROW"
}

const mobileAdditionalFilters = {
  [AdditionalFiltersEnum.BALANCE_STATUS]: Object.keys(BalanceStatusEnum).map(
    (item) => BalanceStatusEnum[item as keyof typeof BalanceStatusEnum]
  ),
  [AdditionalFiltersEnum.FLOW_STATE]: Object.keys(GuestFlowStatusEnum).map(
    (item) => GuestFlowStatusEnum[item as keyof typeof GuestFlowStatusEnum]
  ),
  [AdditionalFiltersEnum.RESERVATION_STATUS]: Object.keys(ReservationStatus).map(
    (item) => ReservationStatus[item as keyof typeof ReservationStatus]
  )
};

const dateOptions = (t: (v: string) => string) => {
  return [
    {
      label: t("labels__additional_filter_YESTERDAY"),
      value: AdditionalDateFiltersEnum.YESTERDAY
    },
    {
      label: t("labels__additional_filter_TODAY"),
      value: AdditionalDateFiltersEnum.TODAY
    },
    {
      label: t("labels__additional_filter_TOMORROW"),
      value: AdditionalDateFiltersEnum.TOMORROW
    }
  ];
};

// There is a need for the filter because whenever we take out all filters we get an array with the first position being a empty string.
const getAdditionlFilterValueForKey = (key: string, filterMap: any): Array<string> => {
  const filterId = serializeIdOfFilter({
    type: FilterObjectType.ADDITIONAL,
    operator: FilterOperator.In,
    name: key
  });
  return filterMap[filterId]?.value?.split(",").filter((item: string) => item !== "") || [];
};

const getDateRangeFilterValueForKey = (key: string, filterMap: any): Array<string> => {
  const filterId = serializeIdOfFilter({
    type: FilterObjectType.DATE_RANGE,
    operator: FilterOperator.In,
    name: key
  });
  return filterMap[filterId]?.value?.split(",").filter((item: string) => item !== "") || [];
};
interface AdditionalFiltersGetter extends BaseTableFiltersProps {
  t: (s: string) => string;
}

const getDesktopFilters = ({ filterMap, onFilterMapChange, t }: AdditionalFiltersGetter) => {
  const desktopAdditionalFilters = {
    arrivalEnum: dateOptions(t).map((item) => item.value),
    departureEnum: dateOptions(t).map((item) => item.value)
  };

  const onAdditionalFilterChange = (key: string, values: Array<string>) => {
    const filterUniqueData = {
      type: FilterObjectType.DATE_RANGE,
      operator: FilterOperator.In,
      name: key
    };
    const id = serializeIdOfFilter({ ...filterUniqueData });
    const filterKeys = Object.keys(filterMap);
    const copyOfFilterMap = { ...filterMap };
    // Logic for removing all "date" filters which are located in date filters
    // This is done because only one should be active and should not work in paralel.
    filterKeys.forEach((filterKey) => {
      const filterKeyUpperCase = filterKey.toUpperCase();
      const typeToUpper = key.toUpperCase();
      if (filterKeyUpperCase.includes(typeToUpper)) {
        delete copyOfFilterMap[filterKey];
      }
    });
    onFilterMapChange({
      ...copyOfFilterMap,
      [id]: {
        value: values.length ? values.join(",") : undefined,
        ...filterUniqueData
      }
    });
  };

  return Object.keys(desktopAdditionalFilters).map((item) => ({
    value: getDateRangeFilterValueForKey(item, filterMap),
    label: t(`labels__filter_title_${item}`),
    onChange: (e: any) => onAdditionalFilterChange(item, e.target.value),
    options: desktopAdditionalFilters[item as keyof typeof desktopAdditionalFilters].map(
      (item) => ({
        label: dateOptions(t).find((date) => date.value === item)?.label,
        value: item
      })
    )
  }));
};

const getMobileFilters = ({ filterMap, onFilterMapChange, t }: AdditionalFiltersGetter) => {
  const onAdditionalFilterChange = (key: string, values: Array<string>) => {
    const filterUniqueData = {
      type: FilterObjectType.ADDITIONAL,
      operator: FilterOperator.In,
      name: key
    };
    const id = serializeIdOfFilter({ ...filterUniqueData });
    onFilterMapChange({
      ...filterMap,
      [id]: {
        value: values.length ? values.join(",") : undefined,
        ...filterUniqueData
      }
    });
  };

  return Object.keys(mobileAdditionalFilters).map((item) => ({
    value: getAdditionlFilterValueForKey(item, filterMap),
    label: t(`labels__filter_title_${item}`),
    onChange: (e: any) => onAdditionalFilterChange(item, e.target.value),
    options: mobileAdditionalFilters[item as keyof typeof mobileAdditionalFilters].map((item) => ({
      label: t(`labels__additional_filter_${item}`),
      value: item
    }))
  }));
};

export const ReservationAdditionalFilters: FC<ReservationAdditionalFiltersProps> = ({
  filterMap,
  onFilterMapChange
}) => {
  const isMobile = useIsMobile();
  const { spacing } = useTheme();
  const { t } = useTranslationWrapper();

  const dropdowns = useMemo(
    () => getDesktopFilters({ filterMap, onFilterMapChange, t }),
    [filterMap, onFilterMapChange, t]
  );

  const dropDownsForMobile = useMemo(
    () => getMobileFilters({ filterMap, onFilterMapChange, t }),
    [filterMap, onFilterMapChange, t]
  );

  return (
    <Grid
      container
      display={"flex"}
      flexWrap={"wrap"}
      sx={{
        gap: spacing(1.25)
      }}
      justifyContent={isMobile ? "flex-start" : "center"}
      width={isMobile ? "auto" : "max-content"}
    >
      {dropdowns.map(({ value, label, onChange, options }) => (
        <Box key={"container-additional-filters-" + label}>
          <FormControl fullWidth>
            <CustomSelector
              multiple
              displayEmpty
              placeholder={label}
              label={label}
              size={"medium"}
              IconComponent={ChevronDown}
              value={value}
              onChange={onChange}
              variant="filled"
              disableUnderline
              selected={!!value.length}
              renderValue={(selected: any) => {
                if (selected.length === 0) {
                  return label;
                }

                const camelCaseSelected = selected.map((item: string) => {
                  return t(`labels__additional_filter_${item}`);
                });

                return `${label}: ${camelCaseSelected.join(", ")}`;
              }}
            >
              {options.map(({ value, label }) => (
                <MenuItem key={"additional-filters-" + label} value={value}>
                  {label}
                </MenuItem>
              ))}
            </CustomSelector>
          </FormControl>
        </Box>
      ))}

      {isMobile &&
        dropDownsForMobile.map(({ value, label, onChange, options }) => (
          <Box key={"container-additional-filters-" + label}>
            <FormControl fullWidth>
              <CustomSelector
                placeholder={label}
                displayEmpty
                label={label}
                multiple
                size={"medium"}
                IconComponent={ChevronDown}
                value={value}
                onChange={onChange}
                variant="filled"
                disableUnderline
                selected={!!value.length}
                renderValue={(selected: any) => {
                  if (selected.length === 0) {
                    return label;
                  }

                  const camelCaseSelected = selected.map((item: string) => {
                    return t(`labels__additional_filter_${item}`);
                  });

                  return `${label}: ${camelCaseSelected.join(", ")}`;
                }}
              >
                {options.map(({ value, label }) => (
                  <MenuItem key={"field-additional-filters" + label} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </CustomSelector>
            </FormControl>
          </Box>
        ))}
    </Grid>
  );
};
