import { Box, Grid, Slider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { add, formatISO } from "date-fns";
import React, { FC, useCallback, useMemo, useState } from "react";
import { formatTime, formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../../../hooks/use-property";
import { Dialog } from "./dialog";
import { PricePreview } from "../../../../../../components/price-preview/price-preview";
import { ServiceModalsProps } from "./types";
import { multiplyPrices } from "../../../../../../utils/price";
import { emptyPrice } from "../../../../../../domain/price";
import { getI18nSelectedLanguage } from "src/utils/language";

const useStyles = makeStyles()((theme) => ({
  dialogContentContainer: {
    alignSelf: "center",
    width: "100%"
  },
  spaceNumberSlider: {
    paddingLeft: theme.spacing(6)
  }
}));

export const LateCheckoutPickerDialog: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const [hours, setHours] = useState(0);

  const { classes } = useStyles();
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const updatedDepartureTime = useMemo(() => {
    if (reservation?.departure) {
      const newDeparture = add(new Date(reservation?.departure), { hours });
      return formatISO(newDeparture);
    }
  }, [reservation?.departure, hours]);

  const handleChangeHours = useCallback(() => {
    const values = [
      {
        date: formatToIsoDate(new Date(reservation?.departure!)),
        count: hours
      }
    ].filter((item) => item.count);
    onChange({ values });
  }, [hours, onChange, reservation?.departure]);

  return (
    <Dialog
      title={t("labels__wizard_late_checkout_title")}
      open
      onConfirm={handleChangeHours}
      onDismiss={onClose}
      content={
        <Box>
          <Box m={2}>
            <Grid container direction="row" mt={3}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">
                  {t("labels__wizard_current_departure")}
                </Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(reservation?.departure, language, selectedProperty?.details.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
            <Grid container direction="row" mt={1} mb={2}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{t("labels__wizard_new_departure")}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(updatedDepartureTime, language, selectedProperty?.details.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
          </Box>
          <Grid container flexWrap="nowrap" alignItems="center" px={5}>
            <Slider
              value={hours}
              onChange={(event, newValue) => setHours(newValue as number)}
              max={service.maximumHours}
              track={false}
              min={0}
              aria-labelledby="input-slider"
            />
            <Paragraph className={classes.spaceNumberSlider}>{hours}</Paragraph>
          </Grid>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview price={multiplyPrices(service?.price ?? emptyPrice(), hours)} />
          </Box>
        </Box>
      }
      buttonLabel={t("labels__wizard_confirm")}
    />
  );
};
