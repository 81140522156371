import React, { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LastConversations } from "../../domain/message";
import { ChatLastConversationPreview } from "./chat-last-conversation-preview";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { ParagraphBold } from "@likemagic-tech/sv-magic-library";
import { TitleHeader } from "src/features/reservations/reservation-details/reservation-details-tabs/overview/card-header/title-header";

export const ChatLastConversations: FC<{ lastConversations?: LastConversations }> = ({
  lastConversations
}) => {
  const { t } = useTranslationWrapper();

  return (
    <Box>
      <Accordion sx={{ boxShadow: "none" }} disableGutters defaultExpanded>
        <AccordionSummary
          sx={{ px: 0 }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="last-conversations"
          id="last-conversations"
        >
          <ParagraphBold>
            <TitleHeader
              title={t("labels__last_conversations")}
              length={lastConversations?.length}
            />
          </ParagraphBold>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0 }}>
          {lastConversations?.map((item) => (
            <Box key={item?.conversationId} py={0.5}>
              <ChatLastConversationPreview
                lastMessage={item.messages?.[0]}
                status={item.status}
                id={item.conversationId}
              />
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};
