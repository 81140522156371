import { ParagraphSmall, useApiVersion, useGlobalModal } from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import ActionDropdown from "src/components/action-dropdown/action-dropdown";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { getYesNoModalArg } from "src/utils/modal-util";

interface DirtyUnitsActionsProps {
  onClick: (actionType: BulkActionType | UnitActionType) => void;
}

export enum BulkActionType {
  ALL_TO_CLEAN = "ALL_TO_CLEAN",
  ALL_TO_CLEAN_TO_BE_INSPECTED = "ALL_TO_CLEAN_TO_BE_INSPECTED",
  ALL_TO_DIRTY = "ALL_TO_DIRTY"
}

export enum UnitActionType {
  MAINTENANCE = "MAINTENANCE",
  CREATE_TASK = "CREATE_TASK"
}
export const DirtyUnitsActions: FC<DirtyUnitsActionsProps> = ({ onClick }) => {
  const { t } = useTranslationWrapper();
  const { open: openModal } = useGlobalModal();
  const { isRESTVersion } = useApiVersion();
  const theme = useTheme();

  const confirmationModal = useCallback(
    async (selectedItem: BulkActionType | UnitActionType) => {
      const result = await openModal(
        getYesNoModalArg(
          t(`labels__set_${selectedItem}`),
          t("labels__are_you_sure"),
          t("labels__yes"),
          t("labels__no")
        )
      );
      if (result) {
        onClick(selectedItem);
      }
    },
    [openModal, onClick, t]
  );

  const maintenanceOptions = useMemo(() => {
    return isRESTVersion
      ? [
          {
            id: UnitActionType.MAINTENANCE,
            label: t("labels__add_maintenance")
          }
        ]
      : [];
  }, [isRESTVersion, t]);

  const taskOptions = useMemo(() => {
    return isRESTVersion
      ? [
          {
            id: UnitActionType.CREATE_TASK,
            label: t("buttons__create_task")
          }
        ]
      : [];
  }, [isRESTVersion, t]);

  const options = useMemo(() => {
    return [
      {
        id: BulkActionType.ALL_TO_DIRTY,
        label: t("labels__set_ALL_TO_DIRTY")
      },
      {
        id: BulkActionType.ALL_TO_CLEAN_TO_BE_INSPECTED,
        label: t("labels__set_ALL_TO_CLEAN_TO_BE_INSPECTED")
      },
      {
        id: BulkActionType.ALL_TO_CLEAN,
        label: t("labels__set_ALL_TO_CLEAN")
      },
      ...maintenanceOptions,
      ...taskOptions
    ];
  }, [maintenanceOptions, t, taskOptions]);

  const handleOption = (optionId: string) => {
    switch (optionId) {
      case BulkActionType.ALL_TO_DIRTY:
      case BulkActionType.ALL_TO_CLEAN:
      case BulkActionType.ALL_TO_CLEAN_TO_BE_INSPECTED:
        confirmationModal(optionId as BulkActionType);
        break;

      case UnitActionType.CREATE_TASK:
      case UnitActionType.MAINTENANCE:
        onClick(optionId as UnitActionType);
        break;
    }
  };

  return (
    <ActionDropdown handleOption={handleOption} options={options}>
      <ParagraphSmall sx={{ color: theme.palette.background.paper }}>
        {t("buttons__actions")}
      </ParagraphSmall>
    </ActionDropdown>
  );
};
