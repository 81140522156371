import { FC, useCallback, useEffect, useState } from "react";
import { useSinglePrismicDocument } from "@prismicio/react";
import {
  LanguageType,
  ReleaseNotes as ReleaseNotesType
} from "src/components/dashboard/release-note-popover";
import { ReleaseNotesCard } from "./release-notes-card";
import { getI18nSelectedLanguage } from "src/utils/language";

export const ReleaseNotes: FC = () => {
  const [releaseNotes, setReleaseNotes] = useState<ReleaseNotesType[]>([]);
  const language = getI18nSelectedLanguage();
  const release_notes = useSinglePrismicDocument("release_notes", {
    lang: LanguageType[language as keyof typeof LanguageType]
  });
  /**
   * Transforms the data receive by prismic and stores it in an array
   */
  const formatPrismicDataToBeUsed = useCallback((data: Array<any>) => {
    const arrayOfReleaseNotes = data[0]?.data.release_notes; //need to test if it's always this position
    if (arrayOfReleaseNotes) {
      let helperArray: ReleaseNotesType[] = []; // used so we don't mutate the releaseNotes array
      for (const arr of arrayOfReleaseNotes.reverse()) {
        helperArray = [
          ...helperArray,
          {
            date: arr.date,
            title: arr.title,
            description: arr.description
          }
        ];
      }
      setReleaseNotes(helperArray);
    }
  }, []);

  useEffect(() => {
    formatPrismicDataToBeUsed(release_notes);
  }, [release_notes, formatPrismicDataToBeUsed]);

  return <ReleaseNotesCard releaseNotes={releaseNotes} page />;
};
