import { useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "../store";
import {
  fetchProperties,
  selectPropertiesByLanguage,
  selectPropertyStatus,
  selectSelectedProperty
} from "../slices/properties";
import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { transformPropertyV2ToCommonProperty } from "../graphql/transform/transform-property";
import { Params, useSelectId } from "./use-select-id";
import { mapQueryStatusToEntityStateStatus } from "../graphql/transform/transform-utils";
import { useGetAllPropertiesQuery } from "src/graphql/queries/GetAllProperties.generated";
import { Property } from "../domain/property";
import { useTranslationWrapper } from "./use-translation-wrapper";

export const ACCOUNT_PROPERTY_ID = "account";

export const useProperty = () => {
  const dispatch = useDispatch();
  const { isRESTVersion } = useApiVersion();
  const { t } = useTranslationWrapper();

  const { data, status } = useGetAllPropertiesQuery(
    {},
    { skip: isRESTVersion, refetchOnMountOrArgChange: false }
  );

  const allPropertiesV1 = useSelector(selectPropertiesByLanguage);
  const propertyStatus = useSelector(selectPropertyStatus);

  const allProperties = useMemo(
    () => (isRESTVersion ? allPropertiesV1 : transformPropertyV2ToCommonProperty(data) ?? []),
    [isRESTVersion, allPropertiesV1, data]
  );
  const { selectedId, setSelectId } = useSelectId({
    fieldName: Params.SELECTED_PROPERTY_ID
  });
  const selectedPropertyInState = useSelector(selectSelectedProperty);

  const getProperty = useCallback(
    (propertyId: string) =>
      allProperties.find((property) => property.propertyId === propertyId) ?? allProperties[0],
    [allProperties]
  );

  const selectedProperty: Property = useMemo(() => {
    if (selectedId === ACCOUNT_PROPERTY_ID) {
      return getProperty(allProperties[0]?.propertyId);
    } else if (selectedId) {
      return getProperty(selectedId);
    } else {
      return getProperty(selectedPropertyInState) ?? allProperties[0];
    }
  }, [selectedId, getProperty, selectedPropertyInState, allProperties]);

  useEffect(() => {
    if (isRESTVersion) {
      dispatch(fetchProperties());
    }
  }, [dispatch, isRESTVersion]);

  const selectedPropertyPreview: string = useMemo(() => {
    if (selectedId === ACCOUNT_PROPERTY_ID) {
      return t("labels__account");
    } else if (selectedId) {
      return getProperty(selectedId)?.details?.name ?? "";
    } else {
      return t("labels__not_available");
    }
  }, [getProperty, selectedId, t]);

  const selectedPropertyIds = useMemo(() => {
    if (selectedId === ACCOUNT_PROPERTY_ID) {
      return allProperties.map((property) => property.propertyId);
    } else if (selectedId) {
      return [selectedId];
    } else {
      return [];
    }
  }, [allProperties, selectedId]);

  const computedStatus = isRESTVersion ? propertyStatus : mapQueryStatusToEntityStateStatus(status);

  const isAccountSelected =
    allProperties.length !== 1 && allProperties.length === selectedPropertyIds.length;

  return {
    allProperties,
    getProperty,
    selectedProperty,
    setSelectedPropertyId: setSelectId,
    selectedPropertyIds,
    isAccountSelected,
    selectedPropertyPreview,
    status: computedStatus
  };
};
