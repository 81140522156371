import type { TypedUseSelectorHook } from "react-redux";
import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { configureStore, PreloadedState } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { magicApi } from "./magic.api";
import { graphqlApi } from "../graphql/graphql.api";
import { messagingApi } from "../graphql-messaging/messaging.api";
import { tasksApi } from "../graphql-tasks/tasks.api";
import { taskManagementFilesApi } from "./task-management-files.api";
import { persistStore } from "redux-persist";
import { guestServiceFilesApi } from "./guest-service-files.api";

export interface ThunkExtraArguments {
  apiKey: string;
  tenantId: string;
  keycloak: any;
}

export const setupStore = (
  extraArgument: ThunkExtraArguments,
  preloadedState?: PreloadedState<typeof rootReducer>
) => {
  const extra = extraArgument as ThunkExtraArguments;

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // adding the api middleware enables caching,
      // invalidation, polling and other features of `rtk-query`
      getDefaultMiddleware({
        serializableCheck: false,
        thunk: {
          extraArgument: extra
        }
      }).concat(
        magicApi.middleware,
        taskManagementFilesApi.middleware,
        guestServiceFilesApi.middleware,
        graphqlApi.middleware,
        messagingApi.middleware,
        tasksApi.middleware
      ),
    preloadedState
  });

  const persistor = persistStore(store);

  return { store, persistor };
};

export type RootStore = ReturnType<typeof setupStore>["store"];

export type RootState = ReturnType<RootStore["getState"]>;

export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = RootStore["dispatch"];

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
