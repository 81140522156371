import React, { FC, MutableRefObject, useCallback, useEffect, useMemo, useRef } from "react";
import { Message } from "../domain/message";
import { Box, Grid, Paper } from "@mui/material";
import { UserAvatar } from "../../components/user-avatar";
import {
  Caption,
  formatDateTime,
  Paragraph,
  ParagraphBold
} from "@likemagic-tech/sv-magic-library";
import { useTheme } from "@mui/material/styles";
import { Direction } from "../../graphql-messaging/generated/graphql";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { ChatItemPlatformIcon } from "./chat-item-platform-icon";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { messageStatusIcons, messageStatusLabelColor } from "../utils/utils";
import { ChatItemContentVideo } from "./chat-item-content/chat-item-content-video";
import { ChatItemContentWhatsapp } from "./chat-item-content/chat-item-content-whatsapp";
import { ChatItemContentImage } from "./chat-item-content/chat-item-content-image";
import { ChatItemContentText } from "./chat-item-content/chat-item-content-text";
import { ChatItemContentEmail } from "./chat-item-content/chat-item-content-email";
import { ChatItemContentAudio } from "./chat-item-content/chat-item-content-audio";
import { ChatItemContentFile } from "./chat-item-content/chat-item-content-file";
import { ChatItemContentLocation } from "./chat-item-content/chat-item-content-location";
import { GuestIdentity } from "../domain/conversation";
import { ChatItemPlaceholder } from "./chat-item-placeholder";
import { ConversationMessageStatus } from "../../domain/ConversationMessageStatus";
import { ChatItemContentV2Audio } from "./chat-item-content/chat-item-content-v2-audio";
import { ChatItemContentV2Image } from "./chat-item-content/chat-item-content-v2-image";
import { ChatItemContentV2Video } from "./chat-item-content/chat-item-content-v2-video";
import { ChatItemContentV2File } from "./chat-item-content/chat-item-content-v2-file";
import { useProperty } from "src/hooks/use-property";
import { ShowMore } from "../../components/show-more/show-more";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ChatItemProps {
  message?: Message;
  selectedMessageId: string | null;
  guestIdentity?: GuestIdentity | null;
}
const isDirectionReceived = (direction?: Direction) => direction === Direction.Received;
export const ChatItem: FC<ChatItemProps> = ({ message, selectedMessageId, guestIdentity }) => {
  const { palette } = useTheme();
  const { t } = useTranslationWrapper();
  const isMobile = useIsMobile();
  const { selectedProperty } = useProperty();
  const chatItemRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const language = getI18nSelectedLanguage();

  useEffect(() => {
    if (selectedMessageId) {
      const element = chatItemRef.current;
      if (element && element.id === selectedMessageId) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedMessageId]);

  const userInitials = useMemo(() => {
    if (isDirectionReceived(message?.direction)) {
      if (guestIdentity?.firstName && guestIdentity?.lastName) {
        return `${guestIdentity?.firstName?.charAt(0)}${guestIdentity?.lastName?.charAt(0)}`;
      }
      return "";
    } else {
      return message?.from?.displayName
        ?.split(" ")
        .map((word) => word[0])
        .join("");
    }
  }, [guestIdentity, message]);

  const chatItemContentDisplay = useCallback(
    (content: any) => {
      switch (Object.keys(content)[0]) {
        case "text":
          return (
            <ChatItemContentText
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.text}
            />
          );
        case "video":
          return (
            <ChatItemContentVideo
              content={content.video?.url}
              caption={content.video?.caption}
              isLoggedUser={isDirectionReceived(message?.direction)}
            />
          );
        case "whatsapp":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.whatsapp}
            />
          );
        case "hsm":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.whatsapp}
            />
          );
        case "image":
          return (
            <ChatItemContentImage
              content={content.image?.url}
              caption={content.image?.caption}
              isLoggedUser={isDirectionReceived(message?.direction)}
            />
          );
        case "email":
          return (
            <ChatItemContentEmail
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.email.content}
            />
          );
        case "audio":
          return (
            <ChatItemContentAudio
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.audio}
            />
          );
        case "file":
          return (
            <ChatItemContentFile
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.file}
            />
          );
        case "location":
          return (
            <ChatItemContentLocation
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.location}
            />
          );
        default:
          return (
            <Box>
              <Paragraph
                sx={{
                  color: isDirectionReceived(message?.direction)
                    ? palette.background.paper
                    : palette.text.primary
                }}
              >
                {t("labels__messaging__content__not__supported")}
              </Paragraph>
            </Box>
          );
      }
    },
    [message?.direction, palette, t]
  );

  const chatItemContentV2Display = useCallback(
    (content: any) => {
      switch (content.type) {
        case "text":
          return (
            <ChatItemContentText
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.text.text}
            />
          );
        case "email":
          return (
            <ChatItemContentEmail
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.email.content}
            />
          );
        case "list":
          return (
            <ChatItemContentWhatsapp
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.list.text}
            />
          );
        case "file":
          switch (content.file.files[0].contentType) {
            case "audio/ogg":
              return (
                <ChatItemContentV2Audio
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            case "video/mp4":
              return (
                <ChatItemContentV2Video
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            case "application/pdf":
              return (
                <ChatItemContentV2File
                  isLoggedUser={isDirectionReceived(message?.direction)}
                  content={content.file}
                  mediaUrl={content.file?.files?.[0].mediaUrl}
                />
              );
            default:
              return (
                <Box>
                  <Paragraph
                    sx={{
                      color: isDirectionReceived(message?.direction)
                        ? palette.background.paper
                        : palette.text.primary
                    }}
                  >
                    {t("labels__messaging__content__not__supported")}
                  </Paragraph>
                </Box>
              );
          }
        case "location":
          return (
            <ChatItemContentLocation
              isLoggedUser={isDirectionReceived(message?.direction)}
              content={content.location.coordinates}
            />
          );
        case "image":
          return (
            <ChatItemContentV2Image
              mediaUrl={content.image?.images?.[0].mediaUrl}
              content={content.image}
              isLoggedUser={isDirectionReceived(message?.direction)}
            />
          );
        default:
          return (
            <Box>
              <Paragraph
                sx={{
                  color: isDirectionReceived(message?.direction)
                    ? palette.background.paper
                    : palette.text.primary
                }}
              >
                {t("labels__messaging__content__not__supported")}
              </Paragraph>
            </Box>
          );
      }
    },
    [message?.direction, palette, t]
  );

  return message?.status === ConversationMessageStatus.Placeholder ? (
    <ChatItemPlaceholder conversationId={message?.conversationId} />
  ) : (
    <Grid
      container
      pt={1}
      direction={isDirectionReceived(message?.direction) ? "row" : "row-reverse"}
      id={message?.messageId}
      ref={chatItemRef}
    >
      {!isMobile && (
        <Grid
          item
          display={isDirectionReceived(message?.direction) ? "" : "flex"}
          justifyContent={isDirectionReceived(message?.direction) ? "" : "end"}
          pr={isDirectionReceived(message?.direction) ? 1 : 0}
          pl={!isDirectionReceived(message?.direction) ? 1 : 0}
        >
          <UserAvatar
            lettersToDisplay={userInitials}
            sx={{
              backgroundColor: palette.common.white,
              border: 1,
              borderColor: palette.common.black,
              color: palette.common.black,
              height: 40,
              width: 40,
              p: 2
            }}
          />
        </Grid>
      )}
      <Grid item md={7} lg={9} xl={7} xs={11}>
        <Grid container direction={isDirectionReceived(message?.direction) ? "row" : "row-reverse"}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: isDirectionReceived(message?.direction)
                  ? palette.grey[700]
                  : palette.background.paper,
                overflow: "hidden"
              }}
            >
              <ShowMore>
                <Box p={2}>
                  <ParagraphBold
                    gutterBottom
                    sx={{
                      wordBreak: "break-word",
                      color: isDirectionReceived(message?.direction)
                        ? palette.common.white
                        : palette.text.primary
                    }}
                  >
                    {message?.from?.displayName ?? message?.conversationId}
                  </ParagraphBold>
                  {message?.messageBirdVersion === "2"
                    ? chatItemContentV2Display(message?.content)
                    : chatItemContentDisplay(message?.content)}
                </Box>
              </ShowMore>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={11} pt={1}>
        <Grid
          container
          alignItems="center"
          justifyContent={isDirectionReceived(message?.direction) ? "start" : "end"}
        >
          {!isMobile && <Grid item xs={1}></Grid>}
          {message?.createdAt && (
            <Grid item>
              <Caption>
                {formatDateTime(message?.createdAt, language, selectedProperty?.details.timeZone)}
              </Caption>
            </Grid>
          )}
          <Grid item pr={2} pl={2}>
            <Grid container alignItems="center">
              <Grid item pr={0.5} mt={0.5}>
                <ChatItemPlatformIcon platform={message?.channel?.platform} />
              </Grid>
              <Grid item>
                <Caption>{t(`labels__message_platform_${message?.channel?.platform}`)}</Caption>
              </Grid>
            </Grid>
          </Grid>
          {message?.direction === Direction.Sent && (
            <Grid item>
              <Grid container alignItems="center">
                <Grid item pr={0.5} mt={0.5}>
                  {messageStatusIcons(message?.status, palette)}
                </Grid>
                <Grid item>
                  <Caption
                    sx={{
                      color: messageStatusLabelColor(message?.status, palette)
                    }}
                  >
                    {t(
                      `labels__message_status_${message?.status ?? ConversationMessageStatus.Other}`
                    )}
                  </Caption>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!isMobile && <Grid item xs={1}></Grid>}
        </Grid>
      </Grid>
    </Grid>
  );
};
