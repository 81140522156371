import {
  Button,
  Chip,
  DoorProvider,
  EmptyState,
  formatDateTime,
  Heading3,
  Paragraph,
  ParagraphBold,
  ParagraphSmall,
  useDoorProviderConfig
} from "@likemagic-tech/sv-magic-library";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { FC, Fragment, useCallback, useMemo, useRef } from "react";
import { useDispatch } from "src/store";
import { DoorType } from "src/api/door-delegate";
import { ReservationDetailsDTO } from "src/store/endpoints/reservation-table.endpoints";
import { resetDoorStatus } from "src/slices/available-doors-state.slice";
import SyncIcon from "@mui/icons-material/Sync";
import { useUnitGroupById } from "src/hooks/use-unit-group-by-id";
import { KeyComponent } from "src/components/key-component";
import { openBanner } from "src/slices/banner.slice";
import { useFetchSupportedSyncStates } from "src/hooks/use-fetch-supported-sync-states";
import { AvailableDoor } from "src/domain/available-door";
import { useOpenDoor } from "../../../../../hooks/use-open-door";
import { useSyncDoors } from "../../../../../hooks/use-sync-doors";
import { useFetchAvailableDoors } from "../../../../../hooks/use-fetch-available-doors";
import { BackofficeKeyCard } from "src/graphql/generated/graphql";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ReservationDetailsKeysProps {
  reservation: ReservationDetailsDTO;
}

interface DoorKeyLabel {
  exists: boolean;
  label: string;
}

const MOBILE_KEY = "MOBILE_KEY";

export const ReservationDetailsKeys: FC<ReservationDetailsKeysProps> = ({ reservation }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const doorTypesWithoutNone = useFetchSupportedSyncStates(reservation.propertyId);
  const reservationId = reservation.id;
  const { filterKeysByIds } = useFetchAvailableDoors(reservation.propertyId);
  const assignedUnitGroup = useUnitGroupById(reservation.propertyId, reservation.unit?.unitGroupId);
  const bookedUnitGroup = useUnitGroupById(reservation.propertyId, reservation.bookedUnitGroupId);
  const keySliderRefs = useRef<{ [doorId: string]: any }>({});
  const doorConfig = useDoorProviderConfig(reservation.propertyId);
  const labelMap = useMemo(() => {
    return {
      [DoorType.PUBLIC]: t("labels__sync_doors_PUBLIC"),
      [DoorType.PUBLIC_INHOUSE]: t("labels__sync_doors_PUBLIC_INHOUSE"),
      [DoorType.PUBLIC_AND_PRIVATE]: t("labels__sync_doors_PUBLIC_AND_PRIVATE")
    };
  }, [t]);
  const { openDoor, openAdminDoor } = useOpenDoor();
  const { syncDoors } = useSyncDoors();

  const reservationKeyIds = useMemo(
    () => reservation.accessibleDoors?.map((door) => door.id),
    [reservation.accessibleDoors]
  );

  const allAvailableDoors = filterKeysByIds(reservationKeyIds);

  // Will be used only for GLUTZ and BURGWACHTER door provider
  const specialPinDoors = useMemo(() => {
    if (
      doorConfig !== undefined &&
      doorConfig.doorProvider === DoorProvider.GLUTZ &&
      reservation.accessibleDoors !== null
    ) {
      const accessiblePinDoor = reservation.accessibleDoors.find((accessibleDoor) =>
        accessibleDoor.id.endsWith("_PIN")
      );

      if (accessiblePinDoor) {
        return [accessiblePinDoor as unknown as AvailableDoor, ...allAvailableDoors];
      }
    }
    if (
      doorConfig !== undefined &&
      (doorConfig.doorProvider === DoorProvider.BURGWACHTER ||
        doorConfig.doorProvider === DoorProvider.BURGWACHTER_LEGACY) &&
      reservation.accessibleDoors !== null
    ) {
      const accessiblePinDoor = reservation.accessibleDoors.find(
        (accessibleDoor) => !accessibleDoor.is_general
      );

      if (accessiblePinDoor) {
        return [accessiblePinDoor as unknown as AvailableDoor];
      }
    }

    if (
      doorConfig !== undefined &&
      doorConfig.doorProvider === DoorProvider.NUKI &&
      reservation.accessibleDoors !== null
    ) {
      return reservation.accessibleDoors.map((door) => door as unknown as AvailableDoor);
    }

    if (
      doorConfig !== undefined &&
      doorConfig.doorProvider === DoorProvider.OFFLINE_PIN &&
      reservation.accessibleDoors !== null
    ) {
      return reservation.accessibleDoors.map((door) => door as unknown as AvailableDoor);
    }
    return undefined;
  }, [reservation.accessibleDoors, doorConfig, allAvailableDoors]);

  useFetchSupportedSyncStates(reservation.propertyId);

  const computedAvailableDoors = specialPinDoors ? specialPinDoors : allAvailableDoors;

  const handleOpenDoor = useCallback(
    async (isGeneral: boolean, magicToken: string, magicId: string, doorId: string) => {
      if (isGeneral) {
        await openAdminDoor({
          propertyId: reservation.propertyId,
          doorId: doorId
        });
      } else {
        await openDoor({ magicToken, magicId, doorId });
      }
      setTimeout(() => {
        dispatch(resetDoorStatus(doorId));
        keySliderRefs.current?.[doorId]?.reset();
      }, 5000);
    },
    [dispatch, openDoor, openAdminDoor, reservation.propertyId]
  );

  const handleSyncDoors = (doorType: DoorType) => async () => {
    const result = await syncDoors({ reservationId, doorType });
    if (result) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }
  };

  const publicDoorsLabel = useCallback(() => {
    const publicDoorExists = !!reservation?.accessibleDoors?.find(
      (door) => door.is_general || door.title === MOBILE_KEY
    );
    return publicDoorExists
      ? {
          exists: publicDoorExists,
          label: t("labels__card_public_door_available")
        }
      : {
          exists: publicDoorExists,
          label: t("labels__card_public_door_not_available")
        };
  }, [t, reservation?.accessibleDoors]);

  const privateDoorsLabel = useCallback(() => {
    const privateDoorExists = !!reservation?.accessibleDoors?.find((door) => !door.is_general);
    return privateDoorExists
      ? {
          exists: privateDoorExists,
          label: `${assignedUnitGroup?.name || bookedUnitGroup?.name} ${t(
            "labels__card_unit_door_available",
            { unitNumber: reservation?.unit?.name ?? "" }
          )}`
        }
      : {
          exists: privateDoorExists,
          label: `${assignedUnitGroup?.name || bookedUnitGroup?.name} ${t(
            "labels__card_unit_door_not_available",
            { unitNumber: reservation?.unit?.name ?? "" }
          )}`
        };
  }, [
    reservation?.accessibleDoors,
    assignedUnitGroup?.name,
    bookedUnitGroup?.name,
    reservation?.unit?.name,
    t
  ]);

  const doorsAvailabilityInfo = useCallback(() => {
    const labelsArray = [privateDoorsLabel(), publicDoorsLabel()];
    return (
      <Grid container gap={1}>
        {labelsArray.map((type: DoorKeyLabel) => (
          <Chip
            size="small"
            color={type.exists ? "success" : "error"}
            key={type.label}
            label={type.label}
          />
        ))}
      </Grid>
    );
  }, [privateDoorsLabel, publicDoorsLabel]);

  const showKeyDetails = useMemo(
    () =>
      doorConfig?.doorProvider !== DoorProvider.OFFLINE_KEY &&
      doorConfig?.doorProvider !== DoorProvider.DORMA_KABA,
    [doorConfig?.doorProvider]
  );

  return (
    <Grid container py={2} rowSpacing={1} spacing={1}>
      {showKeyDetails && (
        <>
          <Grid item xs={12}>
            <Paper>
              <Grid container p={3} direction="row" alignItems="center">
                <Grid item xs={1}>
                  <Heading3 color="text.primary">{t("labels__keys__availability")}</Heading3>
                </Grid>
                <Grid item xs={11} display="flex" justifyContent="end">
                  <Button
                    variant="primary"
                    onClick={handleSyncDoors(DoorType.NONE)}
                    startIcon={<DeleteIcon fontSize="small" sx={{ color: "background.paper" }} />}
                  >
                    {t("labels__sync_doors_NONE")}
                  </Button>
                </Grid>
                <Grid container>
                  <Grid item paddingTop={1}>
                    {doorsAvailabilityInfo()}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ height: "100%" }}>
              <Grid container p={3}>
                <Grid item>
                  <Heading3 color="text.primary">{t("labels__keys__reservation__keys")}</Heading3>
                </Grid>
              </Grid>
              <Grid
                container
                px={3}
                paddingBottom={3}
                direction="row"
                alignItems="center"
                rowSpacing={1}
              >
                {computedAvailableDoors?.length > 0 ? (
                  computedAvailableDoors.map((door, index) => (
                    <Fragment key={`${door.id}-${door.unitId}-${index}`}>
                      <Grid item xs={3} paddingRight={3}>
                        <Paragraph>{door.title}</Paragraph>
                      </Grid>
                      <Grid item xs={9}>
                        <KeyComponent
                          doorData={door}
                          keySliderRefs={keySliderRefs}
                          doorProvider={doorConfig?.doorProvider}
                          onSuccess={() =>
                            handleOpenDoor(
                              !door.unitId,
                              reservation.magicToken,
                              reservation.magicId,
                              door.id
                            )
                          }
                        />
                      </Grid>
                    </Fragment>
                  ))
                ) : (
                  <EmptyState title={t("labels__no__reservation__keys")} />
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ height: "100%" }}>
              <Grid container p={3}>
                <Grid item paddingBottom={2}>
                  <Heading3 color="text.primary">
                    {t("labels__keys__key__synchronization")}
                  </Heading3>
                </Grid>
                <Grid container>
                  {doorTypesWithoutNone.map((doorType: DoorType) => (
                    <Grid item xs={12} key={doorType} paddingBottom={2}>
                      <Button
                        variant="secondary"
                        fullWidth
                        onClick={handleSyncDoors(doorType)}
                        sx={{
                          justifyContent: "start"
                        }}
                        startIcon={<SyncIcon fontSize="large" />}
                      >
                        {labelMap[doorType as keyof typeof labelMap]}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </>
      )}
      {reservation.keyCards && (
        <Grid item xs={12}>
          <Paper>
            <Grid container p={3}>
              <Grid item paddingBottom={2}>
                <Heading3 color="text.primary">
                  {t("labels__keycards")}{" "}
                  {reservation.keyCards.length > 0 ? `(${reservation.keyCards.length})` : ""}
                </Heading3>
              </Grid>
              <Grid container>
                {reservation.keyCards.length > 0 ? (
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Paragraph>{t("labels__keycards_column_keycard")}</Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph>{t("labels__keycards_column_copy")}</Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph>{t("labels__keycards_column_issuer")}</Paragraph>
                          </TableCell>
                          <TableCell>
                            <Paragraph>{t("labels__keycards_column_issued")}</Paragraph>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation.keyCards?.map((card: BackofficeKeyCard, index) => (
                          <TableRow key={`BackofficeKeyCard-${card.id}`}>
                            <TableCell>
                              <Tooltip title={card.id} arrow placement="bottom">
                                <div>
                                  <ParagraphBold>
                                    {t("labels__keycards_keycard_label", {
                                      index: String(index + 1)
                                    })}
                                  </ParagraphBold>
                                </div>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <ParagraphSmall>
                                {card.isCopy
                                  ? t("labels__keycards_duplicate_card")
                                  : t("labels__keycards_main_card")}
                              </ParagraphSmall>
                            </TableCell>
                            <TableCell>
                              <ParagraphSmall>
                                {card.requestor?.role === "ADMIN"
                                  ? t("labels__keycards_team_member")
                                  : card.requestor?.principal}{" "}
                              </ParagraphSmall>
                            </TableCell>
                            <TableCell>
                              <ParagraphSmall>
                                {formatDateTime(
                                  card.createdAt,
                                  language,
                                  selectedProperty?.details.timeZone
                                )}
                              </ParagraphSmall>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <EmptyState title={t("labels__keycards_no_keycards")} />
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
