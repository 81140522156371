import React, { FC, useCallback, useMemo, useState } from "react";
import { ReservationDetailsDTO } from "../../../../../store/endpoints/reservation-table.endpoints";
import { Box, Button, Grid, Paper, useTheme } from "@mui/material";
import {
  Chip,
  ChipProps,
  EmptyState,
  EmptyStateSize,
  formatDate,
  formatTime,
  Heading3,
  ParagraphSmall
} from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../../../../hooks/use-translation-wrapper";
import { grey } from "@mui/material/colors";
import { RestaurantReservationStatus } from "../../../../../domain/reservation-table-dto";
import { ActionMenu } from "../../../../../components/action-menu/action-menu";
import { SevenroomsWidget } from "../../../../../components/sevenrooms-widget/sevenrooms-widget";
import { useSevenRoomsWidgetConfig } from "../../../../../hooks/use-configuration";
import { useIsMobile } from "../../../../../hooks/use-is-mobile";
import { SevenRoomsLoader } from "../../../../../components/sevenrooms-widget/seven-rooms-loader";
import AddIcon from "@mui/icons-material/Add";
import { useProperty } from "src/hooks/use-property";
import { getI18nSelectedLanguage } from "src/utils/language";

interface ReservationDetailsRestaurantsProps {
  reservation: ReservationDetailsDTO;
}

export const ReservationDetailsRestaurants: FC<ReservationDetailsRestaurantsProps> = ({
  reservation
}) => {
  const { t } = useTranslationWrapper();
  const [selectedReservationId, setSelectedReservationId] = useState<string | undefined>();
  const sevenRoomsWidgetConfig = useSevenRoomsWidgetConfig();
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [showComponent, setShowComponent] = useState(false);
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const getChipColor = (status?: RestaurantReservationStatus): ChipProps["color"] => {
    switch (status) {
      case RestaurantReservationStatus.Arrived:
        return "info";
      case RestaurantReservationStatus.Booked:
        return "info";
      case RestaurantReservationStatus.Canceled:
        return "error";
      case RestaurantReservationStatus.NoShow:
        return "error";
      case RestaurantReservationStatus.Completed:
        return "success";
      case RestaurantReservationStatus.Confirmed:
        return "info";
      case RestaurantReservationStatus.Seated:
        return "success";
      default:
        return "default";
    }
  };

  const person = reservation.primaryGuest;

  const menuItems = useMemo(() => {
    return [
      {
        label: t("labels__edit"),
        id: "edit"
      }
    ];
  }, [t]);

  const handleAction = useCallback((selectedOptionId: string, restaurantReservationId?: string) => {
    switch (selectedOptionId) {
      case "edit":
        setSelectedReservationId(restaurantReservationId);
        console.log("edit");
        break;
      case "cancel":
        console.log("cancel");
        break;
    }
  }, []);

  return (
    <Box mt={2}>
      <Grid item pb={2} display="flex" justifyContent="end">
        <Button variant="primary" onClick={() => setShowComponent(!showComponent)}>
          <Grid container direction="row" alignItems="center">
            <AddIcon
              fontSize="small"
              sx={{
                color: theme.palette.background.paper,
                marginRight: theme.spacing(2)
              }}
            />

            {t("buttons__new_reservation")}
          </Grid>
        </Button>
      </Grid>
      {!!reservation?.restaurantReservations?.length && (
        <Paper sx={{ p: 3 }} elevation={0}>
          <Heading3 sx={{ marginBottom: 1.5 }}>{t("labels__restaurant_reservations")}</Heading3>
          <Box sx={{ overflowX: "auto" }}>
            <Box sx={{ minWidth: isMobile ? "220%" : "unset", width: "100%" }}>
              <Grid container alignItems="center" pb={0.5}>
                <Grid item xs={2.5} pl={1.5}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_venue_name")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_date")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_guests")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_table_number")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1.5}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_created_at")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={2}>
                  <ParagraphSmall color="text.primary">
                    {t("labels__reservation__details__restaurant_note")}
                  </ParagraphSmall>
                </Grid>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={0.5}></Grid>
              </Grid>
              <Grid container>
                {reservation.restaurantReservations?.map((restaurantReservation) => (
                  <Grid
                    item
                    xs={12}
                    key={`${restaurantReservation?.foodAndBeveragePropertyId}_${restaurantReservation?.reservationDateTime}`}
                    paddingBottom={1.5}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        background: grey[100],
                        borderColor: grey[100],
                        borderRadius: "5px",
                        pr: 1
                      }}
                      variant="outlined"
                    >
                      <Grid
                        container
                        direction="row"
                        py={1.5}
                        alignItems="center"
                        position="relative"
                      >
                        <Grid item xs={2.5} md={2.5} pl={2}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.foodAndBeveragePropertyName}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={2}>
                          {restaurantReservation?.reservationDateTime && (
                            <>
                              {formatDate(
                                restaurantReservation?.reservationDateTime,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                              {" | "}
                              {formatTime(
                                restaurantReservation?.reservationDateTime,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                            </>
                          )}
                        </Grid>
                        <Grid item xs={1} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.actualNumberOfGuests ??
                              restaurantReservation?.expectedNumberOfGuests ??
                              ""}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.tableNumbers?.map((table, index) => {
                              return `${table}${
                                restaurantReservation?.tableNumbers?.length &&
                                index === restaurantReservation?.tableNumbers?.length - 1
                                  ? ""
                                  : ", "
                              }`;
                            })}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1.5} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            <>
                              {formatDate(
                                restaurantReservation?.createdAt,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                              {" | "}
                              {formatTime(
                                restaurantReservation?.createdAt,
                                language,
                                selectedProperty?.details.timeZone
                              )}
                            </>
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={2} pl={0.5}>
                          <ParagraphSmall color="text.primary">
                            {restaurantReservation?.notes ?? ""}
                          </ParagraphSmall>
                        </Grid>
                        <Grid item xs={1.5} textAlign="center">
                          <Chip
                            size="small"
                            variant="outlined"
                            color={getChipColor(restaurantReservation?.status)}
                            label={t(
                              `labels__restaurant_reservation_status_${restaurantReservation?.status}`
                            )}
                          />
                        </Grid>
                        <Grid item xs={0.5}>
                          <ActionMenu
                            items={menuItems}
                            handleAction={(item) => {
                              handleAction(
                                item,
                                restaurantReservation?.foodAndBeverageReservationId
                              );
                              setShowComponent(!showComponent);
                            }}
                            disabled={!menuItems.length || !sevenRoomsWidgetConfig}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        </Paper>
      )}
      {!reservation?.restaurantReservations?.length && (
        <Box>
          <EmptyState
            title={t("labels__reservation__details__restaurant_no_reservations")}
            size={EmptyStateSize.LARGE}
          />
        </Box>
      )}

      {sevenRoomsWidgetConfig && (
        <SevenRoomsLoader url={sevenRoomsWidgetConfig.url}>
          <SevenroomsWidget
            guestIdentityId={person.guestIdentityId}
            firstName={person.firstName}
            lastName={person.lastName}
            email={person.email}
            phone={person.phone}
            locale={person.preferredLocale}
            hotelReservationArrivalDate={reservation.arrival ?? ""}
            hotelReservationDepartureDate={reservation.departure ?? ""}
            amountOfPeople={reservation.adultsCount}
            //TODO check if there is admin mode ?
            mode="GUEST"
            restaurantReservationId={selectedReservationId ?? ""}
            toggle={showComponent}
            reservationId={reservation.id}
            onClose={(toggle: boolean) => {
              setSelectedReservationId(undefined);
              setShowComponent(toggle);
            }}
            apiKey={sevenRoomsWidgetConfig?.apiKey}
          />
        </SevenRoomsLoader>
      )}
    </Box>
  );
};
