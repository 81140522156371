import { useTheme } from "@mui/material";
import { FC } from "react";

interface TitleHeaderProps {
  title: string;
  length?: number;
}

export const TitleHeader: FC<TitleHeaderProps> = ({ title, length }) => {
  const { palette } = useTheme();

  return (
    <>
      <span>{title}</span>
      {length != null && length > 0 && (
        <span style={{ fontVariantLigatures: "none" }}>
          <>&#160;</>
          <span style={{ color: palette.grey[500] }}>&#40;</span>
          <span style={{ color: palette.grey[500] }}>{length}</span>
          <span style={{ color: palette.grey[500] }}>&#41;</span>
        </span>
      )}
    </>
  );
};
