import { useApiVersion } from "@likemagic-tech/sv-magic-library";
import { useSetPreferredCleaningDaysMutation } from "src/store/endpoints/reservation-table.endpoints";
import { useSetPreferredCleaningDaysMutationEnhanced } from "../graphql/mutations/enhanced-mutations/set-preferred-cleaning-days-enhanced";

interface SetPreferredCleaningDaysProps {
  reservationId: string;
  payload: any;
}

export const useSetPreferredCleaningDays = () => {
  const { isRESTVersion } = useApiVersion();
  const [setPreferredCleaningDays, resultV1] = useSetPreferredCleaningDaysMutation();

  const [setPreferredCleaningDaysV2Enhanced, resultV2] =
    useSetPreferredCleaningDaysMutationEnhanced();

  const setPreferredCleaningDaysV1 = ({
    reservationId,
    payload
  }: SetPreferredCleaningDaysProps) => {
    return setPreferredCleaningDays({ reservationId, payload });
  };

  const setPreferredCleaningDaysV2 = ({
    reservationId,
    payload
  }: SetPreferredCleaningDaysProps) => {
    return setPreferredCleaningDaysV2Enhanced({
      pmsReservationId: reservationId,
      days: payload
    });
  };

  const functionCall = isRESTVersion ? setPreferredCleaningDaysV1 : setPreferredCleaningDaysV2;
  const result = isRESTVersion ? resultV1 : resultV2;

  return isRESTVersion
    ? ([functionCall, result] as [typeof setPreferredCleaningDaysV1, typeof resultV1])
    : ([functionCall, result] as [typeof setPreferredCleaningDaysV2, typeof resultV2]);
};
