import * as React from "react";
import { FC, useCallback, useMemo, useState } from "react";
import { Box, Container, Grid } from "@mui/material";
import { pickerDateSelector } from "../../slices/date-picker";
import { useSelector } from "../../store";
import { DatePicker } from "../../components/datepicker/date-picker";
import { useProperty } from "../../hooks/use-property";
import useSettings from "../../hooks/use-settings";
import { BreakfastCard } from "./breakfast-card";
import { BreakfastFilter } from "./breakfast-filter";
import { Report, ReportStatus } from "../../domain/report";
import { useDateSearchParams } from "../../hooks/use-date-search-params";
import OverviewBreakfastCard from "../../components/dashboard/overview/overview-breakfast-card";
import PageTitle from "src/components/page-title";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { LocalizationDatePicker } from "src/components/language-selector/localization-date-picker";
import { useNavigateWithPropertyId } from "../../hooks/use-navigate-with-propertyId";
import { SearchField } from "../../components/search-field/search-field";
import { useFetchBreakfast } from "./use-fetch-breakfast";

export const BreakfastPage: FC = () => {
  const { t } = useTranslationWrapper();
  const navigate = useNavigateWithPropertyId();
  const date = useSelector(pickerDateSelector);
  const { settings } = useSettings();
  const { selectedProperty, selectedPropertyPreview } = useProperty();
  const { breakfastReports, fetchBreakfastAction } = useFetchBreakfast({
    propertyId: selectedProperty?.propertyId,
    date
  });

  const countForBreakfastCondition = breakfastReports.reduce(
    (acc, breakfastReport) => {
      if (breakfastReport.performed) {
        acc.OBTAINED += 1;
      } else {
        acc.BOOKED += 1;
      }
      return acc;
    },
    { BOOKED: 0, OBTAINED: 0 }
  );

  const [statusFilter, setStatusFilter] = useState<ReportStatus | null>(ReportStatus.BOOKED);
  const [freeTextSearch, setFreeTextSearch] = useState<string>();
  const setConditionFilter = useCallback(
    (status: ReportStatus) => {
      if (status === statusFilter) {
        setStatusFilter(null);
      } else {
        setStatusFilter(status);
      }
    },
    [statusFilter]
  );

  const onDateChange = useCallback(
    (date: string) => {
      if (!selectedProperty?.propertyId) {
        return;
      }
      return fetchBreakfastAction({
        propertyId: selectedProperty?.propertyId,
        date
      });
    },
    [selectedProperty, fetchBreakfastAction]
  );
  useDateSearchParams(onDateChange);

  const filteredBreakfastReports: Report[] = useMemo(() => {
    const statusFilteredReports: Report[] = statusFilter
      ? breakfastReports.filter((report) => {
          return statusFilter === ReportStatus.BOOKED
            ? report.performed === false
            : report.performed === true;
        })
      : breakfastReports;

    return freeTextSearch
      ? statusFilteredReports.filter(
          (report) =>
            report.unitId?.toLowerCase().includes(freeTextSearch.toLowerCase()) ||
            (report.primaryGuestFirstName + " " + report.primaryGuestLastName)
              .toLowerCase()
              .includes(freeTextSearch.toLowerCase())
        )
      : statusFilteredReports;
  }, [breakfastReports, statusFilter, freeTextSearch]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={3}
            item
            xs={12}
          >
            <PageTitle subTitle={selectedPropertyPreview} title={t("labels__breakfast")} />
            <Grid container alignItems="center" spacing={3} px={3} direction="row" pt={1}>
              <Grid item style={{ minWidth: 400 }}>
                <SearchField
                  value={freeTextSearch ?? ""}
                  onChange={setFreeTextSearch}
                  placeholder={t("labels__breakfast_search")}
                />
              </Grid>
              <Grid item>
                <BreakfastFilter
                  value={statusFilter ?? undefined}
                  onConditionSelected={setConditionFilter}
                  countForBreakfastCondition={countForBreakfastCondition}
                />
              </Grid>
              <Grid item>
                <LocalizationDatePicker>
                  <DatePicker timeZone={selectedProperty?.details.timeZone ?? ""} />
                </LocalizationDatePicker>
              </Grid>
            </Grid>

            <Grid item md={12} xs={12}>
              <OverviewBreakfastCard navigate={navigate} />
            </Grid>
            <Grid container item xs={12} spacing={2} pb={3}>
              {filteredBreakfastReports.map((report) => (
                <BreakfastCard
                  key={report.reservationId + "_" + report.serviceId}
                  propertyId={selectedProperty?.propertyId ?? ""}
                  report={report}
                />
              ))}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
