import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Box, Button, Grid, IconButton, useTheme } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { experimentalStyled } from "@mui/material/styles";
import { useDebounce } from "../../hooks/use-debounce";
import {
  formatDate,
  pickerDateSelector,
  setDate as setDateReducer
} from "../../slices/date-picker";
import { useDispatch, useSelector } from "../../store";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import { format, isSameDay } from "date-fns";
import { ParagraphSmallBold } from "@likemagic-tech/sv-magic-library";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import useDynamicLocale from "src/hooks/use-dynamic-locale";
import { utcToZonedTime } from "date-fns-tz";

const DatePickerWrapper = experimentalStyled("div")(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
}));

interface DatePickerProps {
  timeZone: string;
  disablePast?: boolean;
}

export const DatePicker: FC<DatePickerProps> = ({ timeZone, disablePast }) => {
  const { t } = useTranslationWrapper();
  const dispatch = useDispatch();
  const date = useSelector(pickerDateSelector);
  const locale = useDynamicLocale();

  const setDate = useCallback(
    (newDate: Date) => {
      dispatch(setDateReducer(formatDate(newDate, timeZone)));
    },
    [dispatch, timeZone]
  );
  const [internalDate, setInternalDate] = useState<Date | null>(new Date(date));
  useEffect(() => {
    setInternalDate(new Date(date));
  }, [date]);

  const resetDate = useCallback(() => {
    setInternalDate(new Date());
  }, []);

  const decreaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() - 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const increaseDateByDay = useCallback(() => {
    const newDate = internalDate?.getTime() ? new Date(internalDate?.getTime()) : null;
    newDate?.setDate(newDate.getDate() + 1);
    setInternalDate(newDate);
  }, [internalDate]);

  const dateWithDebounce = useDebounce(internalDate, 500);
  useEffect(() => {
    if (!dateWithDebounce) {
      return;
    }
    setDate(dateWithDebounce);
  }, [dateWithDebounce, setDate]);

  const theme = useTheme();

  const disablePastDayButton = useMemo(() => {
    return disablePast ? isSameDay(new Date(date), new Date()) : false;
  }, [disablePast, date]);

  return (
    <DatePickerWrapper>
      <Button
        variant="contained"
        sx={{
          backgroundColor: theme.palette.text.primary,
          borderRadius: theme.spacing(0.5),
          p: theme.spacing(0.75)
        }}
        onClick={resetDate}
      >
        <ParagraphSmallBold sx={{ color: theme.palette.background.paper }}>
          {t("labels__today")}
        </ParagraphSmallBold>
      </Button>
      <Grid container ml={2} alignItems="center">
        <Grid item>
          <IconButton
            onClick={decreaseDateByDay}
            sx={{
              p: theme.spacing(0.625),
              borderRadius: theme.spacing(0.5),
              border: `1px solid ${theme.palette.text.primary}`,
              left: theme.spacing(0.5)
            }}
            size="large"
            disabled={disablePastDayButton}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
        </Grid>
        <Grid item>
          <Box
            sx={{
              minWidth: theme.spacing(15),
              p: theme.spacing(0.625),
              borderTop: `1px solid ${theme.palette.text.primary}`,
              borderBottom: `1px solid ${theme.palette.text.primary}`
            }}
          >
            <MobileDatePicker
              showToolbar={false}
              value={internalDate}
              onChange={(newDate: Date | null) => setInternalDate(newDate)}
              disableCloseOnSelect={false}
              renderInput={(inputProps) => (
                <ParagraphSmallBold
                  mx={2}
                  align="center"
                  color="textPrimary"
                  onClick={inputProps?.inputProps?.onClick}
                >
                  {format(utcToZonedTime(date, timeZone), "EEE, MMM dd", { locale })}
                </ParagraphSmallBold>
              )}
              disablePast={disablePast}
            />
          </Box>
        </Grid>
        <Grid item>
          <IconButton
            onClick={increaseDateByDay}
            sx={{
              p: theme.spacing(0.625),
              border: `1px solid ${theme.palette.text.primary}`,
              borderRadius: theme.spacing(0.5),
              right: theme.spacing(0.5)
            }}
            size="large"
          >
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Grid>
    </DatePickerWrapper>
  );
};
