import { FC } from "react";
import { Box } from "@mui/material";
import { useTranslationWrapper } from "../../../../hooks/use-translation-wrapper";
import { Chip } from "@likemagic-tech/sv-magic-library";

interface CheckboxFilterProps {
  values: Array<string>;
  options: Array<string>;
  onChange: (values: Array<string>) => void;
}

export const CheckboxFilter: FC<CheckboxFilterProps> = ({ values, options, onChange }) => {
  const isOptionSelected = (option: string) => values.indexOf(option) !== -1;
  const { t } = useTranslationWrapper();

  const toggleCheckbox = (option: string) => {
    const index = values.indexOf(option);
    if (index === -1) {
      onChange(values.concat(option));
    } else {
      onChange(
        values.reduce((acc, next) => {
          if (next !== option) {
            return acc.concat(next);
          }
          return acc;
        }, [] as string[])
      );
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      {options.map((option) => (
        <Chip
          onClick={() => {
            toggleCheckbox(option);
          }}
          label={t(`labels__additional_filter_${option}`)}
          variant={isOptionSelected(option) ? "filled" : "outlined"}
          color="primary"
          key={option}
          sx={{
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            width: "fit-content"
          }}
        />
      ))}
    </Box>
  );
};
