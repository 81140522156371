import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import * as React from "react";
import { useForecastQuery } from "../../graphql-tasks/query/Forecast.generated";
import { useProperty } from "../../hooks/use-property";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import {
  formatDate,
  getShortDayName,
  ParagraphSmall,
  ParagraphSmallBold
} from "@likemagic-tech/sv-magic-library";
import { makeStyles } from "tss-react/mui";
import { TableHead, TableRow, useTheme } from "@mui/material";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { getI18nSelectedLanguage } from "src/utils/language";

const useStyles = makeStyles()((theme) => ({
  dateCell: {
    backgroundColor: theme.palette.background.paper,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 2px 8px 0px rgba(126, 128, 138, 0.40)`,
    paddingLeft: theme.spacing(2.5),
    position: "sticky",
    left: 0
  },
  cell: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  }
}));

export const HousekeepingForecast = () => {
  const { classes } = useStyles();
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const property = useProperty();
  const { data } = useForecastQuery({ pmsPropertyId: property.selectedProperty?.propertyId ?? "" });
  const forecast = data?.Forecast ?? [];
  const language = getI18nSelectedLanguage();

  const serviceTasks =
    forecast
      ?.flatMap((item) => Object.keys(item.serviceTaskCounts))
      ?.filter((value, index, array) => array.indexOf(value) === index)
      .sort() || [];

  const checkForZero = (count: number) => {
    return (
      <ParagraphSmall color={count ? theme.palette.text.primary : theme.palette.grey[600]}>
        {count ?? 0}
      </ParagraphSmall>
    );
  };

  return (
    <Paper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.dateCell}
                sx={{
                  borderTopLeftRadius: theme.spacing(1)
                }}
              >
                <ParagraphSmallBold>{t("labels__housekeeping_forecast_date")}</ParagraphSmallBold>
              </TableCell>
              <TableCell className={classes.cell}>
                <ParagraphSmallBold>{t("labels__departure_cleaning")}</ParagraphSmallBold>
              </TableCell>
              <TableCell className={classes.cell}>
                <ParagraphSmallBold>{t("labels__stayover_cleaning")}</ParagraphSmallBold>
              </TableCell>
              {serviceTasks &&
                serviceTasks.map((service, index) => (
                  <TableCell key={index}>
                    <ParagraphSmallBold>{service}</ParagraphSmallBold>
                  </TableCell>
                ))}
              <TableCell className={classes.cell}>
                <ParagraphSmallBold>
                  {t("labels__housekeeping_forecast_summary")}
                </ParagraphSmallBold>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forecast?.map((forecastItem, index) => (
              <TableRow key={index}>
                <TableCell
                  className={classes.dateCell}
                  sx={{
                    display: "flex",
                    gap: theme.spacing(1)
                  }}
                >
                  <ParagraphSmall>
                    {formatDate(
                      forecastItem.date,
                      language,
                      property.selectedProperty?.details.timeZone
                    )}
                  </ParagraphSmall>
                  <ParagraphSmall color={theme.palette.grey[600]}>
                    ({getShortDayName(forecastItem.date)})
                  </ParagraphSmall>
                </TableCell>
                <TableCell className={classes.cell}>
                  {checkForZero(forecastItem.departureCleaningCount)}
                </TableCell>
                <TableCell className={classes.cell}>
                  {checkForZero(forecastItem.stayoverCleaningCount)}
                </TableCell>
                {serviceTasks.map((service, index) => (
                  <TableCell key={index}>
                    {checkForZero(forecastItem.serviceTaskCounts[service])}
                  </TableCell>
                ))}
                <TableCell className={classes.cell}>{checkForZero(forecastItem.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
