import { useUserInfo } from "./use-user-info";
import { useGetEmployeesQuery } from "../graphql-messaging/queries/GetActions.generated";
import { useMemo } from "react";

export const useEmployee = (pmsPropertyIds: string[]) => {
  const { userInfo } = useUserInfo();
  const { data: employees } = useGetEmployeesQuery({ pmsPropertyIds: pmsPropertyIds });

  const currentEmployee = useMemo(
    () => employees?.GetEmployees?.find((item) => item.keycloakUUID === userInfo?.sub),
    [employees?.GetEmployees, userInfo?.sub]
  );

  const currentEmployeeActorId = useMemo(() => currentEmployee?.id ?? "", [currentEmployee?.id]);
  return {
    currentEmployee,
    currentEmployeeActorId,
    employees: employees?.GetEmployees ?? []
  };
};
