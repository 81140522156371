import { QuickFilterCheckbox } from "../../components/quick-filter-checkbox/quick-filter-checkbox";
import { FilterMapInterface, FilterObjectType } from "../../components/table/filters/table-filters";
import { FilterOperator } from "../../types/filters/filters";
import * as React from "react";
import { useCallback } from "react";
import { useCurrentActor } from "../../hooks/use-current-actor";
import { useSearchFilterParams } from "../../components/table/hooks/use-search-filter-params";
import { useTranslationWrapper } from "../../hooks/use-translation-wrapper";
import { Grid } from "@mui/material";
import { HousekeepingTaskOwnershipFilters } from "../../domain/housekeeping-task-ownership-filters";
import { housekeepingFilterParamSelector } from "../../utils/housekeeping";

export const HousekeepingFilters = ({
  currentActorFilterCount,
  teamFilterCount
}: {
  currentActorFilterCount: number;
  teamFilterCount: number;
}) => {
  const { currentActorId } = useCurrentActor();
  const { filterMap, setFilterMap } = useSearchFilterParams();
  const { t } = useTranslationWrapper();
  const onFiltersChange = useCallback(
    (filters: FilterMapInterface) => {
      setFilterMap(filters);
    },
    [setFilterMap]
  );
  return (
    <Grid item display="flex" gap={1}>
      <QuickFilterCheckbox
        key={HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME}
        isSingleValue
        condition={HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME}
        value={currentActorId}
        count={currentActorFilterCount}
        filterMap={filterMap}
        filterType={FilterObjectType.AUTOCOMPLETE}
        filter={{
          name:
            housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_ME) ?? "",
          type: FilterObjectType.AUTOCOMPLETE,
          operator: FilterOperator.Equality
        }}
        label={t("labels__my_assignments")}
        onFilterMapChange={onFiltersChange}
      />
      <QuickFilterCheckbox
        key={HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM}
        isSingleValue
        condition={HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM}
        count={teamFilterCount}
        filterMap={filterMap}
        filterType={FilterObjectType.AUTOCOMPLETE}
        filter={{
          name:
            housekeepingFilterParamSelector(HousekeepingTaskOwnershipFilters.ASSIGNED_TO_TEAM) ??
            "",
          type: FilterObjectType.AUTOCOMPLETE,
          operator: FilterOperator.Equality
        }}
        label={t("labels__team_assignments")}
        onFilterMapChange={onFiltersChange}
      />
    </Grid>
  );
};
