import { EmptyState, EmptyStateSize } from "@likemagic-tech/sv-magic-library";
import React, { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { isBefore } from "date-fns";
import { Task } from "../../../../../tasks/domain/task";
import { TaskPreview } from "src/features/tasks/task-preview";

interface CardTasksInfoProps {
  tasks: Task[];
}

export const taskDateOverdue = (date: string) => {
  if (date) {
    const currentDate = new Date();
    const taskDate = new Date(date);

    return isBefore(taskDate, currentDate);
  }
};

export const CardTasksInfo: FC<CardTasksInfoProps> = ({ tasks }) => {
  const { t } = useTranslationWrapper();

  return (
    <>
      {tasks.length > 0 ? (
        tasks.map((task) => <TaskPreview task={task} key={task.id} displayReservationId={false} />)
      ) : (
        <EmptyState title={t("labels__no__tasks__in__reservation")} size={EmptyStateSize.REGULAR} />
      )}
    </>
  );
};
