import { BaseApiClient, JSONApiResponse } from "@likemagic-tech/sv-magic-library";
import { FileUpload } from "src/domain/file-upload";
import { convertBlobToBase64 } from "./guest-journey.api";

const BASE_URl = "/api/unified-messaging/";
class MessagingUploadClient extends BaseApiClient {
  async uploadFileToReservation(
    arg: { file: File; authToken: string },
    init?: RequestInit
  ): Promise<FileUpload> {
    const { file, authToken } = arg;
    const formData = new FormData();
    formData.append("file", file);
    const response = await this.fetch(BASE_URl + "attachments", {
      ...init,
      method: "POST",
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    });
    return new JSONApiResponse<FileUpload>(response).value();
  }

  async fetchAttachment(
    arg: { url: string; authToken: string },
    init?: RequestInit
  ): Promise<string> {
    const response = await this.fetchApi(BASE_URl + "attachments/messagebird?fullUrl=" + arg.url, {
      ...init,
      headers: {
        ...init?.headers,
        Authorization: `Bearer ${arg.authToken}`
      }
    });
    const blob = await response.blob();
    return convertBlobToBase64(blob);
  }
}

export const MessagingUploadApi = new MessagingUploadClient();
