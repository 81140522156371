import { Box, Grid, Slider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { formatISO, subHours } from "date-fns";
import React, { FC, useCallback, useMemo, useState } from "react";
import { formatTime, formatToIsoDate, Paragraph } from "@likemagic-tech/sv-magic-library";
import { ServiceModalsProps } from "./types";
import { useTranslationWrapper } from "../../../../../../hooks/use-translation-wrapper";
import { useProperty } from "../../../../../../hooks/use-property";
import { Dialog } from "./dialog";
import { PricePreview } from "../../../../../../components/price-preview/price-preview";
import { multiplyPrices } from "../../../../../../utils/price";
import { emptyPrice } from "../../../../../../domain/price";
import { getI18nSelectedLanguage } from "src/utils/language";

const useStyles = makeStyles()((theme) => ({
  dialogContentContainer: {
    alignSelf: "center",
    width: "100%",
    minWidth: 350
  },
  spaceNumberSlider: {
    paddingLeft: theme.spacing(6)
  }
}));

export const EarlyCheckinPickerDialog: FC<ServiceModalsProps> = ({
  onClose,
  onChange,
  reservation,
  service
}) => {
  const [hours, setHours] = useState(service?.maximumHours);
  const { t } = useTranslationWrapper();
  const { selectedProperty } = useProperty();
  const { classes } = useStyles();
  const language = getI18nSelectedLanguage();

  const updatedArrivalTime = useMemo(() => {
    if (reservation?.contractualCheckInTime) {
      const newArrival = subHours(
        new Date(reservation.contractualCheckInTime),
        service.maximumHours - hours
      );
      return formatISO(newArrival);
    }
  }, [reservation?.contractualCheckInTime, hours, service.maximumHours]);

  const handleChange = useCallback(() => {
    const values = [
      {
        date: formatToIsoDate(new Date(reservation?.contractualCheckInTime!)),
        count: service.maximumHours - hours
      }
    ].filter((item) => item.count);
    onChange({ values });
  }, [hours, reservation?.contractualCheckInTime, onChange, service.maximumHours]);

  return (
    <Dialog
      title={t("labels__wizard_early_checkin_picker_title")}
      open
      onConfirm={handleChange}
      onDismiss={onClose}
      content={
        <>
          <Box m={2}>
            <Grid container direction="row" mt={3}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">
                  {t("labels__wizard_current_departure")}
                </Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(
                    reservation?.contractualCheckInTime,
                    language,
                    selectedProperty?.details.timeZone
                  )}
                </Paragraph>
              </Grid>
            </Grid>
            <Grid container direction="row" mt={1} mb={2}>
              <Grid xs={6} item>
                <Paragraph color="text.secondary">{t("labels__wizard_new_departure")}</Paragraph>
              </Grid>
              <Grid item xs={6}>
                <Paragraph sx={{ textAlign: "right" }}>
                  {formatTime(updatedArrivalTime, language, selectedProperty?.details.timeZone)}
                </Paragraph>
              </Grid>
            </Grid>
          </Box>
          <Grid container flexWrap="nowrap" alignItems="center" px={5}>
            <Slider
              track="inverted"
              value={hours}
              onChange={(event, newValue) => {
                setHours(newValue as number);
              }}
              max={service.maximumHours}
              min={0}
              aria-labelledby="input-slider"
            />
            <Paragraph className={classes.spaceNumberSlider}>
              {service.maximumHours - hours}
            </Paragraph>
          </Grid>
          <Box display="flex" alignItems="baseline" justifyContent="center" px={2.5} py={2}>
            <PricePreview
              price={multiplyPrices(service?.price ?? emptyPrice(), service.maximumHours - hours)}
            />
          </Box>
        </>
      }
      buttonLabel={t("buttons__confirm")}
    />
  );
};
