import * as React from "react";
import { FC, useCallback, useMemo } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import {
  DateRangeFilter,
  DateRangeFilterType,
  DateRangeFilterValue
} from "./components/date-range-filter";
import { FilterOperator } from "../../../types/filters/filters";
import Minus from "../../../icons/Minus";
import {
  FilterMapInterface,
  FilterObjectType,
  FilterObjectWithType,
  serializeIdOfFilter
} from "./table-filters";
import { isDate } from "date-fns";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { Add } from "@mui/icons-material";
import { formatDateForBE, Button } from "@likemagic-tech/sv-magic-library";

interface DateFiltersProps {
  filterMap: FilterMapInterface;
  setFilterMap: (value: FilterMapInterface) => void;
  onRemoveFilter: (value: Array<string>) => void;
}

export const dateFilterToFilterObject = (
  filter: DateRangeFilterValue
): Array<FilterObjectWithType> => {
  return filter.type
    ? [
        {
          name: filter.type,
          operator: FilterOperator.GreaterOrEqual,
          type: FilterObjectType.DATE_RANGE,
          value: isDate(filter?.from) ? formatDateForBE(filter?.from ?? new Date()) : undefined
        },
        {
          name: filter.type,
          type: FilterObjectType.DATE_RANGE,
          operator: FilterOperator.LowerOrEqual,
          value: isDate(filter.to) ? formatDateForBE(filter.to ?? new Date()) : undefined
        }
      ]
    : [];
};

export const DateFilters: FC<DateFiltersProps> = ({ filterMap, setFilterMap, onRemoveFilter }) => {
  const { t } = useTranslationWrapper();

  const onDateFiltersChange = useCallback(
    (data: DateRangeFilterValue, oldType?: DateRangeFilterType) => {
      const temp: FilterMapInterface = dateFilterToFilterObject(data).reduce((acc, next) => {
        const copyOfAcc: FilterMapInterface = { ...acc };
        copyOfAcc[serializeIdOfFilter(next) as keyof typeof copyOfAcc] = next;
        return copyOfAcc;
      }, {} as FilterMapInterface);

      //TODO reconsider ID strategy
      if (oldType && data.type !== oldType) {
        const newFilterMap = { ...filterMap, ...temp };

        delete newFilterMap[
          serializeIdOfFilter({
            type: FilterObjectType.DATE_RANGE,
            operator: FilterOperator.GreaterOrEqual,
            name: oldType
          })
        ];

        delete newFilterMap[
          serializeIdOfFilter({
            type: FilterObjectType.DATE_RANGE,
            operator: FilterOperator.LowerOrEqual,
            name: oldType
          })
        ];

        delete newFilterMap[
          serializeIdOfFilter({
            type: FilterObjectType.DATE_RANGE,
            operator: undefined,
            name: oldType
          })
        ];
        // Logic for removing "In" date filters which are located in additional reservation filters
        // This is done because only one should be active and should not work in paralel.
        delete newFilterMap[
          serializeIdOfFilter({
            type: FilterObjectType.DATE_RANGE,
            operator: FilterOperator.In,
            name: oldType
          })
        ];

        setFilterMap({ ...newFilterMap });
      } else {
        // Logic for removing "In" date filters which are located in additional reservation filters
        // This is done because only one should be active and should not work in paralel.
        const newFilterMap = { ...filterMap, ...temp };
        delete newFilterMap[
          serializeIdOfFilter({
            type: FilterObjectType.DATE_RANGE,
            operator: FilterOperator.In,
            name: oldType
          })
        ];
        setFilterMap({ ...newFilterMap });
      }
    },
    [filterMap, setFilterMap]
  );

  const dateFilters: Array<DateRangeFilterValue> = useMemo(() => {
    return Object.keys(filterMap)
      .filter((filterKey) => {
        return (
          filterKey.startsWith(FilterObjectType.DATE_RANGE) &&
          !filterKey.endsWith(FilterOperator.Equality) &&
          !filterKey.endsWith(FilterOperator.In)
        );
      })
      .reduce((acc: Array<DateRangeFilterValue>, nextFilterKey) => {
        const next = filterMap[nextFilterKey];
        const indexOfFilter = acc.findIndex((item) => item.type === next.name);
        if (indexOfFilter !== -1) {
          const temp = { ...acc[indexOfFilter] };

          temp[next.operator === FilterOperator.GreaterOrEqual ? "from" : "to"] = next.value
            ? new Date(next.value)
            : undefined;

          acc[indexOfFilter] = temp;
          return acc;
        } else {
          return acc.concat({
            id: serializeIdOfFilter(next),
            type: next.name,
            from:
              next.operator === FilterOperator.GreaterOrEqual
                ? next.value
                  ? new Date(next.value)
                  : null
                : undefined,
            to:
              next.operator === FilterOperator.LowerOrEqual
                ? next.value
                  ? new Date(next.value)
                  : null
                : null
          } as DateRangeFilterValue);
        }
      }, []);
  }, [filterMap]);

  const addNewDateFilter = useCallback(() => {
    const alreadySelectedFields = dateFilters.map((item) => item?.type?.toString());
    const firstAvailableType = Object.keys(DateRangeFilterType).filter(
      (item) =>
        alreadySelectedFields.indexOf(
          DateRangeFilterType[item as keyof typeof DateRangeFilterType]
        ) === -1
    )[0];

    if (firstAvailableType) {
      const filter = {
        type: DateRangeFilterType[firstAvailableType as keyof typeof DateRangeFilterType],
        to: undefined,
        from: undefined
      };
      onDateFiltersChange({
        id: serializeIdOfFilter(filter),
        ...filter
      });
    }
  }, [dateFilters, onDateFiltersChange]);

  return (
    <>
      {dateFilters.length === 0 ? (
        <Grid item xs={11} py={1}>
          <DateRangeFilter
            onChange={(data, oldType) =>
              onDateFiltersChange(data, oldType === "" ? undefined : oldType)
            }
            value={{
              id: "",
              type: DateRangeFilterType.ARRIVAL,
              from: undefined,
              to: undefined
            }}
            // @ts-ignore
            alreadySelectedTypes={dateFilters.map((item) => item.type)}
            size="small"
          />
        </Grid>
      ) : null}
      {dateFilters.map((filterValue) => (
        <Grid container py={1} alignItems="center" key={`date-filter-${filterValue.id}`}>
          <Grid item xs={11}>
            <DateRangeFilter
              onChange={(data, oldType) =>
                onDateFiltersChange(data, oldType === "" ? undefined : oldType)
              }
              value={filterValue}
              // @ts-ignore
              alreadySelectedTypes={dateFilters.map((item) => item.type)}
              size="small"
            />
          </Grid>

          <Grid item xs={1} textAlign="right">
            <IconButton
              onClick={() => {
                onRemoveFilter([
                  serializeIdOfFilter({
                    type: FilterObjectType.DATE_RANGE,
                    operator: FilterOperator.GreaterOrEqual,
                    name: filterValue.type
                  }),
                  serializeIdOfFilter({
                    type: FilterObjectType.DATE_RANGE,
                    operator: FilterOperator.LowerOrEqual,
                    name: filterValue.type
                  })
                ]);
              }}
            >
              <Minus />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      {dateFilters.length < Object.keys(DateRangeFilterType).length && (
        <Grid container alignItems="center" justifyContent="left">
          <Button variant="ghost" size="medium" onClick={addNewDateFilter} startIcon={<Add />}>
            {t("labels__add_date_filter")}
          </Button>
        </Grid>
      )}
      <Box mt={1} />
    </>
  );
};
