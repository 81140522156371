import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useProperty } from "../../../hooks/use-property";
import { useTaskModal } from "../use-task-select-id";
import { useGetTaskQueryEnhanced } from "../../../graphql-tasks/query/enhanced-queries/get-task-enhanced";
import { Params } from "../../../hooks/use-select-id";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import { DialogModal } from "../../../components/submit-modal/dialog";
import { Form, Formik } from "formik";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  useTheme
} from "@mui/material";
import { Button, formatDateTime, Heading2, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Status } from "../../../graphql-tasks/generated/graphql";
import { Attachments, Task, TaskPriority } from "../domain/task";
import { useFormValidations } from "../../../hooks/use-form-validation";
import { useUpdateTaskMutationEnhanced } from "../../../graphql-tasks/mutations/enhanced-mutations/update-tasks-enhanced";
import { useCreateTaskMutationEnhanced } from "../../../graphql-tasks/mutations/enhanced-mutations/create-task-enhanced";
import {
  transformSubtask,
  transformTask,
  transformTaskFormToCreateTaskRequest,
  transformTaskFormToUpdateTaskRequest
} from "./task-modal.utils";
import { openBanner } from "../../../slices/banner.slice";
import { useDispatch } from "react-redux";
import { TaskForm } from "./task-form/task-form";
import { useIsMobile } from "../../../hooks/use-is-mobile";
import { tasksApi } from "../../../graphql-tasks/tasks.api";
import CloseIcon from "@mui/icons-material/Close";
import { getI18nSelectedLanguage } from "src/utils/language";

export interface SubtasksForm {
  title: string;
  status: Status;
  dueDate: string;
  parentId?: number;
  id?: number;
  isEdit: boolean;
  assignee?: { id: string; label: string };
}

export interface TaskFormValues {
  id: number;
  title: string;
  status: Status;
  priority: TaskPriority;
  description?: string;
  attachments?: Attachments;
  dueDate?: string;
  dueTime?: string;
  assigneeGroup?: { id: string; label: string };
  assignee?: { id: string; label: string };
  parentId?: number;
  linkedUnitId: string;
  linkedPmsReservationId: string;
  linkedDisplayableReservationId: string;
  linkedPmsArrivingReservationId: string;
  linkedDisplayableArrivingReservationId: string;
  linkedPmsDepartingReservationId: string;
  linkedDisplayableDepartingReservationId: string;
  subtasksOnCreate?: SubtasksForm[];
}

export const TaskModal: FC = () => {
  const { taskId, closeModal, selectedId } = useTaskModal({
    type: Params.TASK_LINK_TYPE_URL_PARAM,
    typeId: Params.TASK_LINK_ID_URL_PARAM
  });
  const { t } = useTranslationWrapper();
  const theme = useTheme();
  const { taskFormValidation } = useFormValidations();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const [updateTaskAction, updateResult] = useUpdateTaskMutationEnhanced();
  const [createTaskAction, createResult] = useCreateTaskMutationEnhanced();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (updateResult?.data?.UpdateTask?.id) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }

    if (createResult?.data?.CreateTask?.id) {
      dispatch(
        openBanner({
          type: "success",
          title: t("labels__action_successfully_performed")
        })
      );
    }

    if (updateResult.isError || createResult.isError) {
      dispatch(
        openBanner({
          type: "error",
          title: t("labels__action_failed_to_perform")
        })
      );
    }
  }, [dispatch, updateResult, t, createResult?.data?.CreateTask?.id, createResult.isError]);

  const { data, isLoading } = useGetTaskQueryEnhanced(
    {
      taskId: parseInt(taskId ?? ""),
      pmsPropertyId: selectedProperty?.propertyId ?? ""
    },
    {
      skip: !(taskId && selectedProperty?.propertyId) || taskId === Params.CREATE_TASK_PARAM
    }
  );

  const task = data?.GetTask;

  const initialValues: TaskFormValues = useMemo(() => {
    return transformTask(task as Task, selectedId ?? "");
  }, [selectedId, task]);

  const isCreateTask = useMemo(
    () => taskId === Params.CREATE_TASK_PARAM && !initialValues.id,
    [initialValues.id, taskId]
  );

  const createTask = useCallback(
    async (values: TaskFormValues) => {
      const parentTask = await createTaskAction({
        pmsPropertyId: selectedProperty?.propertyId ?? "",
        request: transformTaskFormToCreateTaskRequest(values, task)
      }).unwrap();

      await Promise.all(
        values?.subtasksOnCreate?.map((subtask) => {
          return createTaskAction({
            pmsPropertyId: selectedProperty?.propertyId ?? "",
            request: transformTaskFormToCreateTaskRequest(
              subtask,
              undefined,
              parentTask?.CreateTask.id
            )
          }).unwrap();
        }) ?? []
      );

      closeModal();
    },
    [closeModal, createTaskAction, selectedProperty?.propertyId, task]
  );

  const updateTask = useCallback(
    async (values: TaskFormValues) => {
      await updateTaskAction({
        pmsPropertyId: selectedProperty?.propertyId ?? "",
        request: transformTaskFormToUpdateTaskRequest(values, task),
        taskId: parseInt(taskId ?? "0")
      }).unwrap();

      await tasksApi.util?.invalidateTags(["Tasks"]);
      closeModal();
    },
    [closeModal, selectedProperty?.propertyId, task, taskId, updateTaskAction]
  );

  const handleSubmit = useCallback(
    (values: TaskFormValues) => {
      isCreateTask ? createTask(values) : updateTask(values);
    },
    [createTask, isCreateTask, updateTask]
  );

  if (isLoading || !taskId) {
    return null;
  }

  return (
    <DialogModal isOpen={!!taskId} handleClose={closeModal} maxWidth="md">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={taskFormValidation}
        enableReinitialize
      >
        {(formik) => {
          return (
            <>
              <DialogTitle sx={{ width: "100%", pb: 0 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Heading2 sx={{ color: theme.palette.text.primary }}>
                    {task ? `TASK-${task.id}` : t("labels__create_task")}
                  </Heading2>
                  <IconButton onClick={closeModal}>
                    <CloseIcon fontSize="medium" />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent
                sx={{
                  width: "100%",
                  p: 2,
                  overflowX: "hidden",
                  "& .MuiSvgIcon-root": {
                    fontSize: 20
                  }
                }}
              >
                <Form noValidate id="TaskForm" style={{ width: "100%" }}>
                  <TaskForm
                    handleChange={formik.handleChange}
                    values={formik.values}
                    errors={formik.errors}
                    touched={formik.touched}
                    setFieldValue={formik.setFieldValue}
                    subtasks={
                      task?.subtasks?.map((subtask) => transformSubtask(subtask as Task)) ?? []
                    }
                    setSubtasksOnCreate={(subtasks) =>
                      formik.setFieldValue("subtasksOnCreate", subtasks)
                    }
                  />
                </Form>
                {task && (
                  <Box>
                    <Divider sx={{ my: 2, mx: 1.25 }} />
                    <Grid container mx={1.5} direction={isMobile ? "column" : "row"}>
                      <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
                        <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                          {t("labels__task_type")}:
                        </ParagraphSmall>
                        <ParagraphSmall>{t(`labels__task_type_${task.type}`)}</ParagraphSmall>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
                        <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                          {t("labels__reporter")}:
                        </ParagraphSmall>
                        <ParagraphSmall>{task.reporter.displayName}</ParagraphSmall>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
                        <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                          {t("labels__created_at")}:
                        </ParagraphSmall>
                        <ParagraphSmall>
                          {formatDateTime(
                            task.createdAt,
                            language,
                            selectedProperty?.details.timeZone
                          )}
                        </ParagraphSmall>
                      </Box>
                      <Box sx={{ display: "flex", gap: 1, mr: 2 }}>
                        <ParagraphSmall sx={{ color: theme.palette.text.secondary }}>
                          {t("labels__updated_at")}:
                        </ParagraphSmall>
                        <ParagraphSmall>
                          {formatDateTime(
                            task.updatedAt,
                            language,
                            selectedProperty?.details.timeZone
                          )}
                        </ParagraphSmall>
                      </Box>
                    </Grid>
                  </Box>
                )}
              </DialogContent>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", width: "100%", px: 3, py: 2 }}
              >
                <DialogActions sx={{ p: "0" }}>
                  <Button
                    size="medium"
                    variant="ghost"
                    onClick={() => closeModal()}
                    fullWidth
                    sx={{ mt: theme.spacing(2), mr: 1 }}
                  >
                    {t("buttons__cancel")}
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    size="medium"
                    fullWidth
                    sx={{ marginTop: theme.spacing(2) }}
                    onClick={() => formik.handleSubmit()}
                    disabled={!formik.dirty}
                  >
                    {t("buttons__save")}
                  </Button>
                </DialogActions>
              </Box>
            </>
          );
        }}
      </Formik>
    </DialogModal>
  );
};
