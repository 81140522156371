import { formatDateTime, Paragraph, ParagraphSmall } from "@likemagic-tech/sv-magic-library";
import { Divider, Grid, useTheme } from "@mui/material";
import { FC } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { Task } from "./domain/task";
import { useProperty } from "src/hooks/use-property";
import { useUnits } from "src/hooks/use-unit-by-id";
import { Status } from "src/graphql-tasks/generated/graphql";
import { DisplayTaskIcon } from "./components/display-task-icon";
import { statusColor } from "./components/status-cell";
import { taskDateOverdue } from "../reservations/reservation-details/reservation-details-tabs/overview/card-tasks/card-tasks-info";
import { useTaskModal } from "./use-task-select-id";
import { getI18nSelectedLanguage } from "src/utils/language";

interface TaskPreviewProps {
  task: Task;
  displayReservationId?: boolean;
}

export const TaskPreview: FC<TaskPreviewProps> = ({ task, displayReservationId = true }) => {
  const { t } = useTranslationWrapper();
  const { palette } = useTheme();
  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();
  const { data: units } = useUnits({
    propertyId: selectedProperty?.propertyId ?? ""
  });
  const unitId = task.links?.pmsUnitIds?.[0] ?? "";
  const findUnitNumber = units?.find((unit) => unit.id === unitId)?.name;
  const { openEditTask } = useTaskModal({});

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => openEditTask(task.id.toString())}
        sx={{ cursor: "pointer" }}
        py={1}
      >
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Paragraph>{task.title}</Paragraph>
            </Grid>
            <Grid item>
              <Grid container direction="row" gap={0.5}>
                {findUnitNumber && (
                  <Grid item>
                    <ParagraphSmall color="text.secondary">
                      {t("labels__unit")}: {findUnitNumber}
                    </ParagraphSmall>
                  </Grid>
                )}
                {displayReservationId &&
                  findUnitNumber &&
                  task.links?.reservations?.[0]?.displayableReservationId && (
                    <Grid item>
                      <ParagraphSmall color="text.secondary">|</ParagraphSmall>
                    </Grid>
                  )}
                {displayReservationId &&
                  task.links?.reservations?.[0]?.displayableReservationId && (
                    <Grid item>
                      <ParagraphSmall color="text.secondary">
                        {`${t("labels__res_ID")}: ${task.links?.reservations?.[0]
                          ?.displayableReservationId}`}
                      </ParagraphSmall>
                    </Grid>
                  )}
              </Grid>
              <Grid item>
                {task.due && (
                  <Grid container direction="row" gap={0.5}>
                    <Grid item>
                      <ParagraphSmall color="text.secondary">
                        {t("labels__due_date")}
                      </ParagraphSmall>
                    </Grid>
                    <Grid item>
                      <ParagraphSmall
                        color={
                          task.status !== Status.Done && taskDateOverdue(task?.due)
                            ? palette.error.main
                            : "inherit"
                        }
                      >
                        {formatDateTime(task.due, language, selectedProperty?.details.timeZone)}
                      </ParagraphSmall>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <DisplayTaskIcon status={task.status} />
            <Paragraph sx={{ color: statusColor(task.status, palette) }}>
              {t(`labels__task_status_${task.status}`)}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
      <Divider sx={{ width: "100%", color: palette.grey[300] }} />
    </>
  );
};
