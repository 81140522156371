import { Button, Caption, Paragraph, ParagraphBold } from "@likemagic-tech/sv-magic-library";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import { FC, useState } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import CloseIcon from "@mui/icons-material/Close";

interface SpecialAssistanceModalProps {
  onClose: () => void;
  onSubmit: (value: string) => void;
}

export const SpecialAssistanceModal: FC<SpecialAssistanceModalProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslationWrapper();

  const [inputValue, setInputValue] = useState("");

  return (
    <Dialog open>
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <ParagraphBold>{t("title__special_assistance_modal")}</ParagraphBold>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 0, px: 3 }}>
        <Grid container spacing={2}>
          <Grid item>
            <Paragraph>{t("subtitle__special_assistance_modal")}</Paragraph>
          </Grid>
          <Grid item sx={{ display: "flex", width: "100%" }}>
            <TextField
              multiline
              rows={3}
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              placeholder={t("labels__input_special_assistance_modal_placeholder")}
            ></TextField>
          </Grid>
          <Grid item>
            <Caption>{t("caption__special_assistance_modal")}</Caption>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="primary"
              onClick={() => onSubmit(inputValue)}
              disabled={inputValue === ""}
            >
              {t("buttons__submit")}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth variant="ghost" onClick={onClose}>
              {t("buttons__skip_special_assistance")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
