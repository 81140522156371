import {
  SUPERUSER_ROLE,
  useApiVersion,
  useAuth,
  usePropertyConfig
} from "@likemagic-tech/sv-magic-library";
import { useMemo } from "react";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { useProperty } from "src/hooks/use-property";
import { Box } from "@mui/material";
import { SidebarItem } from "./sidebar-item";
import { sectionsOption } from "./options";
import { useSelector } from "react-redux";
import { selectAllUnreadMessageCount } from "../../../slices/unread-message-count.slice";
import { BACKOFFICE_ROUTES, BACKOFFICE_ROUTES_MULTI_PROPERTY } from "../../../routes";

const SidebarSections = () => {
  const { t } = useTranslationWrapper();
  const { isRESTVersion } = useApiVersion();
  const { selectedProperty, selectedPropertyIds } = useProperty();
  const { features } = usePropertyConfig({
    propertyId: selectedProperty?.propertyId
  });
  const { hasRole, hasSuperuserRole } = useAuth();

  const messageBadgeCount = useSelector(selectAllUnreadMessageCount);

  const sections = useMemo(
    () =>
      sectionsOption({
        t,
        features,
        isRESTVersion
      }),
    [t, features, isRESTVersion]
  );

  return (
    <Box sx={{ p: 1 }}>
      {sections
        .flatMap((section) => section.items)
        .filter(
          (items) =>
            items?.roles?.some(
              (role) =>
                hasRole(role) || (items.roles.includes(SUPERUSER_ROLE) ? hasSuperuserRole() : false)
            )
        )
        .filter((items) => {
          if (selectedPropertyIds.length > 1) {
            return Object.values(BACKOFFICE_ROUTES_MULTI_PROPERTY).includes(items.path);
          } else {
            return true;
          }
        })
        .map((item) => {
          return (
            <SidebarItem
              key={item.title}
              title={item.title}
              path={item.path}
              icon={item.icon}
              params={item.params}
              isCollapsable={!!item.isCollapsable}
              collapseOptions={item.collapseOptions}
              badgeNumber={
                item.path === BACKOFFICE_ROUTES.MESSAGING ? messageBadgeCount : undefined
              }
            />
          );
        })}
    </Box>
  );
};

export default SidebarSections;
