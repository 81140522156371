import type { FC } from "react";
import { Box, useTheme } from "@mui/material";
import { ReportStatus } from "../../domain/report";
import { QuickFilterCheckbox } from "src/components/quick-filter-checkbox/quick-filter-checkbox";
import { useTranslationWrapper } from "src/hooks/use-translation-wrapper";
import { FilterObjectType } from "src/components/table/filters/table-filters";
import { FilterOperator } from "src/types/filters/filters";

export const filterableStatuses = [ReportStatus.BOOKED, ReportStatus.OBTAINED];

export const mapReservationConditionLabels = {
  [ReportStatus.BOOKED]: "labels__performed_service_BOOKED",
  [ReportStatus.OBTAINED]: "labels__performed_service_OBTAINED"
};

const mapBreakfastConditionColors = (
  condition: string
): "default" | "primary" | "error" | "info" | "success" | "warning" | undefined => {
  switch (condition) {
    case ReportStatus.BOOKED:
      return "error";
    case ReportStatus.OBTAINED:
      return "success";
    default:
      return undefined;
  }
};

interface BreakfastFilterProps {
  value?: ReportStatus;
  onConditionSelected: (status: ReportStatus) => void;
  countForBreakfastCondition: { BOOKED: number; OBTAINED: number };
}

export const BreakfastFilter: FC<BreakfastFilterProps> = ({
  value,
  onConditionSelected,
  countForBreakfastCondition
}) => {
  const { t } = useTranslationWrapper();
  const { spacing } = useTheme();

  return (
    <Box sx={{ display: "flex", gap: spacing(1.25) }}>
      {filterableStatuses.map((condition) => (
        <div key={`condition-${condition}`}>
          <QuickFilterCheckbox
            filter={{ name: "breakfastCondition" }}
            label={t(
              mapReservationConditionLabels[condition as keyof typeof mapReservationConditionLabels]
            )}
            value={condition}
            count={countForBreakfastCondition[condition as keyof typeof countForBreakfastCondition]}
            filterMap={
              value === condition
                ? {
                    [`ADDITIONAL-breakfastCondition-in`]: {
                      type: FilterObjectType.ADDITIONAL,
                      name: "breakfastCondition",
                      operator: FilterOperator.In,
                      value: condition
                    }
                  }
                : {}
            }
            onFilterMapChange={() => onConditionSelected(condition)}
            mapColors={mapBreakfastConditionColors}
            condition={condition}
          />
        </div>
      ))}
    </Box>
  );
};
