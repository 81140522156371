import React, { FC, useCallback, useRef, useState } from "react";
import { DatePicker } from "@mui/lab";
import { Button, formatDate } from "@likemagic-tech/sv-magic-library";
import { ScheduleOutlined } from "@mui/icons-material";
import { LocalizationDatePicker } from "../../../../components/language-selector/localization-date-picker";
import { QuickButtonsToolbar } from "./due-modal";
import { SubtasksForm } from "../task-modal";
import { useProperty } from "../../../../hooks/use-property";
import { Box, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getI18nSelectedLanguage } from "src/utils/language";

interface SubtasksDueDateProps {
  subtask: SubtasksForm;
  setFieldValue: (field: string, value: any) => void;
  index: number;
  disabled: boolean;
}

export const SubtasksDueDate: FC<SubtasksDueDateProps> = ({
  subtask,
  setFieldValue,
  index,
  disabled
}) => {
  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const theme = useTheme();

  const { selectedProperty } = useProperty();
  const language = getI18nSelectedLanguage();

  const QuickButtonsToolbarCallBack = useCallback(
    (props: any, onAccept: any) => <QuickButtonsToolbar {...props} onAccept={onAccept} />,
    []
  );

  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mt: -0.25 }}>
      <LocalizationDatePicker>
        <DatePicker
          onClose={() => setOpen(false)}
          open={open}
          disableOpenPicker
          showToolbar
          PopperProps={{
            placement: "auto",
            anchorEl: anchorRef.current
          }}
          disabled={disabled}
          value={subtask.dueDate || ""}
          onAccept={(date: Date | null) => {
            date && setFieldValue(`subtasks.[${index}].dueDate`, date.toISOString());
            setFieldValue(`subtasks.[${index}].isEdit`, true);
          }}
          ToolbarComponent={(props) =>
            QuickButtonsToolbarCallBack(props, (value: any) => {
              setFieldValue(`subtasks.[${index}].dueDate`, value.toDateString());
              setFieldValue(`subtasks.[${index}].isEdit`, true);
              setOpen(false);
            })
          }
          onOpen={() => setOpen}
          renderInput={({ inputRef }) => (
            <div ref={inputRef}>
              <Button
                variant="ghost"
                size="medium"
                onClick={(event) => {
                  anchorRef.current = event.currentTarget;
                  setOpen(true);
                }}
                startIcon={
                  !subtask.dueDate && (
                    <ScheduleOutlined sx={{ color: theme.palette.grey[700], mr: -1.5 }} />
                  )
                }
                sx={{ minWidth: theme.spacing(4.5), p: theme.spacing(1, 1, 1, 1) }}
                disabled={disabled}
              >
                {subtask.dueDate
                  ? formatDate(subtask.dueDate, language, selectedProperty?.details?.timeZone)
                  : ""}
              </Button>
            </div>
          )}
          onChange={() => {}}
        />
      </LocalizationDatePicker>
      {subtask.dueDate && (
        <CloseIcon
          sx={{ color: theme.palette.text.secondary, cursor: disabled ? "auto" : "pointer" }}
          onClick={() => {
            !disabled && setFieldValue(`subtasks.[${index}].dueDate`, null);
          }}
        />
      )}
    </Box>
  );
};
