import { api } from "../set-preferred-cleaning-days.generated";

const setPreferredCleaningDaysEnhanced = api.enhanceEndpoints({
  endpoints: {
    SetPreferredCleaningDays: {
      invalidatesTags: ["ReservationDetails"]
    }
  }
});

export const { useSetPreferredCleaningDaysMutation: useSetPreferredCleaningDaysMutationEnhanced } =
  setPreferredCleaningDaysEnhanced;
