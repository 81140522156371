import { FC } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { TaskStatus } from "../domain/task";
import CheckIcon from "@mui/icons-material/Check";
import { Autorenew, PriorityHigh } from "@mui/icons-material";
import { statusColor } from "./status-cell";
import { useTheme } from "@mui/material";

interface DisplayTaskIconProps {
  status: TaskStatus;
}

export const DisplayTaskIcon: FC<DisplayTaskIconProps> = ({ status }) => {
  const { palette } = useTheme();
  switch (status) {
    case TaskStatus.Todo:
      return (
        <PriorityHigh
          data-stop-propagation
          sx={{ color: statusColor(status, palette), fontSize: "20px", mr: 1 }}
        />
      );
    case TaskStatus.InProgress:
      return (
        <Autorenew
          data-stop-propagation
          sx={{ color: statusColor(status, palette), fontSize: "20px", mr: 1 }}
        />
      );
    case TaskStatus.Done:
      return (
        <CheckIcon
          data-stop-propagation
          sx={{ color: statusColor(status, palette), fontSize: "20px", mr: 1 }}
        />
      );
    default:
      return (
        <CheckCircleIcon
          data-stop-propagation
          sx={{ color: statusColor(null, palette), fontSize: "20px", mr: 1 }}
        />
      );
  }
};
