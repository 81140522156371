import { useNavigate } from "react-router-dom";
import { ACCOUNT_PROPERTY_ID, useProperty } from "./use-property";
import { Params } from "./use-select-id";
import { useCallback } from "react";

export const useNavigateWithPropertyId = () => {
  const navigate = useNavigate();
  const { selectedProperty, selectedPropertyIds } = useProperty();

  return useCallback(
    (path: string, params?: URLSearchParams) => {
      const searchParamWithPropertyId = new URLSearchParams(params);

      if (selectedPropertyIds.length > 1) {
        searchParamWithPropertyId.set(Params.SELECTED_PROPERTY_ID, ACCOUNT_PROPERTY_ID);
      } else {
        searchParamWithPropertyId.set(Params.SELECTED_PROPERTY_ID, selectedProperty?.propertyId);
      }

      navigate({
        pathname: path,
        search: "?" + searchParamWithPropertyId
      });
    },
    [navigate, selectedProperty, selectedPropertyIds]
  );
};
