import { Box, Grid } from "@mui/material";
import { useIsMobile } from "src/hooks/use-is-mobile";
import { useProperty } from "../../../hooks/use-property";
import { useTranslationWrapper } from "../../../hooks/use-translation-wrapper";
import React from "react";
import PageTitle from "../../../components/page-title";

const SettingsAdditionalServicesPage = () => {
  const { selectedPropertyPreview } = useProperty();
  const isMobile = useIsMobile();
  const { t } = useTranslationWrapper();

  return (
    <Box px={isMobile ? 0 : 3} py={isMobile ? 0 : 4}>
      {!isMobile && (
        <Grid alignItems="center" container justifyContent="space-between" spacing={3}>
          <Grid item pb={2.5}>
            <PageTitle subTitle={selectedPropertyPreview} title={t("title__additional_services")} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SettingsAdditionalServicesPage;
